import camelCase from 'lodash/camelCase'
import { getPlainTextFromRichText } from '@gositeinc/ui'

import { getLinkWithSearchParams } from './getLinkWithSearchParams'

export const enhanceLinks = (): void => {
  // eslint-disable-next-line
  const $ = require('cash-dom')
  // setTimeout(() => {
  $(document).ready(() => {
    $('a').each((index: number, ele: any) => {
      const href = ele.getAttribute('href') as string
      if (href !== '') {
        ele.setAttribute('href', getLinkWithSearchParams(href))
      }

      const analyticsEvent = ele.getAttribute('data-analytics') as string

      if (analyticsEvent != null && typeof analyticsEvent === 'string') {
        $(ele).on('click', function (e) {
          const properties = {}
          $.each(ele.attributes, function (_, attribute) {
            // if this attribute corresponds to a property
            if (attribute.name.startsWith('data-analytics-') === true) {
              // we get this property name. for instance, <a data-property-color="red" /> would mean var property = color
              const property = attribute.name.split('data-analytics-')[1]
              // following the previous example, attribute.value = red, so we set properties['color'] = red
              properties[camelCase(property)] = getPlainTextFromRichText(attribute.value)
            }
          })
          console.log('segment:', JSON.stringify({ event: analyticsEvent, properties }))
          window.analytics.track(analyticsEvent, properties)
        })
      }
    })
  })
}
