import { format as dateFormat } from 'date-fns'
import template from 'lodash/template'

import { HeroCTAMessageFormatter } from '../types'
import { Feature } from '../constants'

export const useSmsPayloadFormatter = (): UseSmsMessageFormatter => {
  const getSmsPayload = (widgetType: Feature): HeroCTAMessageFormatter => {
    const notYetImplemented = (): string => 'Not Yet Implemented'

    const formatter: { [key in Feature]: HeroCTAMessageFormatter } = {
      booking: bookingHeroCTAMessageFormatter,
      'contact-hub': contacthubHeroCTAMessageFormatter,
      'email-campaign': notYetImplemented,
      invoice: defaultHeroCTAMessageFormatter,
      messenger: defaultHeroCTAMessageFormatter,
      payment: paymentHeroCTAMessageFormatter,
      placement: defaultHeroCTAMessageFormatter,
      review: reviewHeroCTAMessageFormatter,
      sites: notYetImplemented,
      engagement: defaultHeroCTAMessageFormatter
    }

    return formatter[widgetType] ?? notYetImplemented
  }

  return {
    getSmsPayload
  }
}

const bookingHeroCTAMessageFormatter: HeroCTAMessageFormatter = ({
  heroCTAData,
  widgetPayload
}) => {
  return template(heroCTAData.smsContent)({
    a: widgetPayload?.serviceName,
    b: dateFormat(widgetPayload.bookingDateTime, 'do MMM yyyy'),
    c: dateFormat(widgetPayload.bookingDateTime, 'h:mm a')
  })
}

const reviewHeroCTAMessageFormatter: HeroCTAMessageFormatter = ({
  heroCTAData,
  widgetPayload
}) => {
  return template(heroCTAData?.smsContent)({
    a: widgetPayload?.reviewStars ?? 'review smsContent'
  })
}

const paymentHeroCTAMessageFormatter: HeroCTAMessageFormatter = ({
  heroCTAData,
  widgetPayload
}) => {
  return template(heroCTAData.smsContent)({
    a: widgetPayload?.amount,
    b: widgetPayload?.selectedService?.price,
    c: widgetPayload?.senderName
  })
}

const contacthubHeroCTAMessageFormatter: HeroCTAMessageFormatter = ({
  heroCTAData,
  widgetPayload
}) => {
  return template(heroCTAData.smsContent)({
    a: widgetPayload?.formValues?.name,
    b: widgetPayload?.formValues?.email,
    c: widgetPayload?.formValues?.phone,
    d: widgetPayload?.formValues?.address
  })
}

const defaultHeroCTAMessageFormatter: HeroCTAMessageFormatter = ({
  heroCTAData,
  widgetPayload
}) => {
  return heroCTAData.smsContent
}

export interface UseSmsMessageFormatter {
  getSmsPayload: (widgetType: Feature) => HeroCTAMessageFormatter
}
