import React from 'react'
import {
  Avatar,
  Box,
  BoxProps,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Text,
  Theme,
  Media,
  styled,
  useTheme,
  useMediaQuery
} from '@gositeinc/ui'
import { BiDollarCircle, BiTime } from 'react-icons/bi'
import hexToRgba from 'hex-to-rgba'

import { IHeroBookingWidget } from '../types'

const BookingServiceDetails = ({ data, ...boxProps }: BookingServiceDetailsProps): JSX.Element => {
  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'))
  const businessIcon = {
    color: (data?.businessIconColor === null || data?.businessIconColor === '' || data?.businessIconColor === undefined)
      ? theme.palette.primary.main
      : data?.businessIconColor,
    size: isLargeScreen ? 14 : 20
  }

  return (
    <Box {...boxProps}>
      <BusinessDetails dense>
        <ListItem sx={{ paddingX: 0 }}>
          <ListItemAvatar>
            <BusinessIconAvatar
              sx={{ backgroundColor: hexToRgba(businessIcon?.color, '0.2') }}
              alt={data?.businessName}
              styleProps={{
                iconColor: businessIcon.color,
                iconSize: businessIcon.size
              }}
            >
              <Media
                media={data?.businessIcon}
                height={businessIcon.size}
                width={businessIcon.size}
              />
            </BusinessIconAvatar>
          </ListItemAvatar>
          <ListItemText
            primary={data?.businessName}
            secondary={data?.businessLocation}
          />
        </ListItem>
      </BusinessDetails>
      <Text
        variant='h6'
      >
        {data?.serviceName}
      </Text>
      <ServiceDetails dense>
        <ListItem>
          <ListItemIcon sx={{ minWidth: 22, color: theme.palette.primary.main }}>
            <BiTime size={15} />
          </ListItemIcon>
          <ListItemText
            secondary={`${data?.serviceDurationInMinutes} min`}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon sx={{ minWidth: 22, color: theme.palette.primary.main }}>
            <BiDollarCircle size={15} />
          </ListItemIcon>
          <ListItemText
            secondary={`$${data?.serviceHourlyPricing}/hr`}
          />
        </ListItem>
      </ServiceDetails>
      <Text
        variant='body2'
        color='textSecondary'
        lineHeight={1.8}
      >
        {data?.serviceDescription}
      </Text>
    </Box>
  )
}

const BusinessDetails = styled(List)(({ theme }) => ({
  padding: 0,
  paddingBottom: theme.spacing(1),
  '& .MuiListItem-root': {
    alignItems: 'flex-start'
  },
  '& .MuiListItemText-root': {
    marginTop: 0
  },
  '& .MuiListItemAvatar-root': {
    marginTop: theme.spacing(0.4),
    minWidth: 48,
    [theme.breakpoints.up('sm')]: {
      minWidth: 32
    }
  },
  '& .MuiAvatar-root': {
    width: 36,
    height: 36,
    [theme.breakpoints.up('sm')]: {
      width: 20,
      height: 20
    }
  },
  '& .MuiListItemText-primary': {
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.caption.fontSize
    }
  },
  '& .MuiListItemText-secondary': {
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.typography.caption.fontSize
    }
  }
}))

const ServiceDetails = styled(List)(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing(0, -1),
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'column'
  },
  '& .MuiListItem-root': {
    margin: theme.spacing(0, 1),
    padding: 0,
    width: 'auto',
    whiteSpace: 'pre'
  }
}))

const BusinessIconAvatar: React.ElementType = styled(Avatar)(
  ({ theme, styleProps }: { theme: Theme, styleProps: IconStyleProps }) => ({
    '& svg': {
      fill: styleProps.iconColor,
      height: styleProps.iconSize,
      position: 'relative',
      top: theme.spacing(0.25)
    }
  }))

interface BookingServiceDetailsProps extends BoxProps {
  data: IHeroBookingWidget
}

interface IconStyleProps {
  iconSize: number
  iconColor: string
}

export default BookingServiceDetails
