import React, { useEffect } from 'react'
import {
  Box,
  typographyClasses,
  Media,
  styled,
  SxProps,
  Text,
  useTheme,
  useGsapAnimation
} from '@gositeinc/ui'
import { gsap } from 'gsap'

import { heroClasses } from '../classes'
import { IHeroFeatureContent } from '../types'

const masterAnimationTimeline: gsap.core.Timeline = gsap.timeline()

const HeroBody = ({ data, renderHeroCtaContent, bodyStyle }: HeroBodyProps): JSX.Element => {
  const theme = useTheme()
  const { animateTextWithSwooshEffect } = useGsapAnimation()
  const sx = { ...data.styles, ...bodyStyle }

  useEffect(() => {
    masterAnimationTimeline.add(animateLabel())
    masterAnimationTimeline.add(animateTextWithSwooshEffect('#heroBodyTitle *'))
    masterAnimationTimeline.add(animateTextWithSwooshEffect(`#heroCtaContent .${typographyClasses.root}`, { delay: 0.5 }))
    // titleTextAnimationTimeline.current = animateTextWithSwooshEffect('#heroBodyTitle *')

    // return () => {
    //   titleTextAnimationTimeline.current?.clear()
    // }
  }, [])

  return (
    <Root sx={sx}>
      <Label mb={2}>
        {data?.labelIcon != null && (
          <Box width={22} pt={0.625} id='heroBodyLabelIcon'>
            <Media media={data?.labelIcon} />
          </Box>
        )}
        <Text
          id='heroBodyLabelText'
          variant='h6'
          fontSize={theme.typography.heading.fontSize}
          color='primary'
          sx={{ textTransform: 'uppercase' }}
        >
          {data?.label}
        </Text>
      </Label>
      <Text
        id='heroBodyTitle'
        rich
        align='center'
        className={heroClasses.title}
      >
        {data?.title}
      </Text>
      {renderHeroCtaContent}
    </Root>
  )
}

const animateLabel = (): gsap.core.TimelineChild => {
  const tl = gsap.timeline()
  return tl
    .from('#heroBodyLabelIcon', {
      autoAlpha: 0,
      duration: 1,
      x: '250%'
    })
    .from('#heroBodyLabelText', {
      autoAlpha: 0,
      duration: 1,
      x: -20
    }, '-=0.5')
}

const Root = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    height: '100%'
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(8.75)
  },
  [theme.breakpoints.down('md')]: {
    '& h2': {
      fontSize: '32px'
    }
  }
}))

const Label = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(1.25),
  justifyContent: 'center'
}))

interface HeroBodyProps {
  data: IHeroFeatureContent
  renderHeroCtaContent: React.ReactNode
  bodyStyle: SxProps
}

export default HeroBody
