import React from 'react'
import { Media, Text, Stack, StackProps } from '@gositeinc/ui'

import { IMedia } from 'src/types'

export const Label = ({
  icon,
  text,
  size = 'medium',
  textColor = 'primary',
  ...props
}: LabelProps): JSX.Element => {
  const getTextVariant = (): 'caption' | 'h6' | 'h4' => {
    switch (size) {
      case 'small':
        return 'caption'
      case 'large':
        return 'h4'
      default:
        return 'h6'
    }
  }

  const renderText = (): React.ReactNode => {
    if (typeof text === 'string') {
      return (
        <Text
          variant={getTextVariant()}
          color={textColor}
        >
          {text}
        </Text>
      )
    }

    return text
  }

  return (
    <Stack
      display='inline-flex'
      direction='row'
      gap={2}
      {...props}
    >
      <Media
        media={icon}
        width={20} height={20}
      />
      {renderText()}
    </Stack>
  )
}

export type LabelProps = {
  icon: IMedia
  text: string | React.ReactNode
  size?: 'small' | 'medium' | 'large'
  textColor?: 'primary' | 'secondary' | 'text.primary' | 'text.secondary'
} & StackProps
