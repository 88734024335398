import { ButtonVariant } from '../types'

export const useButton = (): UseButton => {
  const getVariant = (cmsButtonVariant: ButtonVariant | null | undefined): 'outlined' | 'contained' | 'text' => {
    switch (cmsButtonVariant) {
      case 'outlined':
        return 'outlined'
      case 'link':
        return 'text'
      default:
        return 'contained'
    }
  }
  return {
    getVariant
  }
}

export interface UseButton {
  getVariant: (cmsButtonVariant: ButtonVariant | null | undefined) => 'outlined' | 'contained' | 'text'
}
