import React from 'react'
import {
  BackgroundMedia,
  Box,
  Button,
  Container,
  Grid,
  Media,
  Link,
  Text,
  styled,
  useTheme,
  useMediaQuery
} from '@gositeinc/ui'

import { ITestimonial } from '../types'
import * as Assets from '../assets'
import { ANALYTICS_CONSTANTS } from 'src/analytics-constants'

const Testimonial = ({ data, analytics }: TestimonialProps): JSX.Element => {
  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))

  const {
    label,
    quote,
    ctaButton,
    figure,
    backgroundMedia
  } = data

  return (
    <>
      <Wrapper>
        <Container maxWidth='lg'>
          <TestimonialContainer container spacing={0} paddingTop={10}>
            <Grid item sm={8}>
              <TextContainer>
                {label != null && (
                  <Text variant='subtitle2' fontWeight={theme.typography.fontWeightBold} color={theme.palette.primary.main}>
                    {label}
                  </Text>
                )}
                <Text rich variant={isLargeScreen ? 'h3' : 'h5'} sx={{ py: 2.6, textAlign: 'left', maxWidth: 800 }}>
                  {quote}
                </Text>
                {ctaButton?.map(button => {
                  return (
                    <Link
                      key={button.id}
                      link={button}
                      data-analytics={analytics?.ctaButton}
                      data-analytics-section-data={button}
                      data-analytics-action-title={button?.text}
                    >
                      <Button
                        variant='contained'
                        size='large'
                        sx={{ height: 51, justifyContent: 'space-between' }}
                        endIcon={<Assets.RightArrow pathColor={theme.palette.common.white} />}
                      >
                        {button.text} &nbsp;&nbsp;
                      </Button>
                    </Link>
                  )
                })}
              </TextContainer>
            </Grid>
            {figure != null && (
              <Grid
                item sm={4} sx={{
                  display: 'flex', justifyContent: 'center', alignItems: 'center'
                }}
              >
                <FigureContainer>
                  <Box width={185}>
                    <Media media={figure.captionImage} />
                  </Box>
                  <Text variant='body1' sx={{ pt: 2.25, fontWeight: theme.typography.fontWeightBold, color: theme.palette.primary.light }}>
                    {figure.caption}
                  </Text>
                </FigureContainer>
              </Grid>
            )}
          </TestimonialContainer>
        </Container>
        <BackgroundMedia media={backgroundMedia} />
      </Wrapper>

    </>
  )
}
interface TestimonialProps {
  data: ITestimonial
  analytics: typeof ANALYTICS_CONSTANTS['sections.testimonial-1']
}

const Wrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(6),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative'
}))

const TestimonialContainer = styled(Grid)(({ theme }) => ({
  paddingBottom: theme.spacing(10.2),
  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column-reverse',
    paddingBottom: theme.spacing(6.25)
  }
}))

const TextContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
    padding: theme.spacing(1)
  }
}))

const FigureContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}))

export type { TestimonialProps }
export default Testimonial
