import React, { useState } from 'react'
import { ITestimonialTabsGroup } from '../types'
import {
  Box,
  Container,
  styled,
  Tab,
  Tabs,
  Text,
  tabsClasses,
  tabClasses,
  BackgroundMedia
} from '@gositeinc/ui'

import SwipeableViews from 'react-swipeable-views'
import { TestimonialTabItem } from './testimonial-tab-item'

import { testimonialTabsGroup } from '../classes'
import { Label } from 'src/components'

export const TestimonialTabsGroup = ({ data }: TestimonialTabsGroupProps): JSX.Element => {
  const [tabIndex, setTabIndex] = useState(0)

  const {
    backgroundImage,
    description,
    labelIcon,
    labelText,
    styles,
    tabItems,
    tabPanels,
    title
  } = data

  const onClickTabMenuItem = (newTabIndex: number): void => {
    setTabIndex(Number(newTabIndex))
  }

  const onSwipeTab = (newTabIndex: number): void => {
    setTabIndex(newTabIndex)
  }
  return (
    <Root sx={styles}>
      <Container>
        <Label
          icon={labelIcon}
          text={labelText}
          textColor='text.secondary'
        />
        <Title className={testimonialTabsGroup.title} rich>{title}</Title>
        <Description rich>{description}</Description>
        <TabMenus
          value={tabIndex}
          onChange={(_, index) => onClickTabMenuItem(index)}
        >
          {/* {new Array(6).fill(tabItems[0]).map((tabItem, index) => ( */}
          {tabItems.map((tabItem, index) => (
            <Tab
              key={tabItem?.id}
              label={tabItem?.labelText}
              disableRipple
            />
          ))}
        </TabMenus>
        <TabPanels>
          <SwipeableViews
            enableMouseEvents={false}
            index={tabIndex}
            onChangeIndex={onSwipeTab}
          >
            {/* {new Array(6).fill(tabPanels[0]).map((panel, i) => ( */}
            {tabPanels.map((panelData, i) => (
              <TestimonialTabItem
                key={panelData.id}
                data={panelData}
              />
            ))}
          </SwipeableViews>
        </TabPanels>
      </Container>
      <BackgroundMedia
        media={backgroundImage}
        objectFit='cover'
      />
    </Root>
  )
}

const Root = styled(Box)(({ theme }) => ({
  position: 'relative',
  textAlign: 'center',
  [`.${testimonialTabsGroup.title} h2`]: {
    ...theme.typography.h2
  },
  [theme.breakpoints.down('md')]: {
    [`.${testimonialTabsGroup.title} h2`]: {
      ...theme.typography.h4
    }
  },
  [`& .${tabsClasses.indicator}`]: {
    display: 'none'
  },
  [`& .${tabsClasses.flexContainer}`]: {
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: theme.spacing(4)

  },
  [`& > .${tabsClasses.flexContainer}`]: {
    [theme.breakpoints.up('md')]: {

    }
  },
  [`& .${tabClasses.root}`]: {
    textTransform: 'none',
    fontSize: theme.typography.subtitle3.fontSize
  },
  '& .GoSiteTestimonial3-media': {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(4)
    }
  }
}))

const TabMenus = styled(Tabs)(({ theme }) => ({
  position: 'relative',
  zIndex: 1
}))

const Title = styled(Text)(({ theme }) => ({

}))

const TabPanels = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(-15) // negative margin because of the pddding top from Testimonial3
}))

const Description = styled(Text)(({ theme }) => ({
  ...theme.typography.heading,
  color: theme.palette.text.secondary,
  fontWeight: theme.typography.fontWeightRegular
}))

export interface TestimonialTabsGroupProps {
  data: ITestimonialTabsGroup
}
