import { ChangeEvent, useState } from 'react'
import { FiSend } from 'react-icons/fi'
import {
  Avatar,
  Box,
  Button,
  colors,
  chipClasses,
  Card,
  CardContent,
  Container,
  Chip,
  Divider,
  Grid,
  Media,
  styled,
  TextField,
  Text,
  Theme,
  TITLE_FONT_FAMILY,
  useTheme
} from '@gositeinc/ui'

import { EngagementEmailBody } from './engagement-email-body'
import { HeroWidgetModifierStyle } from './hero-widget'
import { IHeroEngagementWidget } from '../types'
import { engagementEmailBodyClasses } from 'src/classes'

const EngagementWidget = ({ data, onDone }: EngagementWidgetProps): JSX.Element => {
  const theme = useTheme()
  const [emailSubject, setEmailSubject] = useState('')
  const [showSubjectText, setShowSubjectText] = useState(false)

  const {
    title,
    recieverName,
    senderName,
    emailSubjectPlaceholderText,
    businessAvatar,
    businessName,
    engagementMedia,
    engagementTitle,
    engagementDescription,
    engagementCtaText,
    engagementSubmitText,
    businessIconBackgroundColor,
    subject
  } = data

  const handleSubjectChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setEmailSubject(event.target.value)
  }

  const handleSendEmail = (): void => {
    onDone({ emailSubject })
    setShowSubjectText(true)
  }

  const handleShowCta = (event): void => {
    if (event.which === 13) {
      onDone({ emailSubject })
    }
  }

  return (
    <Root className={engagementEmailBodyClasses.root}>
      <EngagementContainer elevation={4}>
        <CardContent>
          <Text fontFamily={TITLE_FONT_FAMILY} variant='heading' px={2} py={1}>{title}</Text>
          <Divider variant='fullWidth' />

          <Container>
            <Grid container flexDirection='column'>
              <Grid item display='flex' alignItems='center' py={0.8}>
                <Text variant='caption' color='text.secondary'>To:</Text>
                <Chip
                  label={recieverName}
                  sx={{
                    backgroundColor: colors.grey[100],
                    borderRadius: '3px',
                    ml: 0.5,
                    color: theme.palette.primary.main,
                    [`& .${chipClasses.label}`]: {
                      paddingX: 1
                    }
                  }}
                  size='small'
                />
              </Grid>
              <Divider variant='middle' />
              <Grid item display='flex' py={0.8} color='text.secondary'>
                <Text variant='caption'>From:</Text>
                <Text variant='caption' ml={0.5}>{senderName}</Text>
              </Grid>
              <Divider variant='middle' />
              {showSubjectText
                ? (
                  <Text
                    variant='h6'
                    ml={0.5}
                  >
                    {emailSubject === '' ? subject : emailSubject}
                  </Text>)
                : (
                  <TextField
                    variant='standard'
                    placeholder={emailSubjectPlaceholderText}
                    onChange={handleSubjectChange}
                    onKeyDown={handleShowCta}
                  />)}
              <Divider variant='middle' />
            </Grid>

            <EngagementBodyContainer maxWidth='xs' sx={{ py: theme.spacing(3) }}>
              <Box display='flex' alignItems='center' mb={1.5}>
                <Avatar
                  sx={{ bgcolor: businessIconBackgroundColor, width: 32, height: 32 }}
                  alt={businessName}
                >
                  <Media media={businessAvatar} />
                </Avatar>
                <Text rich className={engagementEmailBodyClasses.businessName} ml={0.8}>{businessName}</Text>
              </Box>
              <Media media={engagementMedia} />
              <EngagementEmailBody
                title={engagementTitle}
                description={engagementDescription}
                linkText={engagementCtaText}
                titleTextVariant='subtitle1'
                descriptionTextVariant='caption'
              />
            </EngagementBodyContainer>
          </Container>
        </CardContent>
      </EngagementContainer>

      <SendEmailButtonWrapper>
        <Button
          variant='contained'
          onClick={() => handleSendEmail()}
          startIcon={<FiSend stroke={theme.palette.common.white} size={14} />}
          size='large'
        >
          {engagementSubmitText}
        </Button>
      </SendEmailButtonWrapper>
    </Root>
  )
}

const Root = styled(Box)(({ theme }) => ({
  [`.${engagementEmailBodyClasses.businessName} p`]: {
    ...theme.typography.subtitle2
  }
}))

const EngagementContainer = styled(Card)(({ theme }) => ({
  position: 'relative',
  width: 350,
  borderRadius: 8,
  '& .MuiCardContent-root': {
    padding: 0
  },
  '& .MuiDivider-root': {
    margin: 0
  },
  '& .MuiInput-input': {
    fontSize: theme.typography.heading,
    fontWeight: theme.typography.fontWeightBold
  },
  input: {
    '&::placeholder': {
      fontSize: 20,
      fontfamily: TITLE_FONT_FAMILY
    }
  },
  '& .MuiInput-root:before': {
    borderBottom: 'none'
  },
  [theme.breakpoints.up('sm')]: {
    width: 400
  }
}))

const EngagementBodyContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}))

const SendEmailButtonWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(-2),
  textAlign: 'center',
  width: '100%',
  '& .MuiButton-root': {
    borderRadius: 32,
    letterSpacing: 0.25,
    height: '40px',
    fontSize: theme.typography.caption.fontSize,
    boxShadow: '-20px 20px 40px rgba(8, 34, 68, 0.15) !important'
  }
}))

export const engagementWidgetModifierStyle: HeroWidgetModifierStyle = {
  showFade: true,
  fadeStyleCreator: () => ({
    background: 'linear-gradient(180deg, rgba(248, 249, 251, 0) 0%, #F7F9FB 35.91%)',
    top: 124,
    zIndex: 1,
    width: '96%'
  }),
  heroCtaStyleCreator: (theme: Theme) => ({
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(-22.5)
    }
  })
}

export interface EngagementWidgetProps {
  data: IHeroEngagementWidget
  onDone: (payload: object) => void
}

export default EngagementWidget
