import { GetServerSideProps, GetServerSidePropsContext } from 'next'

import { getPageData } from './api'
import { getLocalizedPaths } from './localize'
import { IPage, IPageContext } from '../types'

export const getPageProps: GetServerSideProps = async (context: GetServerSidePropsContext) => {
  const { params, locale, locales, defaultLocale, preview = null } = context

  let _slug = ['']

  if (params?.slug !== undefined) {
    _slug = params?.slug as string[]
  }

  // Fetch pages. Include drafts if preview mode is on
  const pageData: IPage = await getPageData(
    { slug: _slug },
    locale ?? 'en',
    preview ?? false
  )

  if (pageData == null) {
    // Giving the page no props will trigger a 404 page
    // return { props: {} }
    context.res.statusCode = 404
    return {
      props: {
        slugNotFound: true
      }
    }
  }

  // We have the required page data, pass it to the page component
  const {
    contentSections,
    metadata,
    metaTitleSuffix,
    pagePrimaryColor,
    pageNavbarTheme,
    displayPageNavbarLinks,
    localizations,
    slug,
    styles
  } = pageData

  const pageContext: IPageContext = {
    metadata,
    metaTitleSuffix,
    locale: pageData.locale,
    locales: locales ?? null,
    defaultLocale: defaultLocale ?? null,
    slug,
    localizations,
    styles,
    primaryColor: pagePrimaryColor,
    navbarTheme: pageNavbarTheme,
    displayNavbarLinks: displayPageNavbarLinks,
    localizedPaths: []
  }

  const localizedPaths = getLocalizedPaths(pageContext)

  return {
    props: {
      preview,
      sections: contentSections,
      metadata,
      pageContext: {
        ...pageContext,
        localizedPaths
      },
      slugNotFound: false
    }
  }
}
