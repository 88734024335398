import React, { useState } from 'react'
import {
  Avatar,
  Card,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Text,
  FormGroup,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
  Switch,
  Box,
  Media,
  styled,
  useMediaQuery,
  useTheme,
  Theme,
  SxProps
} from '@gositeinc/ui'
import template from 'lodash/template'
import hexToRgba from 'hex-to-rgba'

import { HeroCTAMessageFormatter, IHeroPlacementBusinessHours, IHeroPlacementWidget } from '../types'
import { HeroWidgetModifierStyle } from './hero-widget'

const PlacementWidget = ({ data, onDone }: PlacementWidgetProps): JSX.Element => {
  const {
    businessName,
    businessIcon,
    businessIconColor,
    businessLocation,
    services,
    defaultHours,
    businessHoursText,
    manageBusinessText
  } = data
  const [hours, setHours] = useState<IHeroPlacementBusinessHours[]>([1, 2, 3, 4, 5, 6, 7].map((dayOfWeek) => ({
    dayOfWeek,
    checked: defaultHours?.find(h => h.dayOfWeek === dayOfWeek)?.checked ?? false,
    fullDay: defaultHours?.find(h => h.dayOfWeek === dayOfWeek)?.fullDay ?? false,
    openHour: defaultHours?.find(h => h.dayOfWeek === dayOfWeek)?.openHour ?? '',
    closeHour: defaultHours?.find(h => h.dayOfWeek === dayOfWeek)?.closeHour ?? ''
  })) as IHeroPlacementBusinessHours[])

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Root>
      <PlacementContainer>
        <Grid
          container
          sx={{
            borderRight: '1px solid #ced3da',
            [theme.breakpoints.down('md')]: {
              borderRight: 'none',
              borderBottom: '1px solid #ced3da'
            }
          }}
          spacing={1}
          flexDirection='column'
          justifyContent='start'
        >
          {/* name section */}
          <Grid item container spacing={1}>
            <Grid item>
              <BusinessIconAvatar
                sx={{ backgroundColor: hexToRgba(businessIconColor, '0.2') }}
                alt={businessName}
                color={businessIconColor}
              >
                <Media
                  media={businessIcon}
                  height={isSmallScreen ? 20 : 12}
                  width={isSmallScreen ? 20 : 12}
                />
              </BusinessIconAvatar>
            </Grid>
            <Grid item>
              <Text
                variant='body2'
                sx={{
                  fontSize: '10px',
                  [theme.breakpoints.down('md')]: {
                    fontSize: '12px'
                  }
                }}
              >
                {businessName}
              </Text>
              <Text
                variant='body2'
                color='#576C77'
                sx={{
                  fontSize: '10px',
                  [theme.breakpoints.down('md')]: {
                    fontSize: '12px'
                  }
                }}
              >
                {businessLocation}
              </Text>
            </Grid>
          </Grid>

          {/* manage section */}
          <Grid
            item
            sx={{
              marginTop: '24px'
            }}
          >
            <Text
              variant='body2'
              sx={{
                fontSize: '9.72566px',
                fontWeight: 'bold',
                [theme.breakpoints.down('md')]: {
                  fontSize: '20px'
                }
              }}
            >{manageBusinessText}
            </Text>
          </Grid>

          <Grid
            item
            sx={{
              [theme.breakpoints.down('md')]: {
                display: 'none'
              }
            }}
          >
            <List>
              {services.map(service => (
                <ManageMyBusinessButton
                  key={service.id}
                  selected={service.active}
                  disabled={!service.active}
                >
                  <ListItemIcon>
                    <Media
                      media={service.icon}
                      height={14}
                      width={14}
                    />
                  </ListItemIcon>
                  <ManageMyBusinessText primary={service.name} />
                </ManageMyBusinessButton>))}
            </List>
          </Grid>

        </Grid>
        <Grid container sx={{ verticalAlign: 'top' }} flexDirection='column' justifyContent='space-around'>
          <Grid item sx={{ marginBottom: '18px' }} display='flex' flexDirection='row'>
            {isSmallScreen && services.map(service => (
              service.active ? <Box m={0.5}> <Media key={service.id} media={service.icon} height={14} width={14} /> </Box> : <></>))}
            <Text variant='h6' sx={{ fontSize: 16 }}>{businessHoursText}</Text>
          </Grid>

          {hours.map((hour, index) =>
            <Grid container item key={hour.dayOfWeek} justifyContent='center'>
              <BusinessHourEntry
                hour={hour}
                setHour={(newHour: IHeroPlacementBusinessHours) => {
                  const newHours = [...hours]
                  newHours[index] = newHour
                  setHours(newHours)
                  onDone({
                    businessHours: newHours
                  })
                }}
              />
            </Grid>)}
        </Grid>
      </PlacementContainer>
    </Root>
  )
}

const Root: React.ElementType = styled(Card)(({ theme }: { theme: Theme }) => ({
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    minWidth: 588
  }
}))

const PlacementContainer: React.ElementType = styled(Box)(({ theme }: { theme: Theme }) => ({
  '& > .MuiGrid-container': {
    display: 'inline-block',
    padding: theme.spacing(3),
    width: 'max-content',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      width: '100%',
      margin: 0,
      padding: theme.spacing(2)
    }
  }
}))

const BusinessIconAvatar: React.ElementType = styled(Avatar)(({ theme, color }: { theme: Theme, color: string }) => ({
  height: 20,
  width: 20,
  minWidth: 20,
  '& svg': {
    fill: color
  },
  [theme.breakpoints.down('sm')]: {
    height: 36,
    width: 36
  }
}))

const BusinessHourEntry = ({ hour, setHour, sx }: { hour: IHeroPlacementBusinessHours, setHour: (hour: IHeroPlacementBusinessHours) => void, sx?: SxProps }): JSX.Element => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
  const dayOfWeekNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  const BusinessFormControlLabel: React.ElementType = styled(FormControlLabel)(() => ({
    '&': {
      '.MuiFormControlLabel-label': {
        fontSize: '12px'
      }
    }
  }))

  const BusinessSelect: React.ElementType = styled(Select)(() => ({
    '&': {
      '> .MuiOutlinedInput-notchedOutline': {
        border: '0 solid #ffffff !important'
      },
      '> .MuiSvgIcon-root': {
        width: '16px',
        height: '16px',
        top: 'calc(50% - 10px)'
      },
      '> .MuiSelect-select': {
        paddingRight: '24px !important'
      }
    },
    fontSize: '12px',
    minWidth: '97.38px'
  }))

  const BusinesFormGroup: React.ElementType = styled(FormGroup)(() => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap'
  }))

  const BusinessSwitch: React.ElementType = styled(Switch)(({ theme }: { theme: Theme }) => ({
    '&': {
      '.MuiSwitch-thumb': {
        width: '9.24px',
        height: '9.31px'
      },
      '.MuiSwitch-switchBase': {
        transform: 'translate(3px, 3px)',
        '&.Mui-checked': {
          transform: 'translate(10px, 3px)',
          color: 'white'
        }
      },
      '.Mui-checked+.MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main
      }
    },
    width: '30px'
  }))

  const BusinessCheckbox: React.ElementType = styled(Checkbox)(() => ({
    '& > .MuiSvgIcon-root': {
      width: '14.27px',
      height: '14.27px'
    }
  }))

  const BusinessMenuItem: React.ElementType = styled(MenuItem)(() => ({
    fontSize: '12px',
    padding: '5px 4px 5px 11px',
    minHeight: 'auto'
  }))

  const renderOptions = (): JSX.Element[] => {
    const options = ['12:30']
    for (let i = 1; i <= 11; i++) {
      options.push(`${i}:00`)
      options.push(`${i}:30`)
    }

    return options.map(option => <BusinessMenuItem key={option} value={option}>{option}</BusinessMenuItem>)
  }

  const selectMenuProps = {
    sx: {
      '& > .MuiMenu-paper': {
        boxShadow: 'none',
        maxHeight: '107px',
        minWidth: '61px !important',
        borderRadius: '6px',
        border: '1px solid #DBE3EB',
        '& > .MuiList-root': {
          paddingBottom: '0px',
          paddingTop: '0px'
        }
      }
    }
  }

  return (
    <BusinesFormGroup sx={{ ...sx, display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
      <BusinessFormControlLabel
        control={<BusinessCheckbox size='small' defaultChecked={hour.checked} disableRipple sx={{ paddingRight: '5px' }} />}
        label={dayOfWeekNames[hour.dayOfWeek - 1] ?? ''}
        sx={{ minWidth: '54.02px' }}
      />
      <BusinessFormControlLabel
        control={
          <BusinessSwitch
            size='small'
            checked={hour.fullDay}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setHour({
              ...hour,
              fullDay: e.target.checked
            })}
          />
        }
        label={isSmallScreen ? '24/hr' : '24 hours'}
        sx={{
          [theme.breakpoints.down('md')]: {
            marginRight: 0
          }
        }}
      />
      <BusinessSelect
        size='small'
        displayEmpty
        value={hour.openHour}
        renderValue={() => (
          <Text sx={{ fontSize: '12px', display: 'flex', justifyContent: 'space-between' }}>
            <span>{hour.fullDay ? '00:00' : (hour.openHour === '' ? 'Open' : hour.openHour)}</span><span>AM</span>
          </Text>
        )}
        MenuProps={selectMenuProps}
        disabled={hour.fullDay}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          setHour({
            ...hour,
            openHour: e.target.value
          })
        }}
      >
        {renderOptions()}
      </BusinessSelect>
      <BusinessSelect
        size='small'
        displayEmpty
        value={hour.closeHour}
        renderValue={() => (
          <Text sx={{ fontSize: '12px', display: 'flex', justifyContent: 'space-between' }}>
            <span>{hour.fullDay ? '00:00' : (hour.closeHour === '' ? 'Close' : hour.closeHour)}</span><span>PM</span>
          </Text>
        )}
        MenuProps={selectMenuProps}
        disabled={hour.fullDay}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          setHour({
            ...hour,
            closeHour: e.target.value
          })
        }}
        sx={{
          [theme.breakpoints.down('md')]: {
            '& > .MuiSelect-select': {
              paddingLeft: '0 !important'
            },
            minWidth: '83.38px'
          }
        }}
      >
        {renderOptions()}
      </BusinessSelect>
    </BusinesFormGroup>
  )
}

const ManageMyBusinessButton: React.ElementType = styled(ListItemButton)(() => ({
  borderRadius: '2.77px',
  height: '35px',
  '&.Mui-selected': {
    backgroundColor: '#EDF1F6'
  },
  '&.Mui-selected:hover': {
    backgroundColor: '#EDF1F6'
  },
  '&': {
    '.MuiListItemIcon-root': {
      minWidth: 'initial',
      marginRight: '9px'
    }
  }
}))

const ManageMyBusinessText: React.ElementType = styled(ListItemText)(() => ({
  '&': {
    span: {
      fontSize: '9.72566px'
    }
  }
}))

interface PlacementWidgetProps {
  data: IHeroPlacementWidget
  onDone: (payload: object) => void
}

export const placementWidgetModifierStyle: HeroWidgetModifierStyle = {
  showFade: false
}

export const placementHeroCTAMessageFormatter: HeroCTAMessageFormatter = ({
  heroCTAData,
  widgetPayload
}) => {
  return template(heroCTAData.smsContent)({
    a: widgetPayload?.amount ?? 'payment smsContent'
  })
}

export default PlacementWidget
