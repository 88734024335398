import React, { useRef } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import {
  Box,
  Grid,
  GridProps,
  Container,
  Stack,
  styled,
  useMediaQuery,
  Media,
  useTheme,
  BackgroundMedia
} from '@gositeinc/ui'

import { FeatureRowBody } from './feature-row-body'
import { IFeatureRow } from '../types'
import { ANALYTICS_CONSTANTS } from 'src/analytics-constants'

const GRID_COLUMNS = 12

gsap.registerPlugin(ScrollTrigger)

export const FeatureRow = ({ data }: FeatureRowProps): JSX.Element => {
  const theme = useTheme()
  const isSmallerScreen: boolean = useMediaQuery(theme.breakpoints.down('md'))
  const containerRef = useRef<HTMLElement>(null)
  const imageRef = useRef<HTMLElement>(null)

  // useEffect(() => {
  //   hide(imageRef.current)
  //   const tl = gsap.timeline({
  //     scrollTrigger: {
  //       trigger: containerRef.current,
  //       // start: 'top 10%', /** Don't specifiy start position if you want the animation to begin as soon as we enter the component */
  //       end: 'bottom 30%',
  //       // scrub: 1,
  //       // markers: true,
  //       // pinSpacing: false,
  //       onEnter: () => {
  //         animateFrom(imageRef.current)
  //       },
  //       onEnterBack: () => {
  //         animateFrom(imageRef.current, -1)
  //       },
  //       onLeave: function () { hide(imageRef.current) }
  //     }
  //   })

  //   return () => {
  //     tl?.clear()
  //   }
  // }, [])

  // const hide = (element): gsap.core.Tween => {
  //   return gsap.set(element, { autoAlpha: 0 })
  // }

  // const animateFrom = (element: HTMLElement | null, direction?: number): gsap.core.Tween => {
  //   direction = direction !== undefined ? direction : 1
  //   const x = data.mediaOrder === 'left' ? -140 : 140
  //   const y = direction * 100
  //   return gsap.fromTo(element, { x: x, y: y, autoAlpha: 0 }, {
  //     duration: 1.25,
  //     x: 0,
  //     y: 0,
  //     autoAlpha: 1,
  //     overwrite: 'auto'
  //   })
  // }

  const getColumnFractionFromString = (): number => {
    switch (data.mediaColumnWidth) {
      case 'large':
        return 7
      case 'largest':
        return 8
      default:
        return 6
    }
  }

  const getColumnWidth = (isInverted = false): unknown => {
    if (isInverted) {
      return GRID_COLUMNS - getColumnFractionFromString()
    }
    return getColumnFractionFromString()
  }

  const renderCenteredContent = (): JSX.Element => {
    return (
      <Stack
        alignItems='center'
        textAlign='center'
      >
        <Container maxWidth='lg'>
          <FeatureRowBody data={data} analytics={ANALYTICS_CONSTANTS[data.__component]} />
        </Container>
        <Box className='GoSiteFeatureRow-media'>
          <Media media={(isSmallerScreen !== null && isSmallerScreen ? data.smallScreenMedia : data.media)} />
        </Box>
      </Stack>
    )
  }

  const renderHorizontallyAlignedContent = (): JSX.Element => {
    return (
      <Grid
        container
        columns={GRID_COLUMNS}
      >
        {data.mediaOrder === 'left' && (
          <>
            <FeatureRowColumn
              containsMedia
              md={getColumnWidth() as boolean}
              contentContainerProps={{
                sx: getFlexJustifyContentValueFromAlignment(data.mediaHorizontalAlignment)
              }}
            >
              <Box
                ref={imageRef}
                className='GoSiteFeatureRow-media'
              >
                <Media media={data.media} />
              </Box>
            </FeatureRowColumn>
            <FeatureRowColumn
              containsMedia={false}
              md={getColumnWidth(true) as boolean}
              contentContainerProps={{
                sx: getFlexJustifyContentValueFromAlignment(data.contentHorizontalAlignment),
                paddingRight: { xl: '350px' }
              }}
            >
              <FeatureRowBody data={data} analytics={ANALYTICS_CONSTANTS[data.__component]} />
            </FeatureRowColumn>
          </>
        )}
        {data.mediaOrder === 'right' && (
          <>
            <FeatureRowColumn
              containsMedia={false}
              md={getColumnWidth(true) as boolean}
              contentContainerProps={{
                sx: getFlexJustifyContentValueFromAlignment(data.contentHorizontalAlignment),
                paddingLeft: { xl: '350px' }
              }}
            >
              <FeatureRowBody data={data} analytics={ANALYTICS_CONSTANTS[data.__component]} />
            </FeatureRowColumn>
            <FeatureRowColumn
              md={getColumnWidth() as boolean}
              contentContainerProps={{
                sx: getFlexJustifyContentValueFromAlignment(data.mediaHorizontalAlignment)
              }}
            >
              <Box
                ref={imageRef}
                className='GoSiteFeatureRow-media'
              >
                <Media media={data.media} />
              </Box>
            </FeatureRowColumn>
          </>
        )}
      </Grid>
    )
  }

  if (isSmallerScreen) {
    return (
      <Root
        py={3}
        sx={data.styles}
        mediaOrder={data.mediaOrder}
        textAlign={data.mediaOrder === 'center' ? data.mediaOrder : 'left'}
      >
        <Container>
          <FeatureRowBody data={data} analytics={ANALYTICS_CONSTANTS[data.__component]} />
        </Container>
        <Box className='GoSiteFeatureRow-media'>
          <Media media={data.smallScreenMedia !== null ? data.smallScreenMedia : data.media} />
        </Box>
        <BackgroundMedia media={data.backgroundMedia} objectFit='cover' />
      </Root>
    )
  }

  return (
    <DesktopRoot
      py={3}
      sx={data.styles}
      mediaOrder={data.mediaOrder}
      ref={containerRef}
    >
      <Container
        disableGutters
        maxWidth={
          data.mediaOrder === 'center'
            ? false
            : undefined
        }
      >
        {data.mediaOrder === 'center'
          ? renderCenteredContent()
          : renderHorizontallyAlignedContent()}
      </Container>
      <BackgroundMedia media={data.backgroundMedia} objectFit='cover' />
    </DesktopRoot>
  )
}

const FeatureRowColumn = ({
  containsMedia = true,
  contentContainerProps,
  children,
  ...gridProps
}: FeatureRowColumnProps): JSX.Element => {
  return (
    <Grid
      item
      alignItems='center'
      display='flex'
      sm={12}
      {...gridProps}
      {...contentContainerProps}
    >
      {children}
    </Grid>
  )
}

const getFlexJustifyContentValueFromAlignment = (cmsValue: 'left' | 'center' | 'right' | null): object => {
  switch (cmsValue) {
    case 'left':
      return { justifyContent: 'flex-start' }
    case 'right':
      return { justifyContent: 'flex-end' }
    default:
      return { justifyContent: 'center' }
  }
}

const Root: React.ElementType = styled(Box)(
  ({ theme }) => ({
    position: 'relative',
    '& .MuiContainer-root': {
      zIndex: 2
    }
  })
)

const DesktopRoot: React.ElementType = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    position: 'relative',
    padding: 0,
    '& .MuiContainer-root': {
      maxWidth: 1920
    }
  })
)
export interface FeatureRowProps {
  data: IFeatureRow
}

export type FeatureRowColumnProps = {
  containsMedia?: boolean
  children: React.ReactElement
  contentContainerProps?: GridProps
} & GridProps
