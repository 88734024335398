import React from 'react'
import validator from 'validator'
import { Box, IconButton, Image, Modal, MediaPlayer, Text, Media, styled } from '@gositeinc/ui'

import { IHeroTestimonial } from '../types'

const HeroTestimonial = ({ data }: HeroTestimonialProps): JSX.Element => {
  const [open, setOpen] = React.useState<boolean>(false)

  const isValidURl = (data?.videoUrl !== undefined && data?.videoUrl !== null && data?.videoUrl !== '')
    ? validator.isURL(data?.videoUrl)
    : false

  const handleOpen = (): void => setOpen(true)
  const handleClose = (): void => setOpen(false)

  return (
    <>
      <Root
        onClick={handleOpen}
        sx={{
          cursor: isValidURl ? 'pointer' : 'default'
        }}
      >
        <Box display='flex'>
          <VideoLengthText>
            {data?.videoLength}
          </VideoLengthText>
          <Media media={data?.videoIcon} width={60} height={40} />
        </Box>
        <Box textAlign='left' justifyContent='center' display='flex' flexDirection='column' pl={1.2}>
          <Text
            fontWeight={600}
            fontSize={12}
            width={200}
          >
            {data?.title}
          </Text>
          <Text
            fontWeight={400}
            fontSize={10}
            width={200}
            color='textSecondary'
          >
            {data?.subtitle}
          </Text>
        </Box>
      </Root>
      {isValidURl && (
        <Modal
          open={open}
          onClose={handleClose}
          onBackdropClick={handleClose}
          aria-labelledby='parent-modal-title'
          aria-describedby='parent-modal-description'
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <Box sx={{ width: '100vw' }}>
            <CloseIconButtonRoot onClick={handleClose}>
              <Image src='/images/close.svg' alt='Close' width={20} height={20} />
            </CloseIconButtonRoot>
            <VideoPlayerRoot>
              <MediaPlayer url={data?.videoUrl} width='100%' height='100%' controls={false} playing />
            </VideoPlayerRoot>
          </Box>
        </Modal>
      )}
    </>
  )
}

const Root = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  paddingTop: theme.spacing(3)
}))

const CloseIconButtonRoot = styled(IconButton)(({ theme }) => ({
  top: 0,
  right: 0,
  position: 'absolute',
  zIndex: 999
}))

const VideoPlayerRoot = styled(Box)(({ theme }) => ({
  position: 'relative',
  paddingTop: '56.25%'
}))

const VideoLengthText = styled(Box)(({ theme }) => ({
  ...theme.typography.caption2,
  alignSelf: 'center',
  color: theme.palette.primary.main,
  display: 'flex',
  marginRight: 8,
  position: 'relative',
  zIndex: 1,
  '&:after': {
    backgroundColor: theme.palette.primary.main,
    content: '""',
    height: 1,
    margin: '-1px 0 0 4px',
    position: 'absolute',
    left: '100%',
    top: '50%',
    width: 8
  }
}))

interface HeroTestimonialProps {
  data: IHeroTestimonial
}

export default HeroTestimonial
