import React from 'react'
import { useTime } from 'react-timer-hook'
import { IoCellular, IoBatteryFull } from 'react-icons/io5'
import { IoIosWifi } from 'react-icons/io'
import {
  Box,
  Card,
  CardProps,
  Image,
  styled
} from '@gositeinc/ui'

const PHONE_HEIGHT = 432
const PHONE_BORDER_SIZE = 8
const STATUS_BAR_HEIGHT = 24
const EMAIL_APP_TOP_BAR_HEIGHT = 18
const EMAIL_APP_BOTTOM_NAV_HEIGHT = 44
const EMAIL_APP_BODY_HEIGHT = PHONE_HEIGHT - STATUS_BAR_HEIGHT - EMAIL_APP_TOP_BAR_HEIGHT - EMAIL_APP_BOTTOM_NAV_HEIGHT - PHONE_BORDER_SIZE * 2

const Phone = React.forwardRef(({
  showStatusBar = true,
  showPhoneEmailShell = false,
  children,
  ...cardProps
}: PhoneProps, ref: React.ForwardedRef<HTMLDivElement>): JSX.Element => {
  const { minutes, hours } = useTime({ format: undefined })

  return (
    <div ref={ref}>
      <PhoneShell {...cardProps}>
        {showStatusBar && (
          <StatusBar>
            <StatusBarTime>
              <span>{hours}</span>:<span>{minutes < 10 ? `0${minutes}` : minutes}</span>
            </StatusBarTime>
            <StatusBarIcons>
              <IoCellular size={10} />
              <IoIosWifi size={10} />
              <IoBatteryFull size={11} />
            </StatusBarIcons>
          </StatusBar>
        )}
        {showPhoneEmailShell && (
          <EmailAppTopNav>
            <Image
              src='/images/phone-email-app-top-bar.svg'
              alt='phone-email-app-top-bar'
              width={201}
              height={19}
            />
          </EmailAppTopNav>
        )}
        <EmailAppBody>
          {children}
        </EmailAppBody>
        {showPhoneEmailShell && (
          <EmailAppBottomBar>
            <Image
              src='/images/phone-email-app-bottom-nav.svg'
              alt='phone-email-app-bottom-nav'
              width={201}
              height={44}
            />
          </EmailAppBottomBar>
        )}
      </PhoneShell>
    </div>
  )
})

const PhoneShell = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: '20px',
  border: `${PHONE_BORDER_SIZE}px solid #FAFAFA`,
  display: 'flex',
  flexDirection: 'column',
  height: PHONE_HEIGHT,
  position: 'relative',
  width: 200
}))

const StatusBar = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  borderRadius: '20px 20px 0 0',
  display: 'flex',
  height: STATUS_BAR_HEIGHT,
  justifyContent: 'space-between',
  padding: theme.spacing(0.75, 1)
}))

const StatusBarTime = styled(Box)(({ theme }) => ({ fontSize: 8, fontWeight: 600 }))

const StatusBarIcons = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontSize: 10,
  gap: '4px'
}))

const EmailAppTopNav = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  height: EMAIL_APP_TOP_BAR_HEIGHT
}))

const EmailAppBody = styled(Box)(({ theme }) => ({
  // flexGrow: 1,
  overflowY: 'auto',
  height: EMAIL_APP_BODY_HEIGHT
}))

const EmailAppBottomBar = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey[200]}`,
  height: EMAIL_APP_BOTTOM_NAV_HEIGHT
  // position: 'absolute',
  // bottom: '0px',
  // width: '100%'
}))

interface PhoneProps extends CardProps {
  showStatusBar?: boolean
  showPhoneEmailShell?: boolean
  children: React.ReactNode
}

Phone.displayName = 'Phone'

export default Phone
