import { SvgIcon, SvgIconProps } from '@gositeinc/ui'

import MailAtComponent from '../public/images/mail-at.svg'
import RightArrowComponent from '../public/images/arrow-right.svg'
import SmartPhoneComponent from '../public/images/smartphone.svg'
import LinktextIconComponent from '../public/images/linktextIcon.svg'
import DownloadComponent from '../public/images/downloadInvoice.svg'
import ChevronDownComponent from '../public/images/chevron.svg'

export const MailAt = (props: SvgIconProps): JSX.Element => <SvgIcon {...props} component={MailAtComponent} viewBox='0 0 48 48' />

export const RightArrow = (props: SvgIconProps): JSX.Element => <SvgIcon {...props} component={RightArrowComponent} viewBox='0 0 48 48' />

export const SmartPhone = (props: SvgIconProps): JSX.Element => <SvgIcon {...props} component={SmartPhoneComponent} viewBox='0 0 48 48' />

export const LinktextIcon = (props: SvgIconProps): JSX.Element => <SvgIcon {...props} component={LinktextIconComponent} viewBox='0 0 48 48' />

export const Download = (props: SvgIconProps): JSX.Element => <SvgIcon {...props} component={DownloadComponent} viewBox='0 0 48 48' />

export const ChevronDown = (props: SvgIconProps): JSX.Element => <SvgIcon {...props} component={ChevronDownComponent} viewBox='0 0 926.23699 573.74994' />
