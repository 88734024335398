import React from 'react'
import { Card, CardContent, Form, UseFormStateChange, styled, Theme } from '@gositeinc/ui'

import { IContactHubWidgetPayload, IHeroContactHubWidget } from 'src/types'
import { HeroWidgetModifierStyle } from './hero-widget'

const ContactHubWidget = ({ data, onDone }: ContactHubWidgetProps): JSX.Element => {
  const onChange = ({ formValues, control }: UseFormStateChange): void => {
    if (formValues != null) {
      if (Object.values(formValues).every(field => field != null && field !== '')) {
        return onDone({ formValues })
      }
    }
  }
  return (
    <Root>
      <Card elevation={10}>
        <CardContent sx={{ p: 3 }}>
          <Form onChange={onChange} form={data.form} />
        </CardContent>
      </Card>
    </Root>
  )
}
const Root = styled(CardContent)(({ theme }) => ({
  width: '25rem',
  alignItems: 'left',
  [theme.breakpoints.down('sm')]: {
    width: '23rem'
  },
  '& .MuiTypography-root ': {
    ...theme.typography.h6,
    textAlign: 'left'
  },
  '& .MuiFormLabel-root': {
    ...theme.typography.caption,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold
  },
  '& .MuiInputBase-input': {
    height: 16
  },
  '& .MuiButton-root': {
    height: 42
  }
}))
interface ContactHubWidgetProps {
  data: IHeroContactHubWidget
  onDone: (payload: IContactHubWidgetPayload) => void
}
export const contactHubHeroWidgetModifierStyle: HeroWidgetModifierStyle = {
  showFade: false,
  heroWidgetGridPropsCreator: (theme: Theme) => ({
    md: 6,
    lg: 6,
    sx: {
      [theme.breakpoints.down('md')]: {
        paddingRight: 0
      }
    }
  }),
  heroBodyGridPropsCreator: (theme: Theme) => ({
    md: 6,
    lg: 6,
    sx: {
      [theme.breakpoints.down('md')]: {
        paddingLeft: 0
      }
    }
  })
}
export default ContactHubWidget
