import React, { useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
// import Dots from 'react-carousel-dots'
import {
  Box,
  Button,
  Badge,
  BODY_FONT_FAMILY,
  buttonBaseClasses,
  Container,
  containerClasses,
  Grid,
  Image,
  Link,
  Media,
  Stack,
  styled,
  Tabs,
  Tab,
  TabPanel,
  Text,
  tabsClasses,
  TITLE_FONT_FAMILY,
  useTheme,
  useButton,
  useMediaQuery
} from '@gositeinc/ui'

import { IForegroundMedia, ITabPanel, ITabView } from '../types'
import * as Assets from '../assets'
import { tabPanelItemClasses, tabPanelViewClasses } from '../classes'
import { ANALYTICS_CONSTANTS } from 'src/analytics-constants'

export const TabViewSection = ({ data, analytics }: TabViewSectionProps): JSX.Element => {
  const theme = useTheme()
  const { getVariant } = useButton()
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('lg'))
  const [tabIndex, setTabIndex] = useState(0)

  const {
    labelIcon,
    labelText,
    title,
    tabMenuItems,
    tabPanels,
    mediaOrder,
    styles
  } = data

  // const renderMedia = (item) => {

  // }

  const onClickTabMenuItem = (event: React.SyntheticEvent, newTabIndex: number): void => {
    setTabIndex(Number(newTabIndex))
  }

  const onSwipeTab = (newTabIndex: number): void => {
    setTabIndex(newTabIndex)
  }

  const renderTabMenuItemforDesktopView = (): JSX.Element => {
    return (
      <Grid item md={4} pt={14} display='flex' justifyContent={mediaOrder === 'right' ? 'flex-end' : 'flex-start'}>
        <Tabs
          orientation='vertical'
          variant='scrollable'
          value={tabIndex}
          onChange={onClickTabMenuItem}
          sx={{
            position: 'sticky',
            top: '200px'
          }}
          TabIndicatorProps={{
            style: {
              display: 'none'
            }
          }}
        >
          {tabMenuItems.map((tabItem, index) => (
            <StyledTab
              disableRipple
              id='styledTab'
              sx={{ alignItems: 'flex-start' }}
              key={tabItem?.id}
              label={(
                <>
                  <span style={{ fontFamily: TITLE_FONT_FAMILY, fontSize: 20, textTransform: 'none', color: (tabIndex === index ? 'black' : 'grey') }}>
                    {tabItem?.labelText}
                  </span>
                  {tabIndex === index &&
                    <StyledBox>
                      <Image src='/images/swoosh.svg' alt='bubble' />
                    </StyledBox>}
                </>
              )}

            />
          ))}
        </Tabs>
      </Grid>
    )
  }

  const renderTabConentForDesktopView = (): JSX.Element => {
    return (
      <Grid item md={8} pt={11}>
        <Box className='GositeTabView-contentRoot'>
          {tabPanels.map((tabPanelItem, index) => (
            <TabPanel value={tabIndex} index={index} key={tabPanelItem.id}>
              {tabMenuItems.map((tabMenuItem, index) => (
                tabIndex === index && <Media key={tabMenuItem.id} media={tabMenuItem.labelIcon} width={24} />
              ))}
              <Text rich className={tabPanelItemClasses.title}>{tabPanelItem.title}</Text>
              <Text rich className={tabPanelItemClasses.content}>{tabPanelItem.content}</Text>
              <Stack>
                {renderButton(tabPanelItem)}
                {renderSecondaryButton(tabPanelItem)}
              </Stack>
              <Grid container display='flex' pt={5}>
                <Grid item>
                  <Media media={tabPanelItem?.testimonial?.videoIcon} width={27} height={20} />
                </Grid>
                <Grid pl={2}>
                  <Text
                    fontWeight={600}
                    width={300}
                    fontSize={14}
                    gutterBottom
                  >
                    {tabPanelItem?.testimonial?.title}
                  </Text>
                  <Text
                    fontWeight={400}
                    fontSize={10}
                    width={200}
                    color='textSecondary'
                    gutterBottom

                  >
                    {tabPanelItem?.testimonial?.subtitle}
                  </Text>
                </Grid>
              </Grid>
            </TabPanel>
          ))}
        </Box>
      </Grid>
    )
  }

  const renderTabMediaList = (mediaList: IForegroundMedia[]): JSX.Element => {
    if (mediaList.length === 0) {
      return <></>
    }

    return (
      <Box sx={{ textAlign: 'center' }} className='GoSiteTabView-foregroundMedia'>
        {mediaList.map(media => (
          <Box
            key={media.id}
            className='GoSiteTabView-foregroundMediaItem'
            sx={media.styles}
          >
            <Media media={media.media} />
          </Box>
        ))}
      </Box>
    )
  }

  // const renderTabMediaForDesktopView = (): JSX.Element => {
  //   return (
  //     <Box sx={{ position: 'relative', background: 'red', top: 0, left: 0 }} className='GoSiteTabMediaSection-root'>
  //       {tabPanels[tabIndex].foregroundMedia[0].foregroundMedia.length > 0 && tabPanels[tabIndex].foregroundMedia[0].foregroundMedia.map(media => (
  //         <Media key={media.id} media={media.media} sx={{ ...media.styles }} />
  //       ))}
  //     </Box>
  //   )
  // }

  const switchTabAndMediaBasedOnAlignment = (): object => {
    switch (mediaOrder) {
      case 'left':
        return { flexDirection: 'row-reverse' }
      default:
        return { flexDirection: 'row' }
    }
  }

  const getFlexJustifyContentValueFromAlignment = (cmsValue: 'left' | 'center' | 'right' | null): object => {
    switch (cmsValue) {
      case 'left':
        return { justifyContent: 'flex-start' }
      case 'right':
        return { justifyContent: 'flex-end' }
      default:
        return { justifyContent: 'center' }
    }
  }

  const renderTabView = (): JSX.Element => {
    if (isSmallerScreen) {
      return (
        <MobileRoot>
          <Box pt={5}>
            <SwipeableViews enableMouseEvents>
              {renderTabMediaList(tabPanels[tabIndex].smallScreenForegroundMedia)}
            </SwipeableViews>
          </Box>
          <Tabs
            value={tabIndex}
            onChange={onClickTabMenuItem}
            variant='scrollable'
            scrollButtons
            // allowScrollButtonsMobile
            id='stack-id'
            TabIndicatorProps={{
              style: {
                display: 'none'
              }
            }}
          >
            {tabMenuItems.map((tabItem, index) => (
              <Tab
                key={tabItem?.id}
                label={(
                  <Stack direction='row' gap={1}>
                    {tabIndex === index
                      ? (
                        <>
                          <Media media={tabItem.labelIcon} width={15} height={15} />
                          <span style={{ fontFamily: TITLE_FONT_FAMILY, fontSize: 16, textTransform: 'none', color: 'black' }}>
                            {tabItem?.labelText}
                          </span>
                        </>)
                      : (
                        <span style={{ fontFamily: TITLE_FONT_FAMILY, fontSize: 16, textTransform: 'none', color: 'grey' }}>
                          {tabItem?.labelText}
                        </span>)}
                  </Stack>
                )}
              />
            ))}
          </Tabs>
          <SwipeableViews onChangeIndex={onSwipeTab} index={tabIndex}>
            {tabPanels.map((tabPanelItem, index) => (
              <Box padding={theme.spacing(2)} key={tabPanelItem.id}>
                <TabPanel value={tabIndex} index={index}>
                  <Text rich className={tabPanelItemClasses.title}>{tabPanelItem.title}</Text>
                  <Text rich className={tabPanelItemClasses.content}>{tabPanelItem.content}</Text>
                  <Stack direction='column'>
                    {renderButton(tabPanelItem)}
                    {renderSecondaryButton(tabPanelItem)}
                  </Stack>
                </TabPanel>
              </Box>
            ))}
          </SwipeableViews>
          <DotIndicators className={tabPanelItemClasses.dotIndicators}>
            {tabMenuItems.map((tabItem, index) => (
              <Badge
                key={index}
                sx={{ paddingLeft: 3 }}
              >
                <Box component='span' sx={{ bgcolor: tabIndex === index ? theme.palette.primary.main : theme.palette.primary.light, width: 8, height: 8, borderRadius: '50%' }} />
              </Badge>
            ))}
          </DotIndicators>
          {/* <Box display='flex' justifyContent='center' padding={2}>
            <Dots length={4} active={tabIndex} size='10px' />
          </Box> */}
        </MobileRoot>
      )
    }
    return (
      <DesktopRoot sx={styles}>
        <Container
          // sx={
          //   (mediaOrder === 'right' ? ({ paddingLeft: { lg: 24 }, marginRight: 0 }) : ({ paddingRight: { lg: 20 }, marginLeft: 0 }))
          // }
          disableGutters
        >
          <Grid
            container
            // columnSpacing={mediaOrder === 'right' ? 0 : 8}
            {...switchTabAndMediaBasedOnAlignment()}
          >
            <Grid
              item
              md={5}
            // height='400px'
            >
              <Grid container columnSpacing={mediaOrder === 'right' ? 6 : 0} {...switchTabAndMediaBasedOnAlignment()}>
                {renderTabMenuItemforDesktopView()}
                {renderTabConentForDesktopView()}
              </Grid>
            </Grid>
            <Grid
              item
              md={7}
              display='flex'
              position='relative'
              {...getFlexJustifyContentValueFromAlignment(tabPanels[tabIndex].mediaHorizontalAlignment)}
            >
              {renderTabMediaList(tabPanels[tabIndex].foregroundMedia)}
            </Grid>
          </Grid>
        </Container>

      </DesktopRoot>
    )
  }

  const renderHeaderContent = (): JSX.Element => {
    return (
      <Stack
        padding={isSmallerScreen ? theme.spacing(2) : theme.spacing(8)}
        alignItems='center'
        textAlign='center'
        className={tabPanelViewClasses.header}
      >
        <Stack
          direction='row'
          alignItems='center'
          padding={2}
          gap={2}
        >
          <Media media={labelIcon} width={20} height={20} />
          <Text
            fontWeight={theme.typography.fontWeightSemiBold}
            fontFamily={BODY_FONT_FAMILY}
            color='#576777'
            fontSize={(isSmallerScreen ? '16px' : '20px')}
          >
            {labelText}
          </Text>
        </Stack>

        <Text
          rich
          className={tabPanelViewClasses.title}
          // fontSize={(isSmallerScreen ? 24 : 48)}
          gutterBottom
        >
          {title}
        </Text>
      </Stack>
    )
  }

  const renderButton = (tabPanel: ITabPanel): JSX.Element => {
    const { primaryButton: link } = tabPanel
    return (
      <Link
        className='GoSiteTabView-primaryButtonLink GoSiteTabView-buttonLink'
        link={link}
        data-analytics={analytics?.primaryButton}
        data-analytics-action-title={tabPanel?.primaryButton?.text}
        data-analytics-section-title={title}
        data-analytics-tab-id={tabPanel?.id}
        data-analytics-tab-title={tabPanel?.title}
      >
        <Button
          className='GoSiteTabView-primaryButton'
          variant={getVariant(link?.variant)}
          color='primary'
          size='large'
          fullWidth={isSmallerScreen}
          sx={{
            minHeight: 43,
            borderRadius: 50,
            textTransform: 'none'
            // width: 300
          }}
          endIcon={<Assets.RightArrow pathColor={theme.palette.primary.main} />}
        >
          {link?.text}
        </Button>

      </Link>
    )
  }

  const renderSecondaryButton = (tabPanel: ITabPanel): JSX.Element => {
    const { secondaryButton: link } = tabPanel
    return (
      <Link
        className='GoSiteTabView-secondaryButtonLink GoSiteTabView-buttonLink'
        link={link}
        data-analytics={analytics?.secondaryButton}
        data-analytics-action-title={tabPanel?.secondaryButton?.text}
        data-analytics-section-title={title}
        data-analytics-tab-id={tabPanel?.id}
        data-analytics-tab-title={tabPanel?.title}
      >
        <Button
          className='GoSiteTabView-secondaryButton'
          fullWidth={isSmallerScreen}
          variant='text'
          color='primary'
          size='large'
          sx={{
            textTransform: 'none',
            textDecoration: 'underline',
            '&:hover': {
              backgroundColor: 'transparent !important'
            }
          }}
        >
          {link?.text}
        </Button>

      </Link>
    )
  }

  return (
    <Root sx={styles}>
      {renderHeaderContent()}
      {renderTabView()}
    </Root>
  )
}
const StyledTab = styled(Tab)(({ theme }) => ({
  width: 'fit-content'
}))
const StyledBox = styled(Box)(({ theme }) => ({
  padding: 0,
  'div:first-child': {
    width: '100% !important'
  },
  img: {
    top: '40px !important',
    padding: '16px 0 !important'
  }
}))

const DesktopRoot: React.ElementType = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    position: 'relative',
    paddingBottom: '140px !important',
    [`& .${containerClasses.root}`]: {
      maxWidth: 1920
    }
  })
)

const Root = styled(Box)(({ theme }) => ({
  [`.${tabPanelViewClasses.title} h2`]: {
    ...theme.typography.h4
  },
  [`.${tabPanelViewClasses.header} .${tabPanelViewClasses.title} h2`]: {
    ...theme.typography.h2
  },

  [theme.breakpoints.down('md')]: {
    [`.${tabPanelViewClasses.header} .${tabPanelViewClasses.title} h2`]: {
      ...theme.typography.h4
    },
    [`& .${tabsClasses.flexContainer} .${buttonBaseClasses.root}`]: {
      //  boxShadow: theme.shadows[6],
      margin: theme.spacing(0.5)
    },
    '& .Mui-selected': {
      boxShadow: theme.shadows[4],
      borderRadius: '10px'
    },
    '& .MuiButtonBase-root': {
      padding: '5px 10px'
    },
    '& .react-carousel-dots-dot': {
      backgroundColor: '#DBE3EB',
      border: '1px'
    },
    '& .react-carousel-dots-dot.active': {
      backgroundColor: '#0357FF',
      border: '1px'
    }
  }
}))

const MobileRoot = styled(Box)(({ theme }) => ({
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    [`& .${tabsClasses.flexContainer} .${buttonBaseClasses.root}`]: {
      //  boxShadow: theme.shadows[6],
      margin: theme.spacing(0.5)
    },
    '& .Mui-selected': {
      boxShadow: theme.shadows[4],
      borderRadius: '10px'
    },
    '& .MuiButtonBase-root': {
      padding: '5px 10px'
    },
    '& .react-carousel-dots-dot': {
      backgroundColor: '#DBE3EB',
      border: '1px'
    },
    '& .react-carousel-dots-dot.active': {
      backgroundColor: '#0357FF',
      border: '1px'
    }
  }
}))
const DotIndicators = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center'
}))
export interface TabViewSectionProps {
  data: ITabView
  analytics: typeof ANALYTICS_CONSTANTS['sections.tab-view-section']
}
