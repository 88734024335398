import { Box, Card, CardContent, Container, Rating, Stack, Text, useMediaQuery, useTheme, styled } from '@gositeinc/ui'

import { ITestimonialColumnsGroup } from '../types'
import { testimonialColumnsGroupClasses } from '../classes'

const TestimonialColumnsGroup = ({ data }: TestimonialColumnsGroupProps): JSX.Element => {
  const theme = useTheme()
  const isSmallerScreen: boolean = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <>
      <Root className={testimonialColumnsGroupClasses.root}>
        <Box sx={{
          textAlign: 'center',
          padding: theme.spacing(6, 0),
          paddingTop: 0
        }}
        >
          <Title
            rich
            className={testimonialColumnsGroupClasses.title}
          >
            {data.title}
          </Title>
          <Text
            rich
            className={testimonialColumnsGroupClasses.description}
            color='text.secondary'
          >
            {data.description}
          </Text>
        </Box>
        <Stack
          direction={(isSmallerScreen !== null && isSmallerScreen ? 'column' : 'row')}
          gap={3}
          alignItems='stretch'
        >
          {data.items.map((item, index) => (
            <TestimonialItem
              variant='outlined'
              key={item.id}
            >
              <CardContent>
                {item.stars != null && (
                  <Rating sx={{ pt: 3.75 }} name='read-only' value={item.stars} readOnly />
                )}
                <FeatureTitle
                  rich
                  className={testimonialColumnsGroupClasses.quote}
                >
                  {item.quote}
                </FeatureTitle>
                {item.author != null && (
                  <Text
                    rich
                    className={testimonialColumnsGroupClasses.author}
                    color={theme.palette.text.primary}
                    sx={{ py: 0.5, textAlign: 'left' }}
                  >
                    {item.author}
                  </Text>
                )}
                {item.company != null && (
                  <Text
                    rich
                    className={testimonialColumnsGroupClasses.company}
                    sx={{ textAlign: 'left' }}
                  >
                    {item.company}
                  </Text>
                )}
              </CardContent>
            </TestimonialItem>
          ))}
        </Stack>
      </Root>
    </>
  )
}

const Root = styled(Container)(({ theme }) => ({
  [`.${testimonialColumnsGroupClasses.title} h2`]: {
    ...theme.typography.h2
  },
  [theme.breakpoints.down('md')]: {
    [`.${testimonialColumnsGroupClasses.title} h2`]: {
      ...theme.typography.h4
    }
  },
  [`.${testimonialColumnsGroupClasses.description} p`]: {
    ...theme.typography.heading,
    fontWeight: theme.typography.fontWeightRegular
  },
  [`.${testimonialColumnsGroupClasses.quote} p`]: {
    ...theme.typography.body1
  },
  [`.${testimonialColumnsGroupClasses.quote} h2`]: {
    ...theme.typography.body1
  },
  [`.${testimonialColumnsGroupClasses.author} p`]: {
    ...theme.typography.body1,
    fontWeight: theme.typography.fontWeightBold
  },
  [`.${testimonialColumnsGroupClasses.company} p`]: {
    ...theme.typography.subtitle2,
    fontWeight: theme.typography.fontWeightSemiBold
  },
  paddingTop: theme.spacing(12),
  paddingBottom: theme.spacing(12),
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

const Title = styled(Text)(({ theme }) => ({
  ...theme.typography.h3,
  paddingBottom: theme.spacing(2)
}))

const TestimonialItem = styled(Card)(({ theme }) => ({
  borderRadius: 10,
  minHeight: 277,
  boxShadow: `0 1px 2px rgba(0,0,0,0.02), 
              0 2px 4px rgba(0,0,0,0.02), 
              0 4px 8px rgba(0,0,0,0.02), 
              0 8px 16px rgba(0,0,0,0.02),
              0 16px 32px rgba(0,0,0,0.02), 
              0 32px 64px rgba(0,0,0,0.02)`,
  [theme.breakpoints.up('md')]: {
    width: 278
  },
  '& .MuiCardContent-root': {
    padding: theme.spacing(3)
  }
}))

const FeatureTitle = styled(Text)(({ theme }) => ({
  padding: theme.spacing(1.25, 0, 1, 0)
}))

interface TestimonialColumnsGroupProps {
  data: ITestimonialColumnsGroup
}

export type { TestimonialColumnsGroupProps }
export default TestimonialColumnsGroup
