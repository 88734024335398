import React, { useEffect, useState } from 'react'
import intersectionBy from 'lodash/intersectionBy'
import { Box, Text, PricingTable, IPricingSubscription, CircularProgress, styled, theme } from '@gositeinc/ui'
import { IPricing } from '../types'
import { fetchAPI } from 'src/utils/api'
import { pricingClasses } from 'src/classes'
import { useAnalytics } from '../providers'
import { getLinkWithSearchParams } from 'src/utils'

export const Pricing = ({ data }: PricingProps): JSX.Element => {
  const { title, defaultSubscription, sliders, styles, subscriptions } = data
  const [pricingSubscriptionData, setPricingSubscriptionData] = useState<IPricingSubscription[]>([])
  const [isReady, setIsReady] = useState<boolean>(false)
  const { analytics } = useAnalytics()

  const fetchData = (): void => {
    fetchAPI('/pricing-subscriptions?status=published')
      .then((_subscriptions: IPricingSubscription[]) => {
        setPricingSubscriptionData(intersectionBy(_subscriptions, subscriptions, 'id'))
      }).catch((err) => console.log(err))
  }

  const renderPricingTable = (): JSX.Element => {
    const TRACKING_EVENT_PREFIX = 'mkt_'
    if (!isReady) {
      return (
        <Box textAlign='center'>
          <CircularProgress />
        </Box>
      )
    }
    const getPlanData = (event, state): void => {
      let data = {}
      if (state?.sku != null && state.sku.config != null && state.sku.config.trackingEventName != null) {
        data = {
          planName: state.sku.name,
          planSubscription: state.sku.billingCycle
        }
        analytics?.track(TRACKING_EVENT_PREFIX + String(state.sku.config.trackingEventName), data)
        const urlType = state.newTab === true ? '_blank' : '_self'
        window.open(getLinkWithSearchParams(state.redirectUrl as string), urlType)
      }
    }

    const onChangeSubscription = (event, params): void => {
      let data = {}
      if (params?.subscription != null && params.subscription.config != null && params.subscription.config.trackingEventName != null) {
        data = {
          name: params.subscription.name
        }
        analytics?.track(TRACKING_EVENT_PREFIX + String(params.subscription.config.trackingEventName), data)
      }
    }

    const onChangeSliderCommitted = (event, params): void => {
      let data = {}
      if (params?.priceParam != null && params.priceParam.config != null && params.priceParam.config.trackingEventName != null) {
        data = {
          name: params.priceParam.name,
          value: params.priceParam.value
        }
        analytics?.track(TRACKING_EVENT_PREFIX + String(params.priceParam.config.trackingEventName), data)
      }
    }

    const onChangePricingPlanProductCheckbox = (event, params): void => {
      let data = {}
      if (params?.sku != null) {
        const selectedProduct = params.sku.optionalProducts.find(product => product.id === params.productId)
        if (selectedProduct.config != null && selectedProduct?.config.trackingEventName != null) {
          data = {
            name: selectedProduct.name,
            planName: params.sku.name,
            planSubscription: params.sku.billingCycle
          }
          analytics?.track(TRACKING_EVENT_PREFIX + String(selectedProduct.config.trackingEventName), data)
        }
      }
    }

    return (
      <PricingTable
        data={pricingSubscriptionData}
        priceParams={sliders}
        defaultSubscription={defaultSubscription}
        onClickPricingPlanButton={getPlanData}
        onChangeSubscription={onChangeSubscription}
        onChangeSliderCommitted={onChangeSliderCommitted}
        onChangePricingSkuProductCheckbox={onChangePricingPlanProductCheckbox}
      />
    )
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (pricingSubscriptionData.length > 0) {
      setTimeout(() => {
        setIsReady(true)
      }, 200)
    }
  }, [pricingSubscriptionData])

  return (
    <Root sx={styles}>
      <Box
        sx={{
          justifyContent: 'center',
          display: 'flex'
        }}
      >
        <Box
          sx={{
            padding: theme.spacing(0, 5, 4, 5),
            position: 'relative',
            width: 'fit-content'
          }}
        >
          <PricingTitle
            rich
            className={pricingClasses.title}
          >
            {title}
          </PricingTitle>
        </Box>
      </Box>
      {renderPricingTable()}
    </Root>
  )
}

const Root = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(15),
  minHeight: 900,
  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(10)
  }
}))

const PricingTitle = styled(Text)(({ theme }) => ({
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.5rem'
  }
}))

interface PricingProps {
  data: IPricing
}

export type { PricingProps }
