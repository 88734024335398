import React from 'react'
import {
  Box,
  BoxProps,
  Text,
  Media,
  BackgroundMedia,
  styled
} from '@gositeinc/ui'

import { ITestimonial3 } from '../types'
import { TestimonialTabItem } from './testimonial-tab-item'
import { testimonial3Classes } from '../classes'

export const Testimonial3 = ({ data, ...boxProps }: Testimonial3Props): JSX.Element => {
  const {
    backgroundImage,
    businessImage,
    topReviewImage,
    topReviewImageShown,
    topBannerTextShown,
    topBannerText
  } = data

  return (
    <TestimonialTabItem
      data={data}
      HeaderComponent={(
        <ComponentRoot textAlign='center'>
          {topReviewImageShown ?? <Media media={topReviewImage} mediaStyle={{ width: 'auto' }} />}
          {topBannerTextShown ??
            <Text
              className={testimonial3Classes.topBannerText}
              mt={2}
              rich
              gutterBottom
            >
              {topBannerText}
            </Text>}
          <Media
            media={businessImage}
          />
        </ComponentRoot>
      )}
      BgImageComponent={(
        <BackgroundMedia media={backgroundImage} objectFit='cover' />
      )}
    />
  )
}

const ComponentRoot = styled(Box)(({ theme }) => ({
  [`.${testimonial3Classes.topBannerText} h2`]: {
    ...theme.typography.h5,
    fontSize: theme.typography.pxToRem(28)
  }
}))

export type Testimonial3Props = {
  data: ITestimonial3
} & BoxProps
