import { IMetadata } from '@gositeinc/ui'
import { OpenGraph } from 'next-seo/lib/types'

import { getStrapiMedia } from './media'

export const getOpenGraphMediaData = (metadata: IMetadata): OpenGraph | undefined => {
  if (metadata?.shareImage?.formats == null) {
    return undefined
  }

  return {
    images: Object.values(metadata.shareImage.formats).map((image) => {
      return {
        url: getStrapiMedia(image.url) ?? '',
        width: image.width,
        height: image.height
      }
    })
  }
}
