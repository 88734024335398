interface GetEnvVars {
  appEnv: 'local' | 'development' | 'test' | 'production'
  emulateOldSiteUI: boolean
  strapiDomain: string
  hubspotDomain: string
  segmentWriteKey: string
  googleTagManagerId: string
  pageLockPassword: string
  intercomAppId: string
  googlePlacesApiKey: string
  hubspotAccId: string
  hubspotHeroCtaFormId: string
  hubspotHeroSignUpFormId: string
  optimizeContainerId: string
  v1GositeDashboard: string
  googleSiteVerification: string
  termlyWebsiteUUID: string
}

const getEnvVars = (): GetEnvVars => {
  let appEnv
  const emulateOldSiteUI = process.env.NEXT_PUBLIC_EMULATE_OLD_SITE_UI != null && process.env.NEXT_PUBLIC_EMULATE_OLD_SITE_UI === 'y'
  let strapiDomain: string = process.env.NEXT_PUBLIC_LOCAL_STRAPI_API_URL ?? ''
  let hubspotDomain: string = ''
  let segmentWriteKey: string = ''
  let googleTagManagerId: string = ''
  let pageLockPassword: string = ''
  let intercomAppId: string = ''
  let googlePlacesApiKey: string = ''
  let hubspotAccId: string = ''
  let hubspotHeroCtaFormId: string = ''
  let hubspotHeroSignUpFormId: string = ''
  let optimizeContainerId: string = ''
  let v1GositeDashboard: string = ''
  let googleSiteVerification: string = ''
  let termlyWebsiteUUID: string = ''

  switch (process.env.NEXT_PUBLIC_APP_ENV) {
    case 'local':
      appEnv = 'local'
      strapiDomain = process.env.NEXT_PUBLIC_LOCAL_STRAPI_API_URL as string
      hubspotDomain = process.env.NEXT_PUBLIC_LOCAL_HUBSPOT_API_URL as string
      pageLockPassword = process.env.NEXT_PUBLIC_DEVELOPMENT_PAGE_LOCK_PASSWORD as string
      intercomAppId = process.env.NEXT_PUBLIC_INTERCOM_CHAT_APP_ID as string
      googlePlacesApiKey = process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY as string
      v1GositeDashboard = process.env.NEXT_PUBLIC_TEST_V1_GOSITE_DASHBOARD_URL as string
      googleSiteVerification = process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION as string
      termlyWebsiteUUID = process.env.NEXT_PUBLIC_PRODUCTION_TERMLY_WEBSITE_UUID as string
      break
    case 'development':
      appEnv = 'development'
      strapiDomain = process.env.NEXT_PUBLIC_DEVELOPMENT_STRAPI_API_URL as string
      hubspotDomain = process.env.NEXT_PUBLIC_DEVELOPMENT_HUBSPOT_API_URL as string
      pageLockPassword = process.env.NEXT_PUBLIC_DEVELOPMENT_PAGE_LOCK_PASSWORD as string
      intercomAppId = process.env.NEXT_PUBLIC_INTERCOM_CHAT_APP_ID as string
      googlePlacesApiKey = process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY as string
      v1GositeDashboard = process.env.NEXT_PUBLIC_TEST_V1_GOSITE_DASHBOARD_URL as string
      googleSiteVerification = process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION as string
      termlyWebsiteUUID = process.env.NEXT_PUBLIC_PRODUCTION_TERMLY_WEBSITE_UUID as string
      break
    case 'test':
      appEnv = 'test'
      strapiDomain = process.env.NEXT_PUBLIC_TEST_STRAPI_API_URL as string
      hubspotDomain = process.env.NEXT_PUBLIC_TEST_HUBSPOT_API_URL as string
      segmentWriteKey = process.env.NEXT_PUBLIC_TEST_SEGMENT_WRITE_KEY as string
      googleTagManagerId = process.env.NEXT_PUBLIC_TEST_GOOGLE_TAG_MANAGER_ID as string
      pageLockPassword = process.env.NEXT_PUBLIC_TEST_PAGE_LOCK_PASSWORD as string
      intercomAppId = process.env.NEXT_PUBLIC_INTERCOM_CHAT_APP_ID as string
      hubspotAccId = process.env.NEXT_PUBLIC_TEST_HUBSPOT_ACC_ID as string
      hubspotHeroCtaFormId = process.env.NEXT_PUBLIC_TEST_HUBSPOT_HERO_CTA_FORM_ID as string
      hubspotHeroSignUpFormId = process.env.NEXT_PUBLIC_TEST_HUBSPOT_HERO_SIGNUP_FORM_ID as string
      optimizeContainerId = process.env.NEXT_PUBLIC_OPTIMIZE_CONTAINER_ID as string
      v1GositeDashboard = process.env.NEXT_PUBLIC_TEST_V1_GOSITE_DASHBOARD_URL as string
      googleSiteVerification = process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION as string
      termlyWebsiteUUID = process.env.NEXT_PUBLIC_PRODUCTION_TERMLY_WEBSITE_UUID as string
      break
    case 'production':
    default:
      appEnv = 'production'
      strapiDomain = process.env.NEXT_PUBLIC_PRODUCTION_STRAPI_API_URL as string
      hubspotDomain = process.env.NEXT_PUBLIC_PRODUCTION_HUBSPOT_API_URL as string
      segmentWriteKey = process.env.NEXT_PUBLIC_PRODUCTION_SEGMENT_WRITE_KEY as string
      googleTagManagerId = process.env.NEXT_PUBLIC_PRODUCTION_GOOGLE_TAG_MANAGER_ID as string
      pageLockPassword = process.env.NEXT_PUBLIC_PRODUCTION_PAGE_LOCK_PASSWORD as string
      intercomAppId = process.env.NEXT_PUBLIC_INTERCOM_CHAT_APP_ID as string
      hubspotAccId = process.env.NEXT_PUBLIC_PRODUCTION_HUBSPOT_ACC_ID as string
      hubspotHeroCtaFormId = process.env.NEXT_PUBLIC_PRODUCTION_HUBSPOT_HERO_CTA_FORM_ID as string
      hubspotHeroSignUpFormId = process.env.NEXT_PUBLIC_PRODUCTION_HUBSPOT_HERO_SIGNUP_FORM_ID as string
      optimizeContainerId = process.env.NEXT_PUBLIC_OPTIMIZE_CONTAINER_ID as string
      v1GositeDashboard = process.env.NEXT_PUBLIC_PRODUCTION_V1_GOSITE_DASHBOARD_URL as string
      googleSiteVerification = process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION as string
      termlyWebsiteUUID = process.env.NEXT_PUBLIC_PRODUCTION_TERMLY_WEBSITE_UUID as string
      break
  }

  const result = {
    appEnv,
    emulateOldSiteUI,
    strapiDomain,
    hubspotDomain,
    segmentWriteKey,
    googleTagManagerId,
    pageLockPassword,
    intercomAppId,
    googlePlacesApiKey,
    hubspotAccId,
    hubspotHeroCtaFormId,
    hubspotHeroSignUpFormId,
    optimizeContainerId,
    v1GositeDashboard,
    googleSiteVerification,
    termlyWebsiteUUID
  }

  return result
}

export const env = {
  ...getEnvVars()
}
