import React from 'react'
import { List, ListItem, ListItemText, styled, useTheme } from '@gositeinc/ui'
import BeatLoader from 'react-spinners/BeatLoader'

const MessengerWidgetBody = (props: MessengerWidgetBodyProps): JSX.Element => {
  const theme = useTheme()
  const { data } = props

  const getListItemTextProps = (chatItem: IChatItem, msgType: string): any => {
    return {
      padding: theme.spacing(14 / 8, 3),
      borderRadius: theme.spacing(5),
      maxWidth: 308,
      flex: 'none',
      background: msgType === 'incoming' ? '#EDF1F6' : '#C0F06B',
      display: 'flex',
      alignItems: 'center'
    }
  }

  return (
    <Root>
      {data?.map(function (chatItem: IChatItem, key) {
        return (
          <React.Fragment key={key}>
            <ListItem
              className='animate__animated animate__fadeIn animate__repeat-1'
              sx={{
                justifyContent: chatItem.type === 'incoming' ? 'flex-start' : 'flex-end',
                alignItems: chatItem.type === 'incoming' ? 'flex-start' : 'flex-end'
              }}
            >
              <ListItemText
                primary={chatItem.message}
                sx={getListItemTextProps(chatItem, chatItem.type)}
              />
            </ListItem>
            {chatItem.isLoading !== null && chatItem.isLoading && (
              <ListItem
                className='animate__animated animate__fadeIn animate__repeat-1'
              >
                <ListItemText
                  primary={<BeatLoader loading={chatItem.isLoading} size={10} />}
                  sx={{
                    ...getListItemTextProps(chatItem, 'incoming'),
                    '& .MuiTypography-root': {
                      fontSize: 10
                    }
                  }}
                />
              </ListItem>
            )}
          </React.Fragment>
        )
      })}
    </Root>
  )
}

const Root = styled(List)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    fontSize: theme.spacing(1.75)
  }
}))

interface MessengerWidgetBodyProps {
  data: IChatItem[]
}

export interface IChatItem {
  id: number
  message: string
  type: string
  isLoading?: true | false
}

export default MessengerWidgetBody
