import React from 'react'
import {
  Box,
  Card,
  CardContent,
  Text,
  Grid,
  Divider,
  Button,
  cardClasses,
  Stack,
  useMediaQuery,
  useTheme,
  styled,
  Theme
} from '@gositeinc/ui'
import { addDays, format } from 'date-fns'
import isArray from 'lodash/isArray'

import { HeroWidgetModifierStyle } from './hero-widget'
import { InvoiceWidgetPreviewPayload, IHeroInvoiceWidget } from '../types'
import { } from '../utils'

const PreviewInvoice = ({ id, data, previewData, onClose }: InvoiceWidgetProps): JSX.Element => {
  const theme = useTheme()
  const { businessName, contact, email, phone, rowItems } = previewData?.formValues
  const isScreenLargerThanMedium = useMediaQuery(theme.breakpoints.up('md'))
  const totalAmount = isArray(rowItems) && rowItems?.length > 0 && rowItems.map((item) => Number(item.quantity) * Number(item.rate))
    .reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0).toFixed(2)
  const onSubmit = (submitType: string): void => {
    if (onClose != null) {
      if (submitType === 'send') {
        onClose(true, rowItems)
      } else {
        onClose(false, rowItems)
      }
    }
  }

  const renderRow = (rowdata: InvoiceRowItemFormValues, id: number): JSX.Element => {
    return (
      <InvoiceItemsRoot key={id}>
        <Grid container columnSpacing={2}>
          <Grid item xs={9}>
            <Grid sx={{ marginLeft: 2 }} container columnSpacing={2}>
              <Grid container columnSpacing={2}>
                <Grid item xs={12} md={6}>
                  <Text variant='subtitle2' fontWeight={theme.typography.fontWeightBold}>{rowdata.item}</Text>
                </Grid>
                {
                  isScreenLargerThanMedium
                    ? (
                      <>
                        <Grid item xs={6} md={3}>
                          <PreviewText variant='subtitle2' sx={{ textAlign: 'right' }}>{rowdata.rate}</PreviewText>
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <PreviewText variant='subtitle2' sx={{ textAlign: 'right' }}>{rowdata.quantity}</PreviewText>
                        </Grid>
                      </>)
                    : (
                      <Grid item xs={12} md={6} sx={{ marginTop: theme.spacing(-1) }}>
                        <PreviewText variant='subtitle2' sx={{ textAlign: 'left' }}>{`${Number(rowdata?.quantity)} × ${Number(rowdata?.rate)} each`}</PreviewText>
                      </Grid>)
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Text textAlign='right' variant='subtitle2' fontWeight={theme.typography.fontWeightRegular}>${rowdata?.quantity !== undefined && rowdata?.rate !== undefined && ((Number(rowdata.quantity) * Number(rowdata.rate)).toFixed(2))}</Text>
          </Grid>
        </Grid>
      </InvoiceItemsRoot>
    )
  }

  const renderHeading = (): JSX.Element => {
    return (
      <>
        <HeadRoot container columnSpacing={2}>
          <Grid item xs={9}>
            <Grid container columnSpacing={2}>
              {data.invoiceRowItemFields.slice(0, data.invoiceRowItemFields.length - 1).map((item, index) => {
                if (index === 0) {
                  return (
                    <Grid item xs={12} md={6} key={item.name}>
                      <Text variant='subtitle2' color={theme.palette.text.secondary} fontWeight={theme.typography.fontWeightBold}>{item.label}</Text>
                    </Grid>
                  )
                }

                return (
                  <Grid item md={3} key={item.name}>
                    <Text textAlign='right' variant='subtitle2' color={theme.palette.text.secondary} fontWeight={theme.typography.fontWeightBold}>{item?.label}</Text>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Text textAlign='right' variant='subtitle2' color={theme.palette.text.secondary} fontWeight={theme.typography.fontWeightBold}>{data.invoiceRowItemFields[data.invoiceRowItemFields.length - 1].label}</Text>
          </Grid>
          <Divider />
        </HeadRoot>
      </>
    )
  }

  const renderSubTotalRow = (): JSX.Element => {
    return (
      <InvoiceNotItemsRoot>
        <Divider />
        <Grid container columnSpacing={2}>
          <Grid item xs={9}>
            <PreviewText variant='subtitle2' fontWeight={theme.typography.fontWeightRegular}>Subtotal</PreviewText>
          </Grid>
          <Grid item xs={3}>
            <Text textAlign='right' variant='subtitle2' fontWeight={theme.typography.fontWeightRegular}>${totalAmount}</Text>
          </Grid>
        </Grid>
      </InvoiceNotItemsRoot>
    )
  }

  const renderTotalRow = (): JSX.Element => {
    return (
      <InvoiceNotItemsRoot>
        <Divider />
        <Grid container columnSpacing={2}>
          <Grid item xs={9}>
            <Text variant='subtitle2' fontWeight={theme.typography.fontWeightBold}>Total</Text>
          </Grid>
          <Grid item xs={3}>
            <Text textAlign='right' variant='subtitle2' fontWeight={theme.typography.fontWeightBold}>${totalAmount}</Text>
          </Grid>
        </Grid>
      </InvoiceNotItemsRoot>
    )
  }

  return (
    <Root>
      <CardContent id={id}>
        {!isScreenLargerThanMedium
          ? (
            <Box textAlign='center' paddingY={theme.spacing(2)}>
              <Text variant='subtitle3'>{businessName}</Text>
              <PreviewText variant='subtitle2'>Thanks for your interest in our services.</PreviewText>
              <Text variant='h5' paddingTop={theme.spacing(2.375)} paddingBottom={theme.spacing(1.625)}>${totalAmount}</Text>
              <PreviewText variant='subtitle2'>Due on {format(new Date(), 'MMM dd, yyyy')}</PreviewText>
            </Box>)
          : (
            <Text variant='subtitle3'>{businessName}</Text>)}
        <Box sx={{ border: { xs: '1px solid #DBE3EB', md: 0 }, padding: { xs: theme.spacing(2), md: 0 }, borderRadius: { xs: theme.spacing(1), md: 0 } }}>
          {!isScreenLargerThanMedium
            ? (
              <>
                <Box sx={{ textAlign: 'left' }}>
                  <Text paddingY={theme.spacing(0.5)} variant='subtitle2' fontWeight={theme.typography.fontWeightBold}>Date</Text>
                  <PreviewText variant='subtitle2'>{format(new Date(), 'MMM dd, yyyy')}</PreviewText>
                </Box>
                <Box sx={{ paddingY: theme.spacing(3) }}>
                  <Text sx={{ paddingBottom: theme.spacing(1) }} variant='subtitle2' fontWeight={theme.typography.fontWeightBold}>Bill To</Text>
                  <PreviewText variant='subtitle2'>{contact}</PreviewText>
                  <PreviewText variant='subtitle2'>{email}</PreviewText>
                  <PreviewText variant='subtitle2'>{phone}</PreviewText>
                </Box>
              </>)
            : (
              <Box sx={{ paddingY: theme.spacing(5.375) }}>
                <Text sx={{ paddingBottom: theme.spacing(1) }} variant='subtitle2' fontWeight={theme.typography.fontWeightBold}>Bill To</Text>
                <Grid container>
                  <Grid item xs={8}><PreviewText variant='subtitle2'>{contact}</PreviewText></Grid>
                  <Grid sx={{ textAlign: 'right' }} item xs={2}><PreviewText variant='subtitle2'>Date</PreviewText></Grid>
                  <Grid sx={{ textAlign: 'right' }} item xs={2}><PreviewText variant='subtitle2'>{format(new Date(), 'MM/dd/yyyy')} </PreviewText></Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={8}><PreviewText variant='subtitle2'>{email}</PreviewText></Grid>
                  <Grid sx={{ textAlign: 'right' }} item xs={2}><PreviewText variant='subtitle2'>Due</PreviewText></Grid>
                  <Grid sx={{ textAlign: 'right' }} item xs={2}><PreviewText variant='subtitle2'>{format(addDays(new Date(), 1), 'MM/dd/yyyy')}</PreviewText></Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={8}><PreviewText variant='subtitle2'>{phone}</PreviewText></Grid>
                  <Grid sx={{ textAlign: 'right' }} item xs={2}><PreviewText variant='subtitle2'>invoice #</PreviewText></Grid>
                  <Grid sx={{ textAlign: 'right' }} item xs={2}><PreviewText variant='subtitle2'>256013</PreviewText></Grid>
                </Grid>
              </Box>)}
          {isScreenLargerThanMedium && renderHeading()}
          <Divider />
          {isArray(rowItems) && rowItems?.length > 0 && rowItems.map((item, i) => {
            return renderRow(item, i)
          }
          )}
          {renderSubTotalRow()}
          {renderTotalRow()}
        </Box>
      </CardContent>
      <InvoicePreviewButton>
        <Stack flexDirection='row' columnGap={2} mt={2}>
          <Button
            variant='contained'
            fullWidth
            onClick={() => onSubmit('send')}
          >
            Send
          </Button>
          <Button
            variant='outlined'
            fullWidth
            onClick={() => onSubmit('edit')}
          >
            Edit
          </Button>
        </Stack>
      </InvoicePreviewButton>
    </Root>
  )
}

const Root: React.ElementType = styled(Card)(({ theme }: { theme: Theme }) => ({
  [`&.${cardClasses.root}`]: {
    paddingBottom: 60,
    width: theme.spacing(45),
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(80)
    }
  },
  '& .MuiButton-root': {
    top: theme.spacing(7)
  },
  '.MuiGrid-root': {
    width: '100%',
    marginLeft: 0,
    paddingLeft: 0,
    paddingRight: 0

  },
  '.MuiGrid-item': {
    marginLeft: 0
  },
  '.MuiGrid-root>.MuiGrid-item': {
    borderRadius: 0,
    paddingLeft: 0
  },
  '.MuiCard-root': {
    borderRadius: 0,
    boxShadow: 0

  },
  '.MuiPaper-root': {
    border: 0,
    boxShadow: 0
  }
}))

const InvoiceItemsRoot: React.ElementType = styled(Box)(({ theme }: { theme: Theme }) => ({
  '& .MuiTypography-root': {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
  }
}))

const InvoiceNotItemsRoot: React.ElementType = styled(Box)(({ theme }: { theme: Theme }) => ({
  '& .MuiTypography-root': {
    marginTop: theme.spacing(1.3),
    marginBottom: theme.spacing(1.3)
  }
}))

const HeadRoot: React.ElementType = styled(Grid)(({ theme }: { theme: Theme }) => ({
  paddingBottom: theme.spacing(1.3)
}))

const PreviewText: React.ElementType = styled(Text)(({ theme }: { theme: Theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: theme.spacing(3.15),
  color: theme.palette.text.secondary
}))

const InvoicePreviewButton: React.ElementType = styled(Box)(({ theme }: { theme: Theme }) => ({
  padding: theme.spacing(2)
}))
interface InvoiceWidgetProps {
  id: string
  data: IHeroInvoiceWidget
  previewData: InvoiceWidgetPreviewPayload
  onClose?: (closeCheck: boolean, rowItemsList: InvoiceRowItemFormValues[] | string) => void
}

interface InvoiceRowItemFormValues {
  item?: string
  quantity?: number | null
  rate?: number | null
}

export const invoiceWidgetModifierStyle: HeroWidgetModifierStyle = {
  showFade: true
}

export default PreviewInvoice
