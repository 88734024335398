export const getUtmParams = (): string => {
  let utmParam = ''
  if (typeof window !== 'undefined') {
    const url = new URL(window.location.href)
    const searchParams = new URLSearchParams(url.search)
    let utmParams = ''
    if (
      searchParams.get('utm_content') != null ||
      searchParams.get('utm_medium') != null ||
      searchParams.get('utm_source') != null ||
      searchParams.get('utm_campaign') != null ||
      searchParams.get('utm_term') != null
    ) {
      if (searchParams.get('utm_content') != null) {
        utmParams += `utm_content=${searchParams.get('utm_content') ?? ''}`
      }
      if (searchParams.get('utm_medium') != null) {
        utmParams += utmParams.length > 1 ? '&' : ''
        utmParams += `utm_medium=${searchParams.get('utm_medium') ?? ''}`
      }
      if (searchParams.get('utm_campaign') != null) {
        utmParams += utmParams.length > 1 ? '&' : ''
        utmParams += `utm_campaign=${searchParams.get('utm_campaign') ?? ''}`
      }
      if (searchParams.get('utm_source') != null) {
        utmParams += utmParams.length > 1 ? '&' : ''
        utmParams += `utm_source=${searchParams.get('utm_source') ?? ''}`
      }
      if (searchParams.get('utm_term') != null) {
        utmParams += utmParams.length > 1 ? '&' : ''
        utmParams += `utm_term=${searchParams.get('utm_term') ?? ''}`
      }
    }
    utmParam = utmParams
  }
  return utmParam
}
