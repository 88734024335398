import React from 'react'

import { BackgroundMedia, Box, Container, Text, Grid, styled, useTheme } from '@gositeinc/ui'
import { ITestimonial2 } from '../types'
import { Rating } from '@mui/material'
import hexToRgba from 'hex-to-rgba'
import { testimonial2Classes } from '../classes'

const Testimonial2 = ({ data }: Testimonial2Props): JSX.Element => {
  const theme = useTheme()
  const { content, backgroundMedia, styles } = data

  return (
    <>
      <Wrapper sx={styles} className={testimonial2Classes.root}>
        <Container maxWidth='lg'>
          <TestimonialContainer container spacing={0} paddingTop={10}>
            <Grid item>
              <TextContainer className={testimonial2Classes.content}>
                {content.stars != null && (
                  <Rating name='read-only' value={content.stars} readOnly />
                )}
                <Text
                  rich
                  className={testimonial2Classes.quote}
                  color={theme.palette.common.white}
                  sx={{ py: 2.6, textAlign: 'left', fontSize: '30px', fontWeight: '500' }}
                >
                  {content.quote}
                </Text>
                {content.author != null && (
                  <Text
                    rich
                    className={testimonial2Classes.author}
                    color={theme.palette.common.white}
                    sx={{ mt: 2.5, pb: 5, textAlign: 'left' }}
                  >
                    {content.author}
                  </Text>
                )}
                {content.company != null && (
                  <Text
                    rich
                    className={testimonial2Classes.company}
                    color={hexToRgba(theme.palette.common.white, 0.15)}
                    sx={{ textAlign: 'left' }}
                  >
                    {content.company}
                  </Text>
                )}
              </TextContainer>
            </Grid>
          </TestimonialContainer>
        </Container>
        <BackgroundMedia
          media={backgroundMedia}
          objectFit='cover'
          objectPosition='absolute'
        />
      </Wrapper>

    </>
  )
}
interface Testimonial2Props {
  data: ITestimonial2
}

const Wrapper = styled(Box)(({ theme }) => ({
  [`.${testimonial2Classes.quote} h2`]: {
    fontSize: theme.typography.pxToRem(30),
    fontWeight: theme.typography.fontWeightRegular
  },
  [`.${testimonial2Classes.author} p`]: {
    ...theme.typography.subtitle2,
    fontWeight: theme.typography.fontWeightBold
  },
  [`.${testimonial2Classes.company} p`]: {
    ...theme.typography.overline
  },
  marginBottom: theme.spacing(6),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative'
}))

const TestimonialContainer = styled(Grid)(({ theme }) => ({
  paddingBottom: theme.spacing(10.2),
  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column-reverse',
    paddingBottom: theme.spacing(6.25)
  }
}))

const TextContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1)
  }
}))

export type { Testimonial2Props }
export default Testimonial2
