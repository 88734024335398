import React from 'react'
// import { gsap } from 'gsap'

import { Box, GridProps, styled, SxProps, Theme } from '@gositeinc/ui'
import BookingWidget, { bookingHeroWidgetModifierStyle } from './booking-widget'
import MessengerWidget, { messengerWidgetModifierStyle } from './messenger-widget'
import ReviewWidget, { reviewHeroWidgetModifierStyle } from './review-widget'
import { HeroMediaWidget } from './hero-media-widget'
import { HeroFormWidget } from './hero-form-widget'
import PaymentWidget, { paymentWidgetModifierStyle } from './payment-widget'
import ContactHubWidget, { contactHubHeroWidgetModifierStyle } from './contact-hub-widget'
import InvoiceWidget, { invoiceWidgetModifierStyle } from './invoice-widget'
import EngagementWidget, { engagementWidgetModifierStyle } from './engagement-widget'
import PlacementWidget, { placementWidgetModifierStyle } from './placement-widget'
import { sitesHeroWidgetModifierStyle, SitesWidget } from './sites-widget'
import { HeroWidgetInteractionResultPayload, IHeroFeatureContent, IHeroContent, IHeroWidget } from '../types'
import { Feature } from '../constants'

// const rootAnimationTimeline: gsap.core.Timeline = gsap.timeline({ delay: 4 })

const HeroWidget = ({ contentData, widgetData, widgetType, onSubmit }: HeroWidgetProps): JSX.Element => {
  const WidgetComponent: React.ElementType = widgetComponents[widgetType]

  const _onSubmit = (payload: HeroWidgetInteractionResultPayload): void => {
    if (onSubmit !== undefined) {
      onSubmit(payload)
    }
  }

  // useEffect(() => {
  //   rootAnimationTimeline = pulsate('#heroWidgetContainer')
  //   return () => {
  //     rootAnimationTimeline?.clear()
  //   }
  // }, [])

  return (
    <Root
      id='heroWidgetContainer'
    // onMouseOver={() => {
    //   rootAnimationTimeline?.restart()
    //   rootAnimationTimeline?.pause()
    // }}
    // onMouseLeave={() => {
    //   setTimeout(() => {
    //     rootAnimationTimeline?.resume()
    //   }, 4000)
    // }}
    >
      <WidgetComponent
        contentData={contentData}
        data={widgetData}
        onDone={_onSubmit}
      />
    </Root>
  )
}

// const pulsate = (id: string): gsap.core.Timeline => {
//   const tl = gsap.timeline({ delay: 3, repeat: -1, repeatDelay: 3, yoyo: true })
//   tl.to(id, {
//     keyframes: [
//       { scale: 1.05, duration: 0.63, yoyoEase: 'power2.in' },
//       { scale: 1, duration: 0.88, yoyoEase: 'power1.in' },
//       { scale: 1.05, duration: 0.63, yoyoEase: 'power2.in', delay: 0.2 },
//       { scale: 1, duration: 0.88, yoyoEase: 'power1.in' }
//     ]
//   })

//   return tl
// }

const Root = styled(Box)(() => ({

}))
interface HeroWidgetProps {
  contentData: IHeroFeatureContent | IHeroContent | null
  widgetData: IHeroWidget
  widgetType: Feature
  onSubmit?: (payload: HeroWidgetInteractionResultPayload) => void
}

export interface HeroWidgetModifierStyle {
  showFade?: boolean
  bodyStyleCreator?: (theme: Theme) => SxProps
  fadeStyleCreator?: (theme: Theme) => SxProps
  heroCtaStyleCreator?: (theme: Theme) => SxProps
  heroWidgetGridPropsCreator?: (theme: Theme) => GridProps
  heroBodyGridPropsCreator?: (theme: Theme) => GridProps
}

export const heroWidgetModifierStyles: { [key in Feature]?: HeroWidgetModifierStyle } = {
  booking: bookingHeroWidgetModifierStyle,
  review: reviewHeroWidgetModifierStyle,
  messenger: messengerWidgetModifierStyle,
  payment: paymentWidgetModifierStyle,
  'contact-hub': contactHubHeroWidgetModifierStyle,
  invoice: invoiceWidgetModifierStyle,
  engagement: engagementWidgetModifierStyle,
  placement: placementWidgetModifierStyle,
  sites: sitesHeroWidgetModifierStyle

}

const featureWidgetComponents = {
  booking: BookingWidget,
  'contact-hub': ContactHubWidget,
  'email-campaign': BookingWidget,
  invoice: InvoiceWidget,
  messenger: MessengerWidget,
  payment: PaymentWidget,
  placement: PlacementWidget,
  review: ReviewWidget,
  sites: SitesWidget,
  engagement: EngagementWidget
}

const widgetComponents = {
  ...featureWidgetComponents,
  'hero-media': HeroMediaWidget,
  'hero-form': HeroFormWidget
}

export default HeroWidget
