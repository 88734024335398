import React, { useState, useCallback } from 'react'
import {
  Alert,
  Box,
  Button,
  Container,
  Chip,
  Divider,
  Grid,
  Link,
  Media,
  Stack,
  styled,
  Text,
  TextField,
  TextFieldSubmitCombo,
  useMediaQuery,
  useTheme
} from '@gositeinc/ui'
import validator from 'validator'
import { BsPhone } from 'react-icons/bs'

import { IDownloadApp } from 'src/types'
import { formatPhoneNumber } from '../utils'
import * as Assets from '../assets'

import { sendSMS } from '../utils/api'
import { downloadMobileAppClasses } from '../classes'
import { ANALYTICS_CONSTANTS } from 'src/analytics-constants'
import { useAnalytics } from 'src/providers'

const DownloadMobileApp = ({ data, analytics }: DownloadMobileAppProps): JSX.Element => {
  const {
    label,
    title,
    description,
    downloadAppTheme,
    media,
    agreementText,
    inputPlaceholderText,
    submitButtonText,
    appDownloadLinks,
    successMessage,
    failureMessage,
    showDivider,
    styles
  } = data
  const theme = useTheme()
  const { analytics: _analytics } = useAnalytics()
  const [phoneNumber, setPhoneNumber] = useState<number | '' | null>()
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('')
  const [responseStatus, setResponseStatus] = useState<Number>()
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleChange = useCallback((event) => {
    const formatMobileNumber = formatPhoneNumber(event.target.value)
    setFormattedPhoneNumber(formatMobileNumber)
    setPhoneNumber(Number(formatMobileNumber.replace(/[^0-9]/g, '')))
  }, [phoneNumber])

  const isPhoneNumberValid = (): boolean => {
    return formattedPhoneNumber !== '' && validator.isMobilePhone(formattedPhoneNumber)
  }

  const handleBlur = (): void => {
    _analytics?.track('mkt_enter_phone_number', {
      sectionTitle: title,
      actionTitle: submitButtonText
    })
  }

  const getApp = async (e: React.SyntheticEvent): Promise<string | any> => {
    e.preventDefault()
    const payload = {
      contact_phone: phoneNumber != null ? phoneNumber.toString() : '',
      message: data.smsContent
    }
    _analytics?.track('mkt_submit_phone_number', {
      phoneNumber: phoneNumber,
      sectionTitle: title,
      actionTitle: submitButtonText
    })
    const response = await sendSMS(payload)
    setResponseStatus(response.status)
  }

  const getComponent = (responseStatus): JSX.Element => {
    if (responseStatus === 200) return <ThankyouText>{successMessage}</ThankyouText>
    else return <Alert severity='error' sx={{ marginBottom: '1.6rem', width: 'fit-content' }}>{failureMessage}</Alert>
  }

  const getRootProps = (): object => {
    if (downloadAppTheme === 'inverted') {
      return {
        sx: {
          background: theme.palette.common.black,
          color: theme.palette.common.white,
          ...styles
        }
      }
    }

    return {
      sx: {
        background: theme.palette.common.white,
        color: theme.palette.common.black,
        ...styles
      }
    }
  }

  return (
    <Root className={downloadMobileAppClasses.root} {...getRootProps()}>
      <Container>
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item md={7}>
            <Box className='GoSiteDownloadMobileApp-media'>
              <Media media={media} />
            </Box>
          </Grid>
          <Grid item md={5}>
            <Box className='GoSiteDownloadMobileApp-content'>
              {label != null && <Chip label={label} />}
              <Text
                rich
                className={downloadMobileAppClasses.title}
                sx={{ pt: 1.4 }}
              >
                {title}
              </Text>
              <Text
                variant='body1'
                sx={{
                  py: 2,
                  color: downloadAppTheme === 'inverted'
                    ? theme.palette.common.white
                    : theme.palette.text.secondary
                }}
              >
                {description}
              </Text>

              {responseStatus !== undefined
                ? getComponent(responseStatus)
                : (
                  <PhoneWrapper>
                    {downloadAppTheme === 'inverted'
                      ? (
                        <TextFieldBox>
                          <form
                            id='downloadMobileAppForm'
                            onSubmit={async (e) => await getApp(e)}
                          >
                            <TextFieldSubmitCombo
                              placeholder={inputPlaceholderText}
                              onChange={(event) => handleChange(event)}
                              onBlur={() => handleBlur()}
                              value={formattedPhoneNumber}
                              // onSubmit={async () => await getApp()}
                              startAdornment={(
                                <Assets.SmartPhone
                                  pathColor={theme.palette.primary.main}
                                  sx={{ fontSize: 24 }}
                                />
                              )}
                              isValid={isPhoneNumberValid()}
                            />
                          </form>
                        </TextFieldBox>)
                      : (
                        <PhoneNumberField
                          placeholder={inputPlaceholderText}
                          value={formattedPhoneNumber}
                          InputProps={{
                            endAdornment: <BsPhone color='#d1d7dd' fontSize='25px' />
                          }}
                          onChange={(event) => handleChange(event)}
                          onBlur={() => handleBlur()}
                          variant={'outlined' as any}
                        />)}
                    {downloadAppTheme === 'normal' && (
                      <GetAppButton onClick={async (e) => await getApp(e)}>{submitButtonText}</GetAppButton>
                    )}
                  </PhoneWrapper>)}
              <Stack
                className='GositeDownloadMobileApp-storeLinks'
                direction='row'
                gap={2}
              >
                {appDownloadLinks.map(appDownloadLink => (
                  <Link
                    key={appDownloadLink.id}
                    link={appDownloadLink}
                    data-analytics={appDownloadLink?.url.includes('apple') ? analytics?.appDownloadLinkApple : analytics?.appDownloadLinkGoogle}
                    data-analytics-section-title={title}
                    data-analytics-action-title={appDownloadLink?.url.includes('apple') ? 'apple' : 'google'}
                    component={Button}
                    padding={theme.spacing(0, 1.75, 0, 0)}
                  >
                    <Media media={appDownloadLink.media} />
                  </Link>
                ))}
              </Stack>
              <Text
                className={downloadMobileAppClasses.agreementText}
                rich
                sx={{
                  pt: 3.75,
                  color: '#ABB4B9',
                  display: 'block',
                  a: { color: 'inherit' }
                }}
              >
                {agreementText}
              </Text>
            </Box>
          </Grid>
        </Grid>
        {!isSmallerScreen && showDivider && <Divider variant='middle' />}
      </Container>
    </Root>
  )
}

interface DownloadMobileAppProps {
  data: IDownloadApp
  analytics: typeof ANALYTICS_CONSTANTS['sections.download-mobile-app']
}

const Root = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(10),
  [`.${downloadMobileAppClasses.title} h2`]: {
    ...theme.typography.h3
  },
  [`.${downloadMobileAppClasses.agreementText} p`]: {
    ...theme.typography.caption2
  },
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6)
  }
}))

const GetAppButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.common.white,
  marginLeft: theme.spacing(2.25),
  padding: theme.spacing(1.6, 2.8),
  paddingTop: '12px !important',
  paddingBottom: '12px !important',
  '&:hover': {
    background: theme.palette.primary.main
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: theme.spacing(0),
    marginTop: theme.spacing(2.25)
  }
}))

const TextFieldBox = styled(Box)(({ theme }) => ({
  width: 400,
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
}))
const PhoneWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2.75, 0),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'normal'
  }
}))

const PhoneNumberField = styled(TextField)(({ theme }) => ({
  width: '50%',
  '& .MuiOutlinedInput-root': {

    '& fieldset': {
      borderColor: '#e3eaf0'
    },
    '&:hover fieldset': {
      borderColor: '#e3eaf0'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#e3eaf0'
    }
  },
  '& .MuiOutlinedInput-input': {
    padding: theme.spacing(1.6, 1.75)
  },
  input: {
    '&::placeholder': {
      fontSize: theme.spacing(1.6)
    }
  },
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }

}))

const ThankyouText = styled('p')(({ theme }) => ({
  fontSize: theme.spacing(2.25),
  color: theme.palette.text.secondary
}))

export type { DownloadMobileAppProps }
export default DownloadMobileApp
