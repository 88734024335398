import React, { useContext, useEffect, useReducer } from 'react'
import { Analytics, AnalyticsBrowser } from '@segment/analytics-next'
import { getPlainTextFromRichText } from '@gositeinc/ui'

import { getSectionId } from 'src/domain/sections'
import { ISection } from 'src/types'

const initialState = {
  analytics: null
}

const Context = React.createContext<UseAnalytics>({
  ...initialState
})

export const useAnalytics = (): UseAnalytics => {
  return useContext(Context)
}

export const AnalyticsProvider = ({ writeKey, children }: AnalyticsProviderProps): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const _track = async (eventName: string, properties?: AnalyticsTrackProperties): Promise<void> => {
    const payload: AnalyticsTrackProperties = {
      ...properties,
      sectionId: properties?.sectionData != null
        ? getSectionId(properties?.sectionData)
        : null
    }

    if (properties?.sectionTitle != null) {
      payload.sectionTitle = getPlainTextFromRichText(properties?.sectionTitle)
    }

    if (properties?.actionTitle != null) {
      payload.actionTitle = properties?.actionTitle
    }

    if (payload.sectionData != null) {
      delete payload.sectionData
    }

    state.analytics?.track(eventName, payload)
  }

  useEffect(() => {
    const loadAnalytics = async (): Promise<void> => {
      const [response] = await AnalyticsBrowser.load({ writeKey })
      dispatch({ type: 'setAnalytics', payload: response })
    }
    loadAnalytics().catch(() => { })
  }, [writeKey])

  const value = {
    ...state,
    analytics: {
      ...state.analytics,
      track: _track
    }
  }

  return (
    <Context.Provider
      // @ts-expect-error
      value={value}
    >
      {children}
    </Context.Provider>
  )
}

const reducer = (state: ReducerState, action: ReducerAction): ReducerState => {
  switch (action.type) {
    case 'setAnalytics':
      return {
        ...state,
        analytics: action.payload
      }
    default:
      throw new Error()
  }
}

interface ReducerState {
  analytics: Analytics | null
}

type ReducerAction =
  | { type: 'setAnalytics', payload: Analytics }

export interface AnalyticsTrackProperties {
  sectionData?: ISection
  sectionId: string | null
  sectionTitle?: string
  actionTitle?: string
}

export interface AnalyticsProviderProps {
  writeKey: string
  children: React.ReactNode
}

export interface UseAnalytics extends ReducerState {
  analytics: Analytics | null
}
