import fs from 'fs/promises'
import path from 'path'

import { IPage } from 'src/types'

export const pagesApiResponseCache = {
  get: async (slug: string, locale: string): Promise<IPage | null | undefined> => {
    const data = await fs.readFile(path.join(process.cwd(), 'pages.db'))
    const pages: IPage[] = JSON.parse(data as unknown as string)

    return pages.find(page => page.slug === slug && page.locale === locale)
  },
  set: async (pages: IPage[]) => {
    return await fs.writeFile(
      path.join(process.cwd(), 'pages.db'),
      JSON.stringify(pages)
    )
  }
}
