import React from 'react'
import { Button, Text, Divider, Box, Media, styled, useTheme, SxProps } from '@gositeinc/ui'

import EmailConfirmation from './email-confirmation'
import { IHeroPlacementThirdPartyService, IHeroPlacementWidget, IPlacementWidgetPayload } from 'src/types'

const PlacementConfirmationWidget = ({ widgetData, widgetPayload }: PlacementConfirmationWidgetProps): JSX.Element => {
  const theme = useTheme()
  const {
    placementResult
  } = widgetData

  const {
    senderAvatarColor,
    senderAvatarIcon,
    senderName,
    receiverName,
    emailTitle
  } = widgetPayload

  const { resultIcon, thirdPartyServices, manageListingText } = placementResult
  const _senderAvatarColor = (senderAvatarColor === undefined || senderAvatarColor === null || senderAvatarColor === '')
    ? theme.palette.primary.main
    : senderAvatarColor

  return (
    <EmailConfirmation
      senderAvatarColor={_senderAvatarColor}
      senderAvatarIcon={senderAvatarIcon}
      senderName={senderName}
      receiverName={receiverName}
      title={emailTitle}
      body={
        <EmailBodyContent>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Media
              media={resultIcon}
              height={24}
              width={24}
            />
          </Box>
          <Divider sx={{ marginTop: '12px' }} />
          {thirdPartyServices.map((service, idx) => <ThirdPartyService key={idx} service={service} />)}
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: '28px' }}>
            <Button size='small' color='primary' variant='contained' sx={{ fontSize: '9.71px' }}>{manageListingText}</Button>
          </Box>
        </EmailBodyContent>
      }
    />
  )
}

const EmailBodyContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1)
}))

const ThirdPartyService = ({ service, sx }: { service: IHeroPlacementThirdPartyService, sx?: SxProps }): JSX.Element => {
  const { icon, name, successIcon, status } = service

  const ServiceText = styled(Text)(({ theme }) => ({
    fontSize: '10.47px',
    maxWidth: '70px',
    overflow: 'hidden',
    overflowWrap: 'break-word',
    textAlign: 'center'
  }))

  const StatusText = styled(Text)(({ theme }) => ({
    fontSize: '10.47px',
    maxWidth: '21px',
    overflow: 'hidden',
    overflowWrap: 'break-word',
    textAlign: 'center'
  }))

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '18px 8px 0 8px', ...sx }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Media
          media={icon}
          width={16}
          height={16}
        />
        <ServiceText variant='body1' sx={{ marginLeft: '12.36px' }}>{name}</ServiceText>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Media
          media={successIcon}
          width={16}
          height={16}
        />
        <StatusText variant='body1' sx={{ marginLeft: '7.48px' }}>{status}</StatusText>
      </Box>
    </Box>
  )
}

interface PlacementConfirmationWidgetProps {
  widgetData: IHeroPlacementWidget
  widgetPayload: IPlacementWidgetPayload
}

export default PlacementConfirmationWidget
