import React from 'react'
import {
  Box,
  Form,
  UseFormStateChange,
  styled
} from '@gositeinc/ui'

import { IHeroContent, IHeroFeatureContent, IHeroFormWidget } from '../types'
import { useAnalytics } from '../providers'

export const HeroFormWidget = ({ data, contentData }: HeroFormWidgetProps): JSX.Element => {
  const { analytics } = useAnalytics()
  const onChange = (formState: UseFormStateChange): void => {
    const { formValues } = formState
    if (formValues != null) {
      if (Object.values(formValues).every(field => field != null && field !== '')) {
        analytics?.track('mkt_hero_form_submitted', {
          sectionData: data?.form,
          sectionTitle: contentData?.title
        })
      }
    }
  }

  return (
    <Root className='GoSiteHero-form'>
      <Form
        form={data?.form}
        onChange={onChange}
      />
    </Root>
  )
}

const Root = styled(Box)(({ theme }) => ({
}))

export interface HeroFormWidgetProps {
  data: IHeroFormWidget
  contentData: IHeroContent | IHeroFeatureContent | null
}
