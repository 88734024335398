import { useEffect, useState } from 'react'
import { Box, SitesWidget as SitesWidgetComponent, styled, Theme } from '@gositeinc/ui'

import { IHeroSitesWidget } from '../types'
import { HeroWidgetModifierStyle } from './hero-widget'

export const SitesWidget = ({ data, onDone }: SitesWidgetProps): JSX.Element => {
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsReady(true)
    }, 100)
  }, [])

  if (!isReady) {
    return <></>
  }

  return (
    <Root sx={data.styles}>
      <SitesWidgetComponent {...{ data, onDone }} />
    </Root>
  )
}

const Root = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: 950,
    height: 650,
    overflowY: 'scroll'
  },
  [theme.breakpoints.down('md')]: {
    width: 320,
    height: 650,
    overflowY: 'scroll'
  },
  '& .GoSiteSitesWidget-toolbar': {
    marginLeft: 50,
    marginRight: 50
  }
}))

export const sitesHeroWidgetModifierStyle: HeroWidgetModifierStyle = {
  showFade: false,
  heroWidgetGridPropsCreator: (theme: Theme) => ({
    sx: {
      [theme.breakpoints.down('md')]: {
        paddingRight: 0
      }
    }
  }),
  heroBodyGridPropsCreator: (theme: Theme) => ({
    sx: {
      [theme.breakpoints.down('md')]: {
        paddingLeft: 0
      }
    }
  })
}

interface SitesWidgetProps {
  data: IHeroSitesWidget
  onDone: (payload: object) => void
}
