export const getLinkWithSearchParams = (url: string): string => {
  if (url == null || url === '') {
    return url
  }

  if (typeof window !== 'undefined') {
    let separator = url.includes('?') ? '&' : '?'
    if (window.location.search === '') {
      separator = ''
    }
    return `${url}${separator}${window.location.search.slice(1, window.location.search.length)}`
  }
  return url
}
