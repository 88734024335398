import React, { useRef, useState, useCallback, useEffect } from 'react'
import {
  Button,
  Box,
  Card,
  CardContent,
  Divider,
  Form,
  IconButton,
  InputBase,
  InputAdornment,
  Link,
  List,
  ListProps,
  ListItem,
  ListItemText,
  Stack,
  StackProps,
  UseFormStateChange,
  cardClasses,
  cardContentClasses,
  colors,
  formControlClasses,
  formHelperTextClasses,
  formLabelClasses,
  inputAdornmentClasses,
  inputBaseClasses,
  listItemClasses,
  paperClasses,
  typographyClasses,
  Text,
  useTheme,
  styled
} from '@gositeinc/ui'
import uniq from 'lodash/uniq'

import PreviewInvoice from './preview-invoice'
import { HeroWidgetModifierStyle } from './hero-widget'
import { IHeroInvoiceRow, IHeroInvoiceWidget } from '../types'
import { usePdf } from '../utils'
import * as Constants from '../constants'
import * as Assets from '../assets'

const InvoiceWidget = ({ data, onDone }: InvoiceWidgetProps): JSX.Element => {
  const theme = useTheme()
  const { downloadPdf } = usePdf()
  // const isScreenLargerThanMedium = useMediaQuery(theme.breakpoints.up('md'))
  const [rowItems, setRowItems] = useState<InvoiceRowItemFormValues[]>([])
  const [showPreviewInvoice, setShowPreviewInvoice] = useState<boolean>(false)
  const [invoicePayload, setInvoicePayload] = useState({ formValues: {} })
  const formValuesRef = useRef<object | null>()
  const rootRef = useRef<HTMLDivElement>(null)
  const invoiceItemsHeadingAmountColumnRef = useRef<HTMLDivElement>(null)
  const totalAmount = rowItems.map(item => Number(item.quantity) * Number(item.rate))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0).toFixed(2) ?? 0.00

  const canSubmitForm = formValuesRef.current != null &&
    Object.values(formValuesRef.current).every(field => field != null && field !== '') &&
    rowItems.length > 0

  const getRowItemInputProps = (item: IHeroInvoiceRow, index: number, rowData: InvoiceRowItemFormValues): object => {
    const sx = {}
    const startAdornment = (
      <InputAdornment
        position='start'
      >
        <Text variant='inherit' component='span'>{Constants.CURRENCY_SYMBOL}</Text>
      </InputAdornment>
    )

    if (item.name === 'item') {
      return {
        sx: {
          ...sx,
          [`& .${inputBaseClasses.input}`]: {
            fontWeight: theme.typography.fontWeightBold
          }
        }
      }
    }

    if (item.name === 'rate') {
      return {
        sx,
        startAdornment
      }
    }

    if (item.name === 'amount') {
      return {
        sx,
        disabled: true,
        startAdornment,
        value: rowData?.quantity != null && rowData?.rate != null
          ? (rowData.quantity * rowData.rate).toFixed(2)
          : 0.00
      }
    }

    return sx
  }

  const onChange = ({ control }: UseFormStateChange): void => {
    formValuesRef.current = control._formValues
  }

  const addRowItemsValues = (item: IHeroInvoiceRow, value: string | number, index: number): void => {
    const _value = item.type === 'number' && Number(value) < 0 ? 0 : value

    if (_value != null) {
      const newBlockArray = [...rowItems]
      newBlockArray[index][item.name] = _value
      setRowItems([...newBlockArray])
    }
  }

  const addItem = (): void => {
    const newBlockArray = [...rowItems]
    const itemObj = {
      item: undefined,
      quantity: null,
      rate: null
    }
    newBlockArray.push(itemObj)
    setRowItems([...newBlockArray])
  }

  const onSubmit = (submitType: string): void => {
    if (submitType === 'send') {
      onDone({
        formValues: {
          ...formValuesRef.current,
          rowItems
        }
      })
    } else {
      setInvoicePayload({
        formValues: {
          ...formValuesRef.current,
          rowItems
        }
      })
      setShowPreviewInvoice(!showPreviewInvoice)
    }
  }

  const closePreview = useCallback((closeCheck: boolean, rowItemsList: InvoiceRowItemFormValues[] | string): void => {
    if (closeCheck) {
      onDone({
        formValues: {
          ...formValuesRef.current,
          rowItems: rowItemsList
        }
      })
    } else {
      setTimeout(() => {
        rearrangeFormElements(rootRef)
      }, 1)

      const formInputIndices: number[] = []
      const formValueArray = formValuesRef.current != null && Object.values(formValuesRef.current)
      data.form.sections.forEach((item, index) => {
        if (item != null && item?.__component === 'forms.form-input') {
          formInputIndices.push(index)
        }
      })

      formInputIndices.forEach((formInputIndex, index) => {
        data.form.sections[formInputIndex].defaultValue = formValueArray[index]
      })
      setShowPreviewInvoice(false)
    }
  }, [data.form])

  const downloadInvoice = (): void => {
    setInvoicePayload({
      formValues: {
        ...formValuesRef.current,
        rowItems
      }
    })
    setTimeout(() => {
      downloadPdf(document.getElementById('invoice'), 'invoice')
    }, 1000)
  }

  const renderHeading = (): JSX.Element => {
    return (
      <ListItem disablePadding>
        <InvoiceItemRow>
          {data.invoiceRowItemFields.map((item, index) => {
            return (
              <Box
                key={item.name}
                ref={item.name === 'amount' ? invoiceItemsHeadingAmountColumnRef : null}
                flex={index === 0 ? 2 : 1}
              >
                <Text
                  variant='inherit'
                  height={theme.spacing(3)}
                  color={theme.palette.text.secondary}
                  fontWeight={theme.typography.fontWeightBold}
                // width={item.name === 'amount' ? INVOICE_ITEM_ROW_AMOUNT_COLUMN_WIDTH : 'auto'}
                >
                  {item?.label}
                </Text>
              </Box>
            )
          })}
        </InvoiceItemRow>
      </ListItem>
    )
  }

  const renderRepeatableRow = (rowData: InvoiceRowItemFormValues, index: number): JSX.Element => {
    const renderInput = (item: IHeroInvoiceRow, _index?: number): JSX.Element => (
      <InvoiceItemInput
        value={rowData[item.name]}
        name={item.name}
        type={item.type}
        onChange={(e) => { addRowItemsValues(item, e.target.value, index) }}
        fullWidth
        {...getRowItemInputProps(item, _index ?? 0, rowData)}
      />
    )

    // if (isScreenLargerThanMedium) {
    return (
      <React.Fragment key={index}>
        <ListItem disablePadding>
          <InvoiceItemRow>
            {data.invoiceRowItemFields.map((item: IHeroInvoiceRow, index: number) => (
              <Box
                key={item.name}
                sx={{ flex: index === 0 ? 2 : 1 }}
              >
                {renderInput(item, index)}
              </Box>
            ))}
          </InvoiceItemRow>
        </ListItem>
        <Divider />
      </React.Fragment>
    )
    // }

    // return (
    //   <React.Fragment key={id}>
    //     <ListItem
    //       secondaryAction={renderInput(data.invoiceRowItemFields[data.invoiceRowItemFields.length - 1])}
    //     >
    //       <ListItemText
    //         disableTypography
    //         primary={renderInput(data.invoiceRowItemFields[0], 0)}
    //         secondary={
    //           data
    //             .invoiceRowItemFields
    //             .slice(1, data.invoiceRowItemFields.length - 1)
    //             .map((item: IHeroInvoiceRow, index: number) => (
    //               <Stack direction='row' key={item.name}>
    //                 {renderInput(item, index)}
    //               </Stack>
    //             ))
    //         }
    //       />

    //     </ListItem>
    //     <Divider />
    //   </React.Fragment>
    // )
  }

  const renderNonRepeatableRow = ({
    value,
    TextComponent
  }: {
    value?: string
    TextComponent: JSX.Element
  }): JSX.Element => {
    return (
      <ListItem
        secondaryAction={value != null && (
          <Text
            variant='inherit'
            component='div'
            width={invoiceItemsHeadingAmountColumnRef.current?.offsetWidth}
            fontWeight={theme.typography.fontWeightBold}
          >
            {Constants.CURRENCY_SYMBOL}{Number(value).toFixed(2)}
          </Text>
        )}
      >
        <ListItemText
          primary={TextComponent}
          disableTypography
          sx={{ margin: 0 }}
        />
      </ListItem>
    )
  }

  useEffect(() => {
    rearrangeFormElements(rootRef)
  }, [closePreview])

  const getRowItemFormValues = (name: string, value: string): InvoiceRowItemFormValues => {
    switch (name) {
      case 'item':
        return {
          item: value,
          rate: null,
          quantity: null
        }
      case 'rate':
        return {
          item: '',
          rate: Number(value),
          quantity: null
        }
      case 'quantity':
        return {
          item: '',
          rate: null,
          quantity: Number(value)
        }
      default:
        return {
          item: '',
          rate: null,
          quantity: null
        }
    }
  }

  useEffect(() => {
    if (data.invoiceRowItemFields != null && data.invoiceRowItemFields.length > 0) {
      const rowItems: InvoiceRowItemFormValues[] = []
      data.invoiceRowItemFields.forEach((column: IHeroInvoiceRow, index: number) => {
        // column.name = 'item'
        if (typeof column.defaultValue === 'string' && column.defaultValue !== '') {
          if (column?.defaultValue?.includes(',')) {
            const values = column.defaultValue.split(',')
            values.forEach((value: string, i: number) => {
              if (rowItems.length < values.length) {
                rowItems.push(getRowItemFormValues(column.name, value))
              } else {
                rowItems[i][column.name] = value
              }
            })
          } else {
            if (rowItems.length < 1) {
              rowItems.push(getRowItemFormValues(column.name, column.defaultValue))
            } else {
              rowItems[0][column.name] = column.defaultValue
            }
          }
        }
      })
      setRowItems([...rowItems])
    }
  }, [data])

  return (
    <Root ref={rootRef}>
      <DownloadContainer>
        <PreviewInvoice id='invoice' data={data} previewData={invoicePayload} onClose={closePreview} />
      </DownloadContainer>
      {showPreviewInvoice
        ? <PreviewInvoice id='invoice' data={data} previewData={invoicePayload} onClose={closePreview} />
        : (
          <WidgetRoot
            elevation={24}
          >
            <CardContent>
              <Stack justifyContent='space-between' flexDirection='row'>
                <Text variant='h6'>{data.title}</Text>
                <IconButton
                  color='primary'
                  aria-label='Download invoice'
                  onClick={() => downloadInvoice()}
                  disabled={!canSubmitForm}
                >
                  <Assets.Download />
                </IconButton>
              </Stack>
              <Form onChange={onChange} form={data.form} />
              <Card variant='outlined'>
                <InvoiceItemsTable>
                  {renderHeading()}
                  <Divider />
                  {rowItems.map(renderRepeatableRow)}
                  {renderNonRepeatableRow({
                    TextComponent: (
                      <Link onClick={addItem}>
                        <Text
                          variant='inherit'
                          component='span'
                          fontWeight={theme.typography.fontWeightBold}
                          color='primary'
                          onClick={addItem}
                        >
                          + Add Items
                        </Text>
                      </Link>
                    )
                  })}
                  <Divider />
                  {renderNonRepeatableRow({
                    value: totalAmount,
                    TextComponent: (
                      <Text
                        variant='inherit'
                        height={theme.spacing(3)}
                      >
                        Subtotal
                      </Text>
                    )
                  })}
                  <Divider />
                  {renderNonRepeatableRow({
                    value: totalAmount,
                    TextComponent: (
                      <Text
                        variant='inherit'
                        height={theme.spacing(3)}
                        fontWeight={theme.typography.fontWeightBold}
                      >
                        Total
                      </Text>
                    )
                  })}
                </InvoiceItemsTable>
              </Card>
              <Stack flexDirection='row' columnGap={2} mt={2}>
                <Button
                  variant='contained'
                  fullWidth
                  disabled={!canSubmitForm}
                  onClick={() => onSubmit('send')}
                >
                  Send
                </Button>
                <Button
                  variant='outlined'
                  fullWidth
                  disabled={!canSubmitForm}
                  onClick={() => onSubmit('preview')}
                >
                  Preview
                </Button>
              </Stack>
            </CardContent>
          </WidgetRoot>)}
    </Root>
  )
}

const Root = styled('div')(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(0, 2),
  [theme.breakpoints.up('lg')]: {
    padding: 0
  }
}))

const DownloadContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  opacity: 0,
  pointerEvents: 'none'
}))

const WidgetRoot = styled(Card)(({ theme }) => ({
  [`&.${cardClasses.root}`]: {
    borderRadius: 8,
    maxWidth: 375,
    [theme.breakpoints.up('md')]: {
      maxWidth: 674
    }
  },
  [`& > .${cardContentClasses.root}`]: {
    padding: theme.spacing(3)
  },
  [`& .${listItemClasses.root}`]: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5)
  },
  [`& .${typographyClasses.root}.MuiTypography-heading`]: {
    fontSize: theme.typography.caption.fontSize
  },
  [`& .${formControlClasses.root} .${inputBaseClasses.input}`]: {
    ...getFontSize({ theme }),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5)
  },
  [`& .${formHelperTextClasses.root}`]: {
    margin: 0
  },
  [`& .${formControlClasses.root}`]: {
    margin: 0
  },
  [`& .${formLabelClasses.root}`]: {
    ...getFontSize({ theme }),
    position: 'absolute',
    left: theme.spacing(2),
    top: theme.spacing(2)
  },
  [`& .${formControlClasses.root} .${inputBaseClasses.root}`]: {
    borderRadius: 0,
    paddingLeft: 124,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 194
    }
  },
  [`& .${inputBaseClasses.root} fieldset`]: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderBottomColor: colors.grey[200]
  },
  [theme.breakpoints.up('xs')]: {
    [`& > .${cardContentClasses.root}`]: {
      padding: theme.spacing(2)
    }
  },
  [theme.breakpoints.up('lg')]: {
    [`& > .${cardContentClasses.root}`]: {
      padding: theme.spacing(3)
    }
  }
}))

const InvoiceItemsTable = styled((props: ListProps) => (
  <List
    disablePadding
    {...props}
  />
))(({ theme }) => ({
  ...getFontSize({ theme })
}))

const InvoiceItemRow = styled((props: StackProps) => (
  <Stack
    direction='row'
    spacing={1}
    {...props}
  />
))(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  width: '100%'
}))

const InvoiceItemInput = styled(InputBase)(({ theme }) => ({
  [`& .${inputBaseClasses.input}`]: {
    ...getFontSize({ theme }),
    padding: 0,
    '&:not(.Mui-disabled):hover, &:focus': {
      background: colors.grey[200]
    }
  },
  [`& .${inputAdornmentClasses.root}`]: {
    ...getFontSize({ theme }),
    marginRight: 0
  },
  [`& .${inputBaseClasses.input}.Mui-disabled`]: {
    '-webkit-text-fill-color': theme.palette.text.primary
  }
}))

const rearrangeFormElements = (rootRef: React.RefObject<HTMLDivElement>): void => {
  if (rootRef.current != null) {
    const formNode = rootRef.current?.getElementsByTagName('form')[0]

    if (formNode == null) {
      return
    }

    /* Start - Group all elements with class MuiFormControl-root into MuiPaper-root */
    const formSeparatorIndices: number[] = []
    const formSeparatorGroupedIndices: number[][] = []
    const formControlGroups: Element[][] = []
    const formNodeSiblings: ChildNode[] = []
    let indexOfFormNode = 0

    Array.from(formNode.children).forEach((node, index) => {
      if (!node.classList.contains(formControlClasses.root)) {
        formSeparatorIndices.push(index)
      }
    })

    formSeparatorIndices.forEach((_, index) => {
      if (index === 0 && formSeparatorIndices.length > 2) {
        formSeparatorGroupedIndices.push([formSeparatorIndices[0], formSeparatorIndices[1]])
      } else if (index !== formSeparatorIndices.length) {
        formSeparatorGroupedIndices.push([formSeparatorIndices[index], formSeparatorIndices[index + 1]])
      }
    })

    formSeparatorGroupedIndices.forEach((indicesPair) => {
      if (indicesPair != null) {
        formControlGroups.push(Array.from(formNode.children).slice(...indicesPair))
      }
    })

    formControlGroups.forEach(group => {
      if (group != null) {
        formNode.appendChild(group[0])
        const restOfNodes = group.slice(1, group.length)

        if (restOfNodes.length !== 0) {
          const formControlGroup = document.createElement('div')
          formControlGroup.classList.add(paperClasses.root)

          group.slice(1, group.length).map(node => formControlGroup.appendChild(node))
          formNode.appendChild(formControlGroup)
        }
      }
    })
    /* End - Group all elements with class MuiFormControl-root into MuiPaper-root */

    /* Start - Bring in all elements that are part of the form from outside of the form to inside */
    formNode.parentNode?.childNodes.forEach((node, index) => {
      if (node.nodeName === 'FORM') {
        indexOfFormNode = index
      }
    })

    formNode.parentNode?.childNodes.forEach((node, index) => {
      if (index > indexOfFormNode) {
        formNodeSiblings.push(node)
      }
    })

    formNodeSiblings.forEach(node => {
      formNode.appendChild(node)
    })
    /* End - Bring in all elements that are part of the form from outside of the form to inside */

    const paperNodeClassList: string[][] = []
    formNode.querySelectorAll(`.${paperClasses.root}`).forEach(node => {
      paperNodeClassList.push(node.className.split(' '))
    })

    formNode.querySelectorAll(`.${paperClasses.root}`).forEach(node => {
      node.className = uniq(paperNodeClassList.flat()).join(' ')
    })
  }
}

const getFontSize = ({ theme }): object => ({
  ...theme.typography.caption,
  [theme.breakpoints.up('md')]: {
    ...theme.typography.subtitle2
  }
})

interface InvoiceWidgetProps {
  data: IHeroInvoiceWidget
  onDone: (payload: object) => void
}

interface InvoiceRowItemFormValues {
  item?: string
  quantity?: number | null
  rate?: number | null
}

export const invoiceWidgetModifierStyle: HeroWidgetModifierStyle = {
  showFade: false
}

export default InvoiceWidget
