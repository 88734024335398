import { CSSProperties } from 'react'
import { config } from '@react-spring/web'
import { IErrorPage } from './types'

export const projectName = 'GoSite'

export const DEFAULT_ERROR_PAGE_DATA: IErrorPage = {
  title: '{&quot;time&quot;:1658493716246,&quot;blocks&quot;:[{&quot;id&quot;:&quot;C8XoRy0kKK&quot;,&quot;type&quot;:&quot;header&quot;,&quot;data&quot;:{&quot;text&quot;:&quot;&lt;b&gt;404 Error - Page Not Found&lt;/b&gt;&quot;,&quot;level&quot;:2}}],&quot;version&quot;:&quot;2.22.2&quot;}',
  description: '{&quot;time&quot;:1643782893036,&quot;blocks&quot;:[{&quot;id&quot;:&quot;5IsCGAX9B1&quot;,&quot;type&quot;:&quot;paragraph&quot;,&quot;data&quot;:{&quot;text&quot;:&quot;Sorry, the page you were looking for at this URL was not found.&quot;}}],&quot;version&quot;:&quot;2.22.2&quot;}',
  // locale: 'en',
  redirectButton: {
    __component: 'links.button-link',
    id: 104,
    url: '/',
    newTab: false,
    text: 'Back To Home Page',
    variant: 'solid',
    metaTitle: ''
  }
}

export enum Feature {
  Booking = 'booking',
  ContactHub = 'contact-hub',
  EmailCampaign = 'email-campaign',
  Invoice = 'invoice',
  Messenger = 'messenger',
  Payment = 'payment',
  Placement = 'placement',
  Review = 'review',
  Sites = 'sites',
  Engagement = 'engagement'
}

export enum Section {
  Hero = 'sections.hero',
  HeroMain = 'sections.hero-main',
  FeatureColumnsGroup = 'sections.feature-columns-group',
  FeatureRowsGroup = 'sections.feature-rows-group',
  ProductCta = 'sections.product-cta',
  DownloadMobileApp = 'sections.download-mobile-app',
  FeatureOverview1 = 'sections.feature-overview1',
  FeatureOverview2 = 'sections.feature-overview2',
  FeatureRow1 = 'sections.feature-row1',
  FeatureRow2 = 'sections.feature-row2',
  Testimonial = 'sections.testimonial',
  Testimonial3 = 'sections.testimonial-3'
}

export enum HeroContentPart {
  AdditionalText = 'elements.hero-body-additional-text',
  BackgroundMedia = 'elements.background-media',
  BusinessSearchResult = 'elements.hero-business-search-result',
  Content = 'elements.hero-content',
  CTA = 'elements.hero-call-to-action',
  Email = 'elements.email-confirmation',
  FeatureContent = 'elements.hero-body',
  Main = 'sections.hero-main',
  SignUpForm = 'elements.hero-sign-up',
  Testimonial = 'elements.testimonial',
  Widget = 'widget'
}

export const CURRENCY_SYMBOL = '$'

export const HERO_WIDGET_ANIMATION_CONFIG = {
  from: { opacity: 0, marginTop: 0, transform: 'perspective(900px) rotateY(90deg)' },
  enter: { opacity: 1, marginTop: 0, transform: 'perspective(900px) rotateY(0deg)' },
  leave: { opacity: 0, marginTop: -2000 },
  delay: 0,
  config: { mass: 15, tension: 300, friction: 60 }
}

export const HERO_CTA_CONFIG = {
  from: { opacity: 0, marginTop: -100, top: 0 },
  enter: { opacity: 1, marginTop: 0, top: 0 },
  leave: { opacity: 0, marginTop: 0, top: 500 },
  delay: 0,
  config: config.gentle
}

export const HERO_WIDGET_INTERACTION_RESULT_ANIMATION_CONFIG = {
  from: { opacity: 0, marginBottom: -100, left: 'auto' },
  enter: { opacity: 1, marginBottom: 0, left: 'auto' },
  leave: { opacity: 0, marginBottom: 200, left: -10000 },
  delay: 400,
  config: config.wobbly
}

export const HERO_SIGN_UP_FORM_ANIMATION_CONFIG = {
  from: { opacity: 0, marginBottom: -100 },
  enter: { opacity: 1, marginBottom: 0 },
  leave: { opacity: 0, marginBottom: 200 },
  delay: 500,
  config: config.wobbly
}

export const HERO_CTA_BUTTON_ANIMATION_CONFIG = {
  from: { opacity: 0 },
  enter: { opacity: 1 },
  leave: { opacity: 0 },
  delay: 500,
  config: config.molasses
}

export const MESSENGER_CHAT_BUBBLE_ANIMATION_CONFIG = {
  config: { duration: 4000 },
  from: { opacity: 0 },
  to: { opacity: 1 },
  enter: { opacity: 1 },
  leave: { opacity: 0 },
  delay: 100
}

export const STICKY_TAG_CONTENT_STYLES: CSSProperties = {
  background: '#ca3456',
  color: '#fff',
  padding: '10px 20px',
  borderRadius: '0 24px 24px 0px',
  boxShadow: `0 1px 2px rgba(0,0,0,0.07), 
  0 2px 4px rgba(0,0,0,0.07), 
  0 4px 8px rgba(0,0,0,0.07), 
  0 8px 16px rgba(0,0,0,0.07),
  0 16px 32px rgba(0,0,0,0.07), 
  0 32px 64px rgba(0,0,0,0.07)`,
  display: 'inline-block',
  width: 'fit-content',
  position: 'absolute',
  left: 0,
  right: 0,
  zIndex: 1
}

export const DEFAULT_FAVICON_PATH = '/images/favicon.png'
