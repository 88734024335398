import React, { CSSProperties } from 'react'
import dynamic from 'next/dynamic'
import {
  Box,
  BoxProps,
  styled,
  useMediaQuery,
  useTheme,
  Theme
} from '@gositeinc/ui'

import { HeroState } from '../utils'
import * as Constants from '../constants'

import styles from './hero-arrows.module.css'

const Arrow: React.ComponentType<ArrowProps> = dynamic(
  async () => await import('react-arrows'), { ssr: false })

const HeroArrows = ({ heroState, widgetType }: HeroArrowsProps): JSX.Element => {
  const theme = useTheme()
  const isScreenLargerThanLarge = useMediaQuery(theme.breakpoints.up('lg'))
  const isScreenLargerThanMedium = useMediaQuery(theme.breakpoints.up('md'))
  const isScreenLargerThanSmall = useMediaQuery(theme.breakpoints.up('sm'))

  if (typeof window === 'undefined' || typeof document === 'undefined') {
    return <></>
  }

  const getArrowTailTranslation = (): number[] => {
    if (isScreenLargerThanLarge && isScreenLargerThanMedium && isScreenLargerThanSmall) {
      if (heroState.isCallToActionVisible) {
        return [0.4, 0.3]
      }

      if (heroState.isWidgetInteractionResultVisible) {
        return [0.4, 2]
      }

      return [0.1, 0.2]
    }

    if (isScreenLargerThanMedium && isScreenLargerThanSmall) {
      if (heroState.isCallToActionVisible) {
        return [0.4, 0.3]
      }

      if (heroState.isWidgetInteractionResultVisible) {
        return [0.4, 2]
      }

      return [0.1, 0.2]
    }

    if (isScreenLargerThanSmall) {
      return [0.8, -0.1]
    }

    if (heroState.isSignUpFormVisible) {
      return [0, 0]
    }

    return [0.8, -0.1]
  }

  const getArrowHeadTranslation = (): number[] => {
    if (isScreenLargerThanLarge && isScreenLargerThanMedium && isScreenLargerThanSmall) {
      if (heroState.isCallToActionVisible) {
        return [-0.2, 0.8]
      }

      if (heroState.isWidgetInteractionResultVisible) {
        return [-0.1, 0.4]
      }

      if (heroState.isSignUpFormVisible) {
        return [-0.5, -0.1]
      }

      return [-0.7, 0.2]
    }

    if (isScreenLargerThanMedium && isScreenLargerThanSmall) {
      if (heroState.isCallToActionVisible) {
        return [-0.2, 0.8]
      }

      if (heroState.isWidgetInteractionResultVisible) {
        return [-0.1, 0.4]
      }

      return [-0.7, 0.2]
    }

    if (isScreenLargerThanSmall) {
      if (heroState.isWidgetVisible) {
        return [0.1, -0.2]
      }

      if (heroState.isWidgetInteractionResultVisible) {
        return [0.9, 0.3]
      }

      return [0.2, 0.5]
    }

    if (heroState.isWidgetVisible) {
      return [0.1, -0.2]
    }

    if (heroState.isSignUpFormVisible) {
      return [0.4, 0.8]
    }

    return [0.6, 0.2]
  }

  return (
    <>
      <Arrow
        className={styles['GoSiteHero-arrow']}
        from={{
          direction: 'bottom',
          node: () => document.getElementById('heroArrowTailAnchor'),
          translation: getArrowTailTranslation()
        }}
        to={{
          direction: 'left',
          node: () => document.getElementById('heroArrowHeadAnchor'),
          translation: getArrowHeadTranslation()
        }}
      // onChange={...}
      />
    </>
  )
}

export const HeroArrowTailAnchor = styled(Box)<HeroArrowProps>(
  ({ theme, heroState, widgetType }: HeroArrowProps & { theme: Theme }
  ) => ({
    height: 1,
    position: 'absolute',
    transition: 'all 0.2s ease-in-out',
    width: 1,
    ...getArrowTailPosition({ theme, heroState, widgetType })
  }))

export const HeroArrowHeadAnchor = styled(Box)<HeroArrowProps>(
  ({ theme, heroState, widgetType }: HeroArrowProps & { theme: Theme }
  ) => ({
    position: 'absolute',
    height: 1,
    transition: 'all 0.2s ease-in-out',
    width: 1,
    ...getArrowHeadPosition({ theme, heroState, widgetType })
  }))

const getArrowTailPosition = ({ theme, widgetType, heroState }: GetArrowPositionArgs): object => {
  if (heroState.isWidgetVisible) {
    return {
      bottom: 20,
      right: -5,
      top: 'auto',
      [theme.breakpoints.up('md')]: {
        bottom: -10,
        right: 60,
        top: 'auto'
      }
    }
  }

  if (heroState.isSignUpFormVisible) {
    return {
      bottom: 'auto',
      right: -10,
      top: 5,
      [theme.breakpoints.up('md')]: {
        bottom: -10,
        right: 10,
        top: 'auto'
      },
      [theme.breakpoints.up('md')]: {
        bottom: 10,
        right: -10,
        top: 'auto'
      }
    }
  }

  return {
    bottom: 'auto',
    right: 10,
    top: -10,
    [theme.breakpoints.up('md')]: {
      bottom: 10,
      right: -10,
      top: 'auto'
    }
  }
}

const getArrowHeadPosition = ({ theme, widgetType, heroState }: GetArrowPositionArgs): object => {
  if (heroState.isWidgetVisible) {
    if (heroState.isCallToActionVisible) {
      return {
        bottom: 'auto',
        right: 'calc(50% - 150px)',
        top: -10,
        [theme.breakpoints.up('md')]: {
          bottom: 300,
          left: 0,
          top: 'auto'
        }
      }
    }

    return {
      bottom: 'auto',
      right: 'calc(50% - 150px)',
      top: -10,
      [theme.breakpoints.up('md')]: {
        bottom: 120,
        left: 0,
        top: 'auto'
      }
    }
  }

  if (heroState.isSignUpFormVisible) {
    return {
      bottom: -10,
      right: '15%',
      top: 'auto',
      [theme.breakpoints.up('sm')]: {
        right: '25%'
      },
      [theme.breakpoints.up('md')]: {
        bottom: 100,
        left: 0,
        top: 'auto'
      }
    }
  }

  return {
    bottom: widgetType === Constants.Feature.ContactHub
      ? -10
      : 30,
    right: 'calc(50% - 120px)',
    top: 'auto',
    [theme.breakpoints.up('md')]: {
      bottom: 200,
      left: widgetType === Constants.Feature.ContactHub
        ? 0
        : 'calc(50% - 120px)',
      top: 'auto'
    }
  }
}

interface HeroArrowsProps {
  heroState: HeroState
  widgetType: Constants.Feature
}

type HeroArrowProps = BoxProps & {
  heroState: HeroState
  widgetType: Constants.Feature
}

interface ArrowProps {
  className: string
  from: ArrowNode
  to: ArrowNode
  style?: CSSProperties
}

interface ArrowNode {
  direction: string
  node: () => HTMLElement | null
  translation: number[]
}

interface GetArrowPositionArgs extends HeroArrowsProps {
  theme: Theme
}

export default HeroArrows
