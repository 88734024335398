import { IAnalyticsEvent } from './analytics-types'

export const ANALYTICS_CONSTANTS: IAnalyticsEvent = {
  navbar: {
    buttons: 'mkt_navbar_button_link_clicked'
  },
  'sections.product-cta': {
    button: 'mkt_start_free_trial_footer'
  },
  'sections.testimonial-1': {
    ctaButton: 'mkt_to_blogpost'
  },
  'sections.download-mobile-app': {
    appDownloadLinkApple: 'mkt_to_ios_appstore_phmodal',
    appDownloadLinkGoogle: 'mkt_to_google_play_phmodal'
  },
  'sections.tab-view-section': {
    primaryButton: 'mkt_tabview_primary_btn_clicked',
    secondaryButton: 'mkt_tabview_secondary_btn_clicked'
  },
  'elements.hero-content': {
    button: 'mkt_start_24d_free_trial',
    storeLinkApple: 'mkt_to_ios_appstore_top',
    storeLinkGoogle: 'mkt_to_google_play_top'
  },
  'sections.feature-row1': {
    buttonLink: 'mkt_feature_row_button_clicked'
  },
  'sections.feature-row2': {
    buttonLink: 'mkt_feature_row_button_clicked'
  },
  'sections.hero-main': {
    buttonLink: 'mkt_hero_button_link_clicked',
    ctaLink: 'mkt_hero_cta_button_clicked',
    storeLinkApple: 'mkt_to_ios_appstore_top',
    storeLinkGoogle: 'mkt_to_google_play_top'
  }
}
