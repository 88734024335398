import React from 'react'
import {
  BackgroundMedia,
  Box,
  Button,
  Card,
  cardClasses,
  CardContent,
  cardContentClasses,
  colors,
  Link,
  Container,
  Grid,
  Text,
  styled,
  useTheme,
  useMediaQuery
} from '@gositeinc/ui'

import { env } from '../utils'
import { IProductCta } from '../types'
import * as Assets from '../assets'
import { productCTAClasses } from '../classes'
import { ANALYTICS_CONSTANTS } from 'src/analytics-constants'

const ProductCta = ({ data, analytics }: ProductCtaProps): JSX.Element => {
  const theme = useTheme()
  const isLargerScreen = useMediaQuery(theme.breakpoints.up('md'))

  const {
    backgroundImage,
    button,
    isBounded,
    label,
    subtitle,
    title,
    styles
  } = data

  const renderContent = (): JSX.Element => {
    return (
      <ContentRoot>
        {label !== '' &&
          <Text
            fontWeight={theme.typography.fontWeightBold}
            paddingBottom={theme.spacing(2.5)}
            color='primary'
            variant='subtitle2'
          >
            {label}
          </Text>}
        {title != null && title !== '' &&
          <Text
            rich
            className={productCTAClasses.title}
            paddingBottom={theme.spacing(1.25)}
            margin='0'
          >
            {title}
          </Text>}
        {subtitle != null && subtitle !== '' &&
          <Text
            rich
            className={productCTAClasses.subtitle}
            paddingY={theme.spacing(1.25)}
            margin='0'
          >
            {subtitle}
          </Text>}
        {button?.text !== null && button?.text !== '' && (
          <Link
            link={button}
            data-analytics={analytics?.button}
            data-analytics-section-title={data?.title}
            data-analytics-action-title={data?.button?.text}
          >
            <Button
              variant='contained'
              sx={{ mt: 2, height: 51 }}
              endIcon={<Assets.RightArrow pathColor={theme.palette.common.white} />}
            >
              {button?.text}
            </Button>
          </Link>)}
      </ContentRoot>
    )
  }

  if (isBounded && isLargerScreen) {
    return (
      <DesktopRoot
        sx={styles}
        className={productCTAClasses.root}
        isBounded={isBounded}
      >
        <Container disableGutters>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item md={6}>
                  {renderContent()}
                </Grid>
              </Grid>
            </CardContent>
            <BackgroundMedia
              media={backgroundImage}
              objectFit='cover'
              objectPosition='absolute'
            />
          </Card>
        </Container>
      </DesktopRoot>
    )
  }

  if (isLargerScreen) {
    return (
      <DesktopRoot
        sx={styles}
        className={productCTAClasses.root}
      >
        <Container maxWidth='md' disableGutters>
          <Grid container>
            <Grid item md={6}>
              {renderContent()}
            </Grid>
          </Grid>
        </Container>
        <BackgroundMedia
          media={backgroundImage}
          objectFit='cover'
          objectPosition='absolute'
        />
      </DesktopRoot>
    )
  }

  return (
    <Root
      variant='outlined'
      sx={styles}
      className={productCTAClasses.root}
    >
      <CardContent>
        {renderContent()}
      </CardContent>
    </Root>
  )
}

const ContentRoot = styled(Box)(({ theme }) => ({
  [`.${productCTAClasses.title} h2`]: {
    ...theme.typography.h4
  },
  [`.${productCTAClasses.subtitle} p`]: {
    ...theme.typography.subtitle3,
    fontSize: theme.spacing(2.25)
  }
}))

const DesktopRoot = styled(Box)<RootProps>(({ theme, isBounded }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: getRootHeight(),
  position: 'relative',
  [`& .${cardClasses.root}`]: {
    backgroundColor: 'transparent',
    borderColor: colors.grey[200],
    borderWidth: 2,
    borderRadius: 16,
    position: 'relative'
  },
  [`& .${cardContentClasses.root}`]: {
    padding: theme.spacing(12, 8)
  }
}))

const Root = styled(Card)(({ theme }) => ({
  borderColor: colors.grey[200],
  borderWidth: 2,
  borderRadius: 16,
  margin: theme.spacing(4, 2, 12, 2),
  textAlign: 'center',
  [`& .${cardContentClasses.root}`]: {
    padding: theme.spacing(6, 4)
  }
}))

const getRootHeight = (): number => {
  if (env.emulateOldSiteUI) {
    return 560
  }

  return 480
}

interface ProductCtaProps {
  data: IProductCta
  analytics: typeof ANALYTICS_CONSTANTS['sections.product-cta']
}

interface RootProps {
  isBounded?: boolean
}

export type { ProductCtaProps }
export default ProductCta
