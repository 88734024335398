import React, { useEffect } from 'react'
import App, { AppContext, AppProps } from 'next/app'
import Head from 'next/head'
import { DefaultSeo } from 'next-seo'
import Script from 'next/script'
import { LiveChatLoaderProvider, Intercom } from 'react-live-chat-loader'
import { CacheProvider, EmotionCache } from '@emotion/react'
import templateSettings from 'lodash/templateSettings'
import { withPasswordProtect } from '@storyofams/next-password-protect'
import {
  AppLinkNotificationBanner,
  ThemeProvider,
  CssBaseline,
  theme,
  IGlobal
} from '@gositeinc/ui'
import { gsap } from 'gsap/dist/gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin'
import { TextPlugin } from 'gsap/dist/TextPlugin'

import { LoginComponent } from 'src/domain/login-page'
import {
  enhanceLinks,
  createEmotionCache,
  env,
  getOpenGraphMediaData,
  getAppLinkNotificationBanner,
  getErrorPageData
} from 'src/utils'
import { getStrapiMedia } from 'src/utils/media'
import { getGlobalData } from 'src/utils/api'
import { DEFAULT_FAVICON_PATH } from 'src/constants'
import { AnalyticsProvider } from 'src/providers'

import '../public/css/fonts.css'
import '../public/css/termly.css'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, TextPlugin)

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()
templateSettings.interpolate = /{{([\s\S]+?)}}/g

const MyApp = ({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps
}: MyAppProps): JSX.Element => {
  const {
    appLinkNotificationBanner,
    global
  } = pageProps

  const { metadata, favicon, metaTitleSuffix }: IGlobal = global
  const favIconUrl = favicon !== null ? getStrapiMedia(favicon?.url) ?? '' : DEFAULT_FAVICON_PATH

  useEffect(() => {
    enhanceLinks()
  }, [])

  return (
    // <ErrorBoundary
    //   fallbackRender={({ error }) => renderErrorPage(error.stack, true)}
    // >
    <CacheProvider value={emotionCache}>
      <AnalyticsProvider
        writeKey={env.segmentWriteKey}
      >
        {/* Favicon */}
        <Head>
          {/* PWA primary color */}
          <meta name='theme-color' content={theme.palette.primary.main} />
          <link rel='shortcut icon' href={favIconUrl} />
          <link
            rel='stylesheet'
            type='text/css'
            charSet='UTF-8'
            href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css'
          />
          <link
            rel='stylesheet'
            type='text/css'
            href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css'
          />
        </Head>
        <Script
          id='browserUpdate'
          strategy='lazyOnload'
          dangerouslySetInnerHTML={{
            __html: ' var $buoop = {required:{e:-4,f:-3,o:-3,s:-1,c:-3},insecure:true,api:2022.06 }; function $buo_f(){ var e = document.createElement("Script"); e.src = "//browser-update.org/update.min.js"; document.body.appendChild(e);}; try {document.addEventListener("DOMContentLoaded", $buo_f,false)} catch(e){window.attachEvent("onload", $buo_f)}'
          }}
        />
        <Script
          id='googleTagManager'
          type='text/plain'
          data-categories='analytics'
          strategy='afterInteractive'
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'Script','dataLayer','${env.googleTagManagerId}');
        `
          }}
        />
        <Script
          id='segmentAnalytics'
          strategy='afterInteractive'
          dangerouslySetInnerHTML={{
            __html:
              `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error('Segment snippet included twice.');else{analytics.invoked=!0;analytics.methods=['trackSubmit','trackClick','trackLink','trackForm','pageview','identify','reset','group','track','ready','alias','debug','page','once','off','on','addSourceMiddleware','addIntegrationMiddleware','setAnonymousId','addDestinationMiddleware'];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement('Script');t.type='text/javascript';t.async=!0;t.src='https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';var n=document.getElementsByTagName('Script')[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey='${env.segmentWriteKey}';;analytics.SNIPPET_VERSION='4.15.3';
                    analytics.load('${env.segmentWriteKey}');
                    analytics.page();
                    }}();`
          }}
        />
        {/* Global site metadata */}
        <DefaultSeo
          titleTemplate={`%s | ${metaTitleSuffix}`}
          title={`${metadata.metaTitle} | ${metaTitleSuffix}`}
          description={metadata.metaDescription}
          openGraph={getOpenGraphMediaData(metadata)}
          twitter={{
            cardType: metadata.twitterCardType,
            handle: metadata.twitterUsername
          }}
        />
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <LiveChatLoaderProvider
            providerKey={env.intercomAppId}
            provider='intercom'
          >
            <AppLinkNotificationBanner
              storageKey='mkt_notification_banner_app_link'
              data={appLinkNotificationBanner}
            />
            <Component {...pageProps} />
            <Intercom />
          </LiveChatLoaderProvider>
        </ThemeProvider>
      </AnalyticsProvider>
    </CacheProvider>
    // </ErrorBoundary>
  )
}

// getInitialProps disables automatic static optimization for pages that don't
// have getStaticProps. So [[...slug]] pages still get SSG.
// Hopefully we can replace this with getStaticProps once this issue is fixed:
// https://github.com/vercel/next.js/discussions/10949
MyApp.getInitialProps = async (appContext: AppContext) => {
  // Calls page's `getInitialProps` and fills `appProps.pageProps`
  const locale = appContext.router.query?.locale as string ?? 'en'

  const appProps = await App.getInitialProps(appContext)
  const errorPage = await getErrorPageData(locale)
  const global: IGlobal = await getGlobalData(locale)
  const appLinkNotificationBanner = await getAppLinkNotificationBanner(locale)

  return {
    ...appProps,
    pageProps: {
      errorPage,
      global,
      appLinkNotificationBanner
    }
  }
}

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
  pageProps: any
}

// Assigning AppPageProps to pageProps causes error on Component
// export interface AppPageProps {
//   errorPageData?: IErrorPage
//   global?: IGlobal
//   appLinkNotificationBanner?: IAppLinkNotificationBanner
//   blogPageData?: IBlogPage,
//   blogCategoriesGroupData?: IBlogCategoriesGroup
// }

export default process.env.NEXT_PUBLIC_PASSWORD_PROTECT_SITE != null && process.env.NEXT_PUBLIC_PASSWORD_PROTECT_SITE === 'y'
  ? withPasswordProtect(MyApp, {
    loginComponent: LoginComponent,
    loginComponentProps: {
      backUrl: 'https://www.gosite.com',
      logo: 'https://www.gosite.com/hubfs/GoSite.svg',
      buttonBackgroundColor: '#0258ff',
      buttonColor: '#FFFFFF'
    }
  })
  : MyApp
