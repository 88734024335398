import React from 'react'
import { Button, Grid, Card, CardActions, CardContent, Text, Box, Stack, colors, useTheme, Media, styled } from '@gositeinc/ui'
import hexToRgba from 'hex-to-rgba'

import { IContactHubWidgetPayload, IHeroContactHubWidget, IMedia } from '../types'
import { formatPhoneNumber } from '../utils'

const ContactCardWidget = ({ widgetData, widgetPayload }: ContactCardWidgetProps): JSX.Element => {
  const theme = useTheme()

  const {
    buttons,
    formFieldAttributes
  } = widgetData

  const {
    formValues
  } = widgetPayload

  const fields = Object.keys(formValues)
    .splice(1, Object.keys(formValues).length)
    .map(fieldKey => ({
      name: fieldKey,
      label: formFieldAttributes.find(attr => attr.name === fieldKey)?.label,
      icon: formFieldAttributes.find(attr => attr.name === fieldKey)?.icon
    }))
    .concat(formFieldAttributes.filter(attr => !Object.keys(formValues).includes(attr.name)))
    .map(field => ({
      ...field, value: formValues[field.name] ?? field.label
    }))

  const renderField = (item: { name: string, value: string | number, icon: IMedia | undefined }): JSX.Element => {
    const text = item.name === 'phone' ? formatPhoneNumber(String(item.value)) : item.value
    return (
      <Grid item md={6} xs={12}>
        <Stack direction='row'>
          <Box sx={{ width: 20 }} flex='none' alignItems='center'>
            <Media media={item.icon} width={20} height={20} />
          </Box>
          <Text ml={2} color='text.secondary' sx={{ wordBreak: 'break-word' }}>
            {text}
          </Text>
        </Stack>
      </Grid>
    )
  }
  const card = (
    <>
      <CardContent sx={{ p: 0 }}>
        <Stack mb={3} alignItems='center' columnGap={1} flexDirection='row'>
          <Text variant='h5'>
            {Object.values(formValues)[0]}
          </Text>
          <StatusCircle />
        </Stack>
        <Grid container rowSpacing={3} columnSpacing={2}>
          {fields.map(renderField)}
        </Grid>
      </CardContent>
      <CardActions sx={{ px: 0 }}>
        <Grid container alignItems='center' columnSpacing={2} rowSpacing={3}>
          {
            buttons.map((btn) => (
              <Grid item md={6} xs={12} key={btn?.id}>
                <Button
                  variant='outlined'
                  startIcon={<Media media={btn?.icon} height={16.5} width={16.5} />}
                  fullWidth
                  sx={{
                    borderColor: colors.grey[300],
                    color: theme.palette.text.primary
                  }}
                >
                  {btn.text}
                </Button>
              </Grid>
            ))
          }
        </Grid>
      </CardActions>
    </>
  )

  return (
    <Box sx={{ p: 3 }}>
      <Card sx={{ p: 3 }} elevation={10}>{card}</Card>
    </Box>
  )
}

const StatusCircle = styled(Box)(({ theme }) => ({
  height: 10,
  width: 10,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.light,
  boxShadow: `0px 0px 0px 2px ${hexToRgba(theme.palette.success.light, 0.5)}`
}))
interface ContactCardWidgetProps {
  widgetData: IHeroContactHubWidget
  widgetPayload: IContactHubWidgetPayload
}

export default ContactCardWidget
