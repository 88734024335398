import React, { useEffect, useState } from 'react'
import {
  BackgroundMedia,
  Box,
  Button,
  colors,
  Container,
  containerClasses,
  Divider,
  Grid,
  IconButton,
  Link,
  Media,
  styled,
  Stack,
  Text,
  typographyClasses,
  useTheme,
  useGsapAnimation,
  useMediaQuery,
  useButton
} from '@gositeinc/ui'
import { motion } from 'framer-motion'
import { gsap } from 'gsap'

// import { getComponentClassNamesObject } from '../utils/createClassName'
import { env } from '../utils'
import {
  IHeroMain,
  IImageLink,
  IMedia,
  SxProps
} from '../types'
import * as Assets from '../assets'
import { heroMainClasses } from 'src/classes'
import { ANALYTICS_CONSTANTS } from 'src/analytics-constants'

const transition = { duration: 1, ease: 'easeInOut', delay: 4 }

const masterAnimationTimeline: gsap.core.Timeline = gsap.timeline()

const HeroMain = ({ data, analytics }: HeroMainProps): JSX.Element => {
  const handleResize = (): boolean => {
    const windowWidth = window.innerWidth
    if (windowWidth < 767.98) {
      return true
    } else {
      return false
    }
  }
  const emulateOldSiteUI = env.emulateOldSiteUI
  const theme = useTheme()
  const { getVariant } = useButton()
  // const { analytics } = useAnalytics()
  const [isMobile, setIsMobile] = useState(false)
  const isBiggerScreen = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
    defaultMatches: true
  })
  // const isSmallerScreen = useMediaQuery(theme.breakpoints.down('sm'), {
  //   noSsr: true,
  //   defaultMatches: true
  // })
  const isSmallerScreen = isMobile
  const isLargerThanSmallScreen = useMediaQuery(theme.breakpoints.up('sm'))
  const { animateTextWithSwooshEffect } = useGsapAnimation()

  const {
    labelText,
    labelIcon,
    description,
    title,
    badges,
    storeLinks,
    backgroundImage,
    buttonLinks,
    ctaText,
    ctaUrl,
    ctaType,
    foregroundMedia,
    styles,
    smallScreenForegroundMedia
  } = data

  useEffect(() => {
    masterAnimationTimeline.add(animateTextWithSwooshEffect('#GoSiteHeroMain-title *'))
    const handlePageResized = (): void => {
      setIsMobile(handleResize)
    }

    window.addEventListener('resize', handlePageResized)
    window.addEventListener('orientationchange', handlePageResized)
    window.addEventListener('load', handlePageResized)
    window.addEventListener('reload', handlePageResized)

    return () => {
      window.removeEventListener('resize', handlePageResized)
      window.removeEventListener('orientationchange', handlePageResized)
      window.removeEventListener('load', handlePageResized)
      window.removeEventListener('reload', handlePageResized)
    }
    // titleTextAnimationTimeline.current = animateTextWithSwooshEffect('#heroBodyTitle *')

    // return () => {
    //   titleTextAnimationTimeline.current?.clear()
    // }
  }, [])

  const getTextTitleAttributes = (): object => {
    if (emulateOldSiteUI) {
      return {
        fontSize: { md: theme.spacing(6), xs: theme.spacing(4.5) },
        lineHeight: theme.spacing(6.5),
        sx: {
          whiteSpace: {
            md: 'pre'
          }
        }
      }
    }

    return {
      fontSize: { md: theme.spacing(7.5), xs: theme.spacing(4.5) },
      lineHeight: { md: theme.spacing(8), xs: theme.spacing(5.4) }
    }
  }

  const getTextDescriptionAttributes = (): object => {
    if (emulateOldSiteUI) {
      return {
        fontWeight: theme.typography.fontWeightMedium,
        lineHeight: '140%',
        py: 3,
        sx: {
          whiteSpace: {
            md: 'pre'
          }
        }
      }
    }

    return {
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: theme.spacing(3.5),
      py: 1.25
    }
  }

  const getHeroTheme = (): string => {
    switch (data?.theme) {
      case 'inverted':
        return theme.palette.common.white
      default:
        return theme.palette.text.primary
    }
  }

  const renderDivider = (index: number): JSX.Element => {
    if (isBiggerScreen && index + 1 < badges.length) {
      return (
        <Divider
          orientation='vertical'
          sx={{
            borderColor: theme.palette.text.secondary,
            height: theme.spacing(4),
            marginX: theme.spacing(3)
          }}
        />
      )
    }

    return <></>
  }

  const renderBadges = (): JSX.Element => {
    if (badges?.length === 0) {
      return <></>
    }

    return (
      <Grid
        container
        alignItems={isBiggerScreen ? 'center' : 'flex-start'}
        flexDirection={isBiggerScreen ? 'row' : 'column'}
        rowSpacing={isBiggerScreen ? 0 : 2}
        pb={4.5}
      >
        {badges?.length > 0 && badges?.map((link: IMedia, index: number) => (
          <React.Fragment key={index}>
            <Grid item>
              <Media key={index} media={link} />
            </Grid>
            {renderDivider(index)}
          </React.Fragment>
        ))}
      </Grid>
    )
  }

  const renderLinks = (): JSX.Element => {
    return (
      <Stack
        gap={4}
      >
        <ButtonStack
          className='GositeHeroMain-buttonLinks'
          direction='row'
          gap={2}
        >
          {buttonLinks?.length > 0 && buttonLinks?.map((button) => (
            <Link
              key={button.id}
              link={button}
              data-analytics={analytics?.buttonLink}
              data-analytics-action-title={button?.text}
              data-analytics-section-title={title}
            >
              <Button
                variant={getVariant(button.variant)}
                fullWidth={!isBiggerScreen}
                size='large'
                sx={{ justifyContent: 'space-between' }}
                endIcon={(
                  <Assets.RightArrow
                    pathColor={
                      getVariant(button.variant) === 'contained'
                        ? theme.palette.common.white
                        : theme.palette.primary.main
                    }
                  />
                )}
              >
                {button.text}
              </Button>
            </Link>
          ))}
        </ButtonStack>
        <Stack
          className='GositeHeroMain-storeLinks'
          direction='row'
          gap={2}
        >
          {storeLinks?.length > 0 && storeLinks?.map((link: IImageLink) => (
            <Link
              key={link.id}
              link={link}
              data-analytics={link?.url?.includes('apple') ? analytics?.storeLinkApple : analytics?.storeLinkGoogle}
              data-analytics-section-title={title}
              data-analytics-action-title={link?.url?.includes('apple') ? 'apple' : 'google'}
            >
              <Media media={link.media} />
            </Link>
          ))}
        </Stack>
      </Stack>
    )
  }

  const renderButton = (): JSX.Element => {
    return (
      <IconButton
        id='GoSiteHeroMain-ctaButton'
        sx={{
          background: colors.grey[100],
          height: 40,
          width: 40,
          ml: isSmallerScreen ? 1.5 : 5
        }}
      >
        <Assets.RightArrow pathColor={theme.palette.primary.main} />
      </IconButton>
    )
  }

  const renderCta = (): JSX.Element => {
    if (ctaType === 'link') {
      return (
        <CtaLink
          link={{
            url: ctaUrl,
            text: ctaText,
            newTab: false,
            id: 1
          }}
          data-analytics={analytics?.ctaLink}
          data-analytics-action-title={ctaText}
          data-analytics-section-title={title}
        >
          <Stack direction='row' columnGap={3} alignItems='center'>
            <Text
              variant='h5'
              fontWeight={theme.typography.fontWeightSemiBold}
              py={1.25}
            >
              {ctaText}
            </Text>
            {renderButton()}
          </Stack>
          {renderSwoosh()}
        </CtaLink>
      )
    } else if (ctaType === 'input') {
      return (
        <>
          <Stack
            direction='row'
            alignItems='center'
            mt={isLargerThanSmallScreen ? 5 : 0}
          >
            <Text
              id='GoSiteHeroMain-ctaText' /** Added a new ctaText id for text animation before textfield */
              sx={{
                color: '#fff',
                fontSize: '24px',
                fontWeight: theme.typography.fontWeightBold,
                width: 'auto'
              }}
            >
              {ctaText}
            </Text>
            {renderButton()}
          </Stack>
          {renderSwoosh()}
        </>
      )
    } else {
      return <></>
    }
  }

  const renderSwoosh = (): JSX.Element => {
    if (ctaText != null && ctaText !== '') {
      return (
        <motion.svg
          id='heroCtaSwooshImage'
          xmlns='http://www.w3.org/2000/svg'
          width='298'
          height='16'
          fill='none'
          viewBox='0 0 298 16'
        >
          <motion.path
            stroke='#0357FF'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='3'
            d='M2 14.4067C46.4444 7.36612 175.333 -3.45931 326 6.29752'
            initial={{ pathLength: 0, display: 'none' }}
            animate={{ pathLength: 1, display: 'block' }}
            transition={transition}
          />
        </motion.svg>
      )
    }
    return <></>
  }

  const renderContentColumn = (): JSX.Element => {
    return (
      <Grid
        item
        lg={env.emulateOldSiteUI ? 6 : 7}
        md={env.emulateOldSiteUI ? 6 : 7}
        xs={12}
        sm={12}
      >
        {renderBadges()}
        {/* <Box
          sx={{
            width: isBiggerScreen ? '90%' : '100%'
          }}
        > */}
        <Text
          className={heroMainClasses.title}
          id={heroMainClasses.title}
          rich
          gutterBottom
          color={getHeroTheme()}
          {...getTextTitleAttributes()}
        >
          {title}
        </Text>
        <Text
          className={heroMainClasses.description}
          id={heroMainClasses.description}
          rich
          color={getHeroTheme()}
          {...getTextDescriptionAttributes()}
        >
          {description}
        </Text>
        {renderCta()}
        {/* </Box> */}
        {renderLinks()}
      </Grid>
    )
  }

  const renderMediaList = (mediaList: Array<{
    id: number
    styles: SxProps
    media: IMedia
  }>): JSX.Element => {
    if (mediaList.length === 0) {
      return <></>
    }

    return (
      <>
        {mediaList?.map((item) => (
          <Box sx={item.styles} key={item.id} className={heroMainClasses.foregroundMedia}>
            <Media media={item.media} />
          </Box>
        ))}
      </>
    )
  }

  const renderForegroundMediaColumn = (): JSX.Element => {
    if (isBiggerScreen) {
      return (
        <Grid
          item
          lg={env.emulateOldSiteUI ? 6 : 5}
          md={env.emulateOldSiteUI ? 6 : 5}
        >
          {renderMediaList(foregroundMedia)}
        </Grid>
      )
    } else if (isSmallerScreen && smallScreenForegroundMedia.length > 0) {
      return (
        <Grid
          item
          xs={12}
          sm={12}
        >
          {renderMediaList(smallScreenForegroundMedia)}
        </Grid>
      )
    }
    return (
      <Grid
        item
        lg={env.emulateOldSiteUI ? 6 : 5}
        md={env.emulateOldSiteUI ? 6 : 5}
      >
        {renderMediaList(foregroundMedia)}
      </Grid>
    )
  }

  return (
    <Root sx={styles} color={getHeroTheme()}>
      <Container>
        <Label mb={2}>
          {labelIcon != null && (
            <Box width={22} pt={0.625}>
              <Media media={labelIcon} />
            </Box>
          )}
          {labelText !== '' && (
            <Text
              variant='h6'
              fontSize={theme.typography.heading.fontSize}
              color='primary'
              sx={{ textTransform: 'uppercase' }}
            >
              {labelText}
            </Text>
          )}
        </Label>
        <Grid
          container
          spacing={0}
          alignItems='center'
          justifyContent='center'
          position='relative'
        >
          {renderContentColumn()}
          {renderForegroundMediaColumn()}
        </Grid>
        <BackgroundMedia
          media={backgroundImage}
          objectFit='cover'
        />
      </Container>
    </Root>
  )
}

const Root = styled(Box)(({ theme }) => ({
  position: 'relative',
  minHeight: 720,
  padding: env.emulateOldSiteUI ? theme.spacing(8, 0) : theme.spacing(12, 0),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(20)
  },
  [`& > .${String(containerClasses.root)}`]: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: env.emulateOldSiteUI ? theme.spacing(6) : 0,
      paddingRight: env.emulateOldSiteUI ? theme.spacing(6) : 0
    }
  },
  [`.${heroMainClasses.title} h1`]: {
    ...theme.typography.h3
  },
  [`.${heroMainClasses.description} p`]: {
    fontSize: theme.spacing(2.5)
  }
}))

const CtaLink = styled(Link)(({ theme }) => ({
  display: 'inline-block',
  marginTop: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(5)
  },
  [`& .${String(typographyClasses.root)}`]: {
    color: theme.palette.text.primary
  }
}))

const Label = styled(Box)(({ theme }) => ({
  alignItems: 'start',
  display: 'flex',
  gap: theme.spacing(1.25),
  justifyContent: 'start'
}))

const ButtonStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column'
  }
}))

interface HeroMainProps {
  data: IHeroMain
  analytics: typeof ANALYTICS_CONSTANTS['sections.hero-main']
}

export type { HeroMainProps }

export default HeroMain
