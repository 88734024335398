import React, { useState, useCallback, useEffect } from 'react'
import { Box, Text, Grid, useLocalstorageState, SxProps, styled, TextFieldSubmitCombo, useTheme } from '@gositeinc/ui'
import { useLocalStorage } from 'react-use'
import validator from 'validator'

import { useAnalytics } from '../providers'
import { formatPhoneNumber } from '../utils'
import {
  IHeroCTA,
  IHero,
  IHeroFeatureContent,
  IHeroContent
} from '../types'
import * as Assets from '../assets'
import { Feature } from '../constants'
import { heroCTAClasses } from '../classes'

const HeroCta = ({
  parentData,
  contentData,
  data,
  onSubmit,
  onClickContinue,
  isMobileView,
  renderAdditionalContent,
  widgetType,
  ctaStyle
}: HeroCtaProps): JSX.Element => {
  const { analytics } = useAnalytics()
  const theme = useTheme()
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('')
  const [phoneNumber, setPhoneNumber] = useState<number | null>(null)
  const [segmentAnonymousId] = useLocalStorage<string | null>('ajs_anonymous_id')
  const [mostRecentEmail, setMostRecentEmail] = useLocalstorageState<string>('most_recent_email', '')
  const sx = { ...data.styles, ...ctaStyle }

  const isPhoneNumberValid = (): boolean => {
    return formattedPhoneNumber !== '' && validator.isMobilePhone(formattedPhoneNumber)
  }

  const onChangePhoneNumber = useCallback((event) => {
    const formatMobileNumber = formatPhoneNumber(event.target.value)
    setFormattedPhoneNumber(formatMobileNumber)
    setPhoneNumber(Number(formatMobileNumber.replace(/[^0-9]/g, '')))
  }, [phoneNumber])

  const onBlurPhoneNumber = (): void => {
    analytics?.track('mkt_enter_phone_number', {
      sectionData: parentData,
      sectionTitle: contentData?.title,
      actionTitle: data?.textFieldPlaceholder
    })
  }

  const _onSubmit = (event: React.SyntheticEvent): void => {
    event.preventDefault()
    if (phoneNumber != null && isPhoneNumberValid()) {
      onSubmit(phoneNumber)
    }
  }

  useEffect(() => {
    if (segmentAnonymousId != null) {
      if (mostRecentEmail == null || mostRecentEmail === '') {
        setMostRecentEmail(`${segmentAnonymousId}@ignore.com`)
      }
    }
  }, [segmentAnonymousId])

  return (
    <Root
      sx={sx}
      className={heroCTAClasses.root}
    >
      <RichTextRoot
        rich
        className={heroCTAClasses.title}
      >
        {data?.title}
      </RichTextRoot>
      <TextFieldBox>
        <form
          id='heroCTAForm'
          onSubmit={_onSubmit}
        >
          <TextFieldSubmitCombo
            placeholder={data?.textFieldPlaceholder}
            inputProps={{
              'aria-label': data?.textFieldPlaceholder,
              name: 'phone'
            }}
            onChange={onChangePhoneNumber}
            onBlur={onBlurPhoneNumber}
            value={formattedPhoneNumber}
            startAdornment={(
              <Assets.SmartPhone
                pathColor={theme.palette.primary.main}
                sx={{ fontSize: 24 }}
              />
            )}
            isValid={isPhoneNumberValid()}
          />
        </form>
      </TextFieldBox>
      <Box pt={1} sx={{ width: '100%' }}>
        <Grid
          container
          columnSpacing={1}
          alignItems='center'
          justifyContent='center'
          flexDirection='column'
        >
          {isMobileView && (
            <Grid item pb={2} display='flex' justifyContent='center'>
              {renderAdditionalContent()}
            </Grid>
          )}
          <Grid item display='flex'>
            <Box mt={0.3}>
              <Assets.LinktextIcon
                pathColor={theme.palette.primary.main}
                sx={{ fontSize: 18 }}
              />
            </Box>
            <Text
              variant='subtitle1'
              color='primary'
              fontWeight={theme.typography.fontWeightSemiBold}
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={onClickContinue}
              pl={1}
            >
              {data?.continueLinkText}
            </Text>
          </Grid>
        </Grid>
      </Box>
    </Root>
  )
}

const Root = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(4),
  textAlign: 'center',
  marginBottom: 30,
  [`.${heroCTAClasses.title} p`]: {
    ...theme.typography.body2
  }
}))

const RichTextRoot = styled(Text)(({ theme }) => ({
  width: 267,
  fontSize: theme.typography.subtitle3.fontSize,
  [theme.breakpoints.up('md')]: {
    width: 412
  }
}))

const TextFieldBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 0),
  width: '100%',
  [theme.breakpoints.up('md')]: {
    width: '100%',
    padding: theme.spacing(2, 0)
  }
}))

interface HeroCtaProps {
  onSubmit: (phoneNumber: number) => void
  parentData: IHero | null
  contentData: IHeroContent | IHeroFeatureContent
  data: IHeroCTA
  widgetType: Feature
  renderAdditionalContent: () => React.ReactNode
  isMobileView: boolean
  onClickContinue: () => void
  ctaStyle: SxProps
}

export default HeroCta
