import React, { useCallback } from 'react'
import validator from 'validator'
import { Box, Card, ImageContainer, Text, TextFieldSubmitCombo, Media, styled, useTheme } from '@gositeinc/ui'

import { IHeroSignUp } from '../types'
import * as Assets from '../assets'

const HeroSignUpForm = ({ data, onSubmit, onBlur }: HeroSignUpProps): JSX.Element => {
  const theme = useTheme()
  const [email, setEmail] = React.useState<string>('')
  const isValidEmail = validator.isEmail(email)

  const onBlurEmail = (): void => {
    if (onBlur != null) {
      onBlur()
    }
  }

  const _onSubmit = useCallback((e: React.SyntheticEvent) => {
    e.preventDefault()
    if (isValidEmail) {
      onSubmit(email)
    }
  }, [onSubmit, email])

  const renderForm = (): React.ReactNode => {
    return (
      <form
        id='heroSignUpForm'
        onSubmit={_onSubmit}
      >
        <TextFieldSubmitCombo
          placeholder='Your email'
          inputProps={{ 'aria-label': 'your email address', name: 'email', required: true, type: 'email' }}
          onChange={event => setEmail(event.target.value)}
          onBlur={onBlurEmail}
          elevation={6}
          startAdornment={
            <TextFieldSubmitComboStartAdornment>
              <Assets.MailAt fontSize='small' pathColor={theme.palette.primary.main} />
            </TextFieldSubmitComboStartAdornment>
          }
          isValid={isValidEmail}
        />
      </form>
    )
  }

  return (
    <Root>
      <StyledCard elevation={24}>
        <Content textAlign='center'>
          <Media
            media={data?.icon}
            component='img'
            height={22}
            width={22}
          />
          <Text
            variant='h5'
            fontWeight={theme.typography.fontWeightBold}
            sx={{
              marginBottom: theme.spacing(4),
              marginTop: theme.spacing(2)
            }}
          >
            {data?.title}
          </Text>
          {renderForm()}
        </Content>
        <ImageContainer>
          <Media media={data?.backgroundMedia} />
        </ImageContainer>
      </StyledCard>
    </Root>
  )
}

const Root = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingBottom: 0,
  '& .MuiCard-root': {
    borderRadius: 0,
    borderTopLeftRadius: Number(theme.shape.borderRadius) * 3,
    borderBottomLeftRadius: Number(theme.shape.borderRadius) * 3,
    minHeight: 435,
    [theme.breakpoints.up('md')]: {
      maxWidth: 720,
      width: '50vw'
    },
    '& form': {
      [theme.breakpoints.up('md')]: {
        maxWidth: 480,
        width: '30vw'
      }
    }
  }
}))

const StyledCard = styled(Card)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative'
}))

const Content = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  padding: theme.spacing(0, 2.5)
}))

const TextFieldSubmitComboStartAdornment = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(1)
}))

interface HeroSignUpProps {
  data: IHeroSignUp
  onSubmit: (payload: string) => void
  onBlur: () => void
}

export default HeroSignUpForm
