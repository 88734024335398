import React from 'react'
import { Box, Divider, Text, List, styled, useTheme } from '@gositeinc/ui'

import EmailConfirmation, { EmailBodyContent } from './email-confirmation'
import MessengerWidgetBody from './messenger-widget-body'
import {
  IEmailConfirmation,
  IHeroMessengerWidget
} from '../types'
import { messengerEmailConfirmationClasses } from '../classes'

const MessengerEmailConfirmation = ({ widgetData, widgetPayload }: MessengerEmailConfirmationProps): JSX.Element => {
  const {
    messengerItems
  } = widgetData
  const {
    senderAvatarColor,
    senderAvatarIcon,
    senderName,
    receiverName,
    emailTitle,
    emailContent
  } = widgetPayload
  const theme = useTheme()

  return (
    <EmailConfirmation
      senderAvatarColor={senderAvatarColor}
      senderAvatarIcon={senderAvatarIcon}
      senderName={senderName}
      receiverName={receiverName}
      title={emailTitle}
      body={
        <BodyRoot>
          <EmailBody>
            <EmailBodyContent>
              <Text
                rich
                className={messengerEmailConfirmationClasses.emailContent}
              >
                {emailContent}
              </Text>
            </EmailBodyContent>
            <Divider variant='middle' sx={{ marginX: theme.spacing(1) }} />
          </EmailBody>
          <StyledList>
            <MessengerWidgetBody data={messengerItems} />
          </StyledList>
        </BodyRoot>
      }
    />
  )
}

const BodyRoot = styled(Box)(({ theme }) => ({
  [`.${messengerEmailConfirmationClasses.emailContent} p`]: {
    ...theme.typography.caption
  }
}))

const EmailBody = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  '& .MuiList-root': {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(0, -1)
  },
  '& .MuiListItem-root': {
    // margin: theme.spacing(0, 1),
    padding: 0,
    width: 'auto',
    whiteSpace: 'pre'
  },
  '& .MuiListItemIcon-root': {
    color: theme.palette.primary.main,
    minWidth: 15.5
  },
  '& .MuiListItemText-primary': {
    fontSize: theme.typography.caption2.fontSize
  },
  '& .MuiTypography-root': {
    marginBottom: 0,
    textAlign: 'left'
  }
}))

const StyledList = styled(List)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    fontSize: '7px'
  },
  '& .MuiListItem-root': {
    padding: '0'
  },
  '& .MuiListItemText-root': {
    maxWidth: '130px',
    padding: theme.spacing(1, 2)
  },
  '& .MuiList-root': {
    padding: 0
  }
}))
interface MessengerEmailConfirmationProps {
  widgetData: IHeroMessengerWidget
  widgetPayload: IEmailConfirmation
}

export default MessengerEmailConfirmation
