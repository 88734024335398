import React, { CSSProperties, ReactElement, useCallback, useEffect, useState } from 'react'
import { useTransition, animated, AnimatedProps, useSpringRef } from '@react-spring/web'
import { fromUnixTime, getUnixTime, format as dateFormat, addMonths, lastDayOfMonth, getHours } from 'date-fns'
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  styled,
  StaticTimePicker,
  StaticDatePicker,
  Text,
  TextField,
  Theme,
  useMediaQuery,
  useTheme
} from '@gositeinc/ui'

import BookingServiceDetails from './booking-service-details'
import { IHeroBookingWidget } from '../types'
import { HeroWidgetModifierStyle } from './hero-widget'

const BookingWidget = (props: BookingWidgetProps): JSX.Element => {
  const { data, onDone } = props
  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'))
  // const date = '[aria-label="' + dateFormat(new Date(), "MMM d, y") + '"]'

  const [serviceDate, setServiceDate] = useState<Date>(new Date())
  const [serviceTime, setServiceTime] = useState<Date>(new Date())
  const [currentViewIndex, setCurrentViewIndex] = useState(0)
  const viewTransitionRef = useSpringRef()

  const transitions = useTransition(currentViewIndex, {
    ref: viewTransitionRef,
    keys: null,
    from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' }
  })

  const onChangeServiceDate = useCallback((date: Date): void => {
    setServiceDate(date)
    setCurrentViewIndex(state => (state + 1) % 2)
  }, [])

  const _onChangeServiceTime = useCallback((time: Date) => {
    setServiceTime(time)
    if ((serviceDate !== null) && time !== null) {
      return onDone({
        bookingDateTime: new Date(
          serviceDate?.getFullYear(),
          serviceDate?.getMonth(),
          serviceDate?.getDate(),
          time?.getHours(),
          time?.getMinutes()
        )
      })
    }
    // return setCurrentViewIndex(state => (state + 1) % 2)
  }, [onDone, serviceDate])

  const onClickSelectedServiceDate = (): void => {
    setCurrentViewIndex(state => (state + 1) % 2)
  }

  useEffect(() => {
    viewTransitionRef.start()
  }, [currentViewIndex])

  const renderDatePickerView = (): JSX.Element => {
    const maximumMonths: number = (data?.maximumMonths === null ? 3 : data?.maximumMonths)
    return (
      <Box position='absolute'>
        <CardContent sx={{ padding: theme.spacing(2, 3, 1), position: 'relative' }}>
          <Text
            variant='subtitle1'
            fontWeight={theme.typography.fontWeightSemiBold}
          >
            Select a date
          </Text>
          <DatePickerTodayLink
            onClick={() => onChangeServiceDate(new Date())}
            underline='none'
            variant='caption'
          >
            Today
          </DatePickerTodayLink>
        </CardContent>
        <CardContent sx={{ padding: 0 }}>
          <StaticDatePicker
            displayStaticWrapperAs='desktop'
            value={serviceDate}
            onChange={onChangeServiceDate}
            allowSameDateSelection
            minDate={new Date()}
            maxDate={lastDayOfMonth(addMonths(new Date(), maximumMonths - 1))}
            renderInput={(params: any) => <TextField {...params} />}
          />
        </CardContent>
      </Box>
    )
  }

  const renderTimePickerView = (): ReactElement => {
    return (
      <Box position='absolute'>
        <CardContent
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            padding: theme.spacing(2, 3)
          }}
        >
          <Box display='flex'>
            <Text
              variant='subtitle1'
              fontWeight={theme.typography.fontWeightSemiBold}
            >
              Select a time
            </Text>
          </Box>
          <Link
            onClick={onClickSelectedServiceDate}
            variant='caption'
            // component='Button'
            underline='always'
          >
            {serviceDate !== undefined && dateFormat(serviceDate, 'EEEE, MMMM d')}
          </Link>
        </CardContent>
        <CardContent sx={{ padding: theme.spacing(2, 3) }}>
          <StaticTimePicker
            value={serviceTime}
            intervalInMinutes={data?.serviceDurationInMinutes}
            minTime={fromUnixTime(getUnixTime(new Date(2012, 1, 29, (serviceDate.getDate() === new Date().getDate() ? getHours(serviceDate) + 1 : 7), 0, 0)))}
            maxTime={fromUnixTime(getUnixTime(new Date(2012, 1, 29, 20, 0, 0)))}
            onChange={_onChangeServiceTime}
          />
        </CardContent>
      </Box>
    )
  }

  const views: Array<(props: AnimatedProps<{ style: CSSProperties }>) => ReactElement> = [
    ({ style }) => (
      <animated.div style={{ ...style, backgroundColor: theme.palette.background.default }}>
        {renderDatePickerView()}
      </animated.div>
    ),
    ({ style }) => (
      <animated.div style={{ ...style, backgroundColor: theme.palette.background.default }}>
        {renderTimePickerView()}
      </animated.div>
    )
  ]

  return (
    <Root elevation={24}>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={5}>
          <Box sx={{ alignContent: 'stretch', display: 'flex', height: '100%' }}>
            <CardContent sx={{ minHeight: { sm: 353 }, padding: theme.spacing(2, 3) }}>
              <BookingServiceDetails data={data} />
            </CardContent>
            {isLargeScreen && <Divider orientation='vertical' flexItem />}
          </Box>
        </Grid>
        <Grid item xs={12} sm={7}>
          {!isLargeScreen && <Divider sx={{ width: '100%' }} />}
          <Box sx={{ minHeight: 400, overflow: 'hidden' }}>
            {transitions((style, i) => {
              const View = views[i]
              return <View style={style} />
            })}
          </Box>
        </Grid>
      </Grid>
    </Root>
  )
}

const Root: React.ElementType = styled(Card)(({ theme }) => ({
  borderRadius: 8,
  maxWidth: 326,
  [theme.breakpoints.up('sm')]: {
    maxWidth: 560
  },
  '& .MuiCalendarPicker-root': {
    maxHeight: 320,
    margin: theme.spacing(0, 0.5)
  },
  '& .MuiCalendarPicker-root [role="presentation"]': {
    fontSize: theme.typography.body2.fontSize,
    justifyContent: 'center',
    width: 180
  },
  '& .MuiCalendarPicker-root [role="presentation"] .MuiButtonBase-root': {
    display: 'none'
  },
  '& .MuiCalendarPicker-root [role="presentation"] + div': {
    position: 'absolute',
    left: theme.spacing(2),
    top: 62,
    width: 196
  },
  '& .MuiCalendarPicker-root [role="presentation"] + div .MuiButtonBase-root:nth-of-type(2)': {
    position: 'absolute',
    right: 0
  },
  '& .MuiButton-root': {
    borderWidth: '0 !important'
  },
  '& .css-idaj5i-MuiTypography-root-MuiLink-root': {
    textDecoration: 'underline !important'
  }
  // '& .MuiCalendarPicker-root .MuiCalendarPicker-viewTransitionContainer [role="cell"]': {
  //   [styleProps.property]: {
  //     backgroundColor: 'transparent',
  //     color: 'theme.palette.text.primary'
  //   }
  // }

}))

const DatePickerTodayLink = styled(Link)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(3.5),
  top: theme.spacing(2.25),
  fontSize: theme.typography.body2.fontSize
}))

interface BookingWidgetProps {
  data: IHeroBookingWidget
  onDone: (payload: object) => void
}

export const bookingHeroWidgetModifierStyle: HeroWidgetModifierStyle = {
  showFade: false,
  heroWidgetGridPropsCreator: (theme: Theme) => ({
    sx: {
      [theme.breakpoints.down('md')]: {
        paddingRight: 0
      }
    }
  }),
  heroBodyGridPropsCreator: (theme: Theme) => ({
    sx: {
      [theme.breakpoints.down('md')]: {
        paddingLeft: 0
      }
    }
  })
}

export default BookingWidget
