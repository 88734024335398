import React from 'react'
import {
  Box,
  BoxProps,
  Button,
  Chip,
  Grid,
  Link,
  Media,
  Stack,
  styled,
  Text,
  useButton,
  useTheme
} from '@gositeinc/ui'

import { IButtonLink, IFeatureRow, IValueProposition } from '../types'
import * as Assets from '../assets'
import { featureRowBodyClasses } from '../classes'
import { ANALYTICS_CONSTANTS } from 'src/analytics-constants'

export const FeatureRowBody = ({ data, analytics, ...boxProps }: FeatureRowBodyProps): JSX.Element => {
  const theme = useTheme()
  const { getVariant } = useButton()

  const {
    label,
    title,
    description,
    buttonLink,
    valuePropositions,
    valuePropositionColumnLayout,
    content,
    iconList
  } = data

  const renderValuePropositionItem = (item: IValueProposition): JSX.Element => {
    return (
      <Box>
        <Stack
          direction='row'
          alignItems='center'
          gap={2}
        >
          {item?.icon != null && (
            <Media media={item?.icon} />
          )}
          <Text
            rich
            className={featureRowBodyClasses.valuePropositionTitle}
            sx={{
              fontWeight: item?.icon != null
                ? theme.typography.fontWeightBold
                : theme.typography.fontWeightSemiBold
            }}
            gutterBottom
            color={theme.palette.primary.main}
          >
            {item.title}
          </Text>
        </Stack>
        <Text
          rich
          className={featureRowBodyClasses.valuePropositionDescription}
          color='text.secondary'
          gutterBottom
        >
          {item.description}
        </Text>
      </Box>
    )
  }

  const renderValuePropositions = (): JSX.Element => {
    if (valuePropositions == null || valuePropositions.length === 0) {
      return <></>
    }

    if (valuePropositionColumnLayout !== 'one') {
      return (
        <Grid container spacing={2}>
          {valuePropositions?.map((item: IValueProposition) => (
            <Grid item key={item.id} md={6}>
              {renderValuePropositionItem(item)}
            </Grid>
          ))}
        </Grid>
      )
    }

    return (
      <Stack gap={2}>
        {valuePropositions.map(renderValuePropositionItem)}
      </Stack>
    )
  }

  const renderButton = (link: IButtonLink): JSX.Element => {
    if (getVariant(link.variant) === 'text') {
      return (
        <Link
          link={link}
          data-analytics={analytics?.buttonLink}
          data-analytics-section-title={data?.title}
          data-analytics-action-title={link?.text}
          color='primary'
          fontWeight={theme.typography.fontWeightSemiBold}
        >
          {link.text}
        </Link>
      )
    }

    return (
      <Link
        link={link}
        data-analytics={analytics?.buttonLink}
        data-analytics-section-title={data?.title}
        data-analytics-action-title={link?.text}
      >
        <Button
          variant={getVariant(link.variant)}
          color='primary'
          size='large'
          endIcon={
            getVariant(link.variant) === 'contained' && (
              <Assets.RightArrow
                pathColor={theme.palette.common.white}
              />
            )
          }
        >
          {link.text}
        </Button>
      </Link>
    )
  }

  return (
    <Root
      className={featureRowBodyClasses.root}
      sx={{ width: { lg: data.mediaOrder !== 'center' ? 'max-content' : 'auto' } }}
      {...boxProps}
    >
      {label !== null && <Chip label={label} />}
      {title !== '' && (
        <Text
          rich
          className={featureRowBodyClasses.title}
          my={3}
        >
          {title}
        </Text>
      )}
      {description !== '' && (
        <Text
          rich
          className={featureRowBodyClasses.description}
          color='text.secondary'
          sx={{
            mb: 4,
            width: {
              md: data.mediaOrder !== 'center' ? 'max-content' : 'auto'
            }
          }}
        >
          {description}
        </Text>
      )}
      {renderValuePropositions()}
      {buttonLink?.text != null && (
        <Box mt={5}>{renderButton(buttonLink)}</Box>
      )}
      {content != null && content !== '' && (
        <Text
          rich
          className={featureRowBodyClasses.content}
          mt={8}
        >
          {content}
        </Text>
      )}
      {iconList != null && (
        <MediaListContainer>
          {iconList?.map(function (media) {
            return (
              <Box
                pr={2.75}
                pb={2.5}
                key={media.id}
                className={featureRowBodyClasses.media}
              >
                <Media
                  media={media}
                />
              </Box>
            )
          })}
        </MediaListContainer>
      )}
    </Root>
  )
}

const Root = styled(Box)(({ theme }) => ({
  [`.${featureRowBodyClasses.valuePropositionTitle} p`]: {
    ...theme.typography.h6
  },
  [`.${featureRowBodyClasses.valuePropositionTitle} h2`]: {
    ...theme.typography.h6
  },
  [`.${featureRowBodyClasses.valuePropositionDescription} p`]: {
    ...theme.typography.body2
  },
  [`.${featureRowBodyClasses.title} h2`]: {
    ...theme.typography.h3
  },
  [`.${featureRowBodyClasses.description} p`]: {
    ...theme.typography.heading,
    fontWeight: theme.typography.fontWeightRegular
  },
  [`.${featureRowBodyClasses.content} p`]: {
    ...theme.typography.body1
  }
}))

const MediaListContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  margin: theme.spacing(3, 0, 3, 0)
}))

interface FeatureRowBodyProps extends BoxProps {
  data: IFeatureRow
  analytics: typeof ANALYTICS_CONSTANTS['sections.feature-row1']
}
