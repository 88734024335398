import React from 'react'
import { format, addDays } from 'date-fns'
import isArray from 'lodash/isArray'
import { Image, Grid, Divider, Text, Card, Button, Box, CardContent, cardContentClasses, styled, useTheme } from '@gositeinc/ui'

// import PreviewInvoice from './preview-invoice'
import { IHeroInvoiceWidget, IInvoiceWidgetPayload } from '../types'

const InvoicesEmailConfirmation = ({ widgetPayload }: InvoiceEmailConfirmationProps): JSX.Element => {
  const theme = useTheme()
  const totalAmount = isArray(widgetPayload?.formValues?.rowItems) && widgetPayload?.formValues?.rowItems.map((item) => Number(item.quantity) * Number(item.rate))
    .reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0).toFixed(2)
  const renderRow = (rowdata: InvoiceRowItemFormValues, id: number): JSX.Element => {
    return (
      <InvoiceItemsRoot key={id}>
        <Grid container columnSpacing={0} paddingRight={1}>
          <Grid item xs={9}>
            <Grid sx={{ marginLeft: 2 }} container columnSpacing={0}>
              <Grid container columnSpacing={0}>
                <Grid item xs={12}>
                  <Text variant='subtitle2' fontSize={10} fontWeight={theme.typography.fontWeightBold}>{rowdata.item}</Text>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: theme.spacing(-3) }}>
                  <PreviewText variant='subtitle2' sx={{ textAlign: 'left' }}>{`${Number(rowdata.quantity)} × ${Number(rowdata.rate)} each`}</PreviewText>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Text textAlign='right' variant='subtitle2' fontSize={10} fontWeight={theme.typography.fontWeightRegular}>${rowdata?.quantity !== undefined && rowdata?.rate !== undefined && ((Number(rowdata.quantity) * Number(rowdata.rate))).toFixed(2)}</Text>
          </Grid>
        </Grid>
      </InvoiceItemsRoot>
    )
  }

  const renderSubTotalRow = (): JSX.Element => {
    return (
      <InvoiceNotItemsRoot>
        <Divider />
        <Grid container columnSpacing={2} paddingRight={3}>
          <Grid item xs={9}>
            <PreviewText variant='subtitle2' fontWeight={theme.typography.fontWeightRegular}>Subtotal</PreviewText>
          </Grid>
          <Grid item xs={3}>
            <Text textAlign='right' variant='subtitle2' fontSize={10} fontWeight={theme.typography.fontWeightRegular}>${totalAmount}</Text>
          </Grid>
        </Grid>
      </InvoiceNotItemsRoot>
    )
  }

  const renderTotalRow = (): JSX.Element => {
    return (
      <InvoiceNotItemsRoot>
        <Divider />
        <Grid container columnSpacing={2} paddingRight={4}>
          <Grid item xs={9}>
            <Text variant='subtitle2' fontSize={10} fontWeight={theme.typography.fontWeightBold}>Total</Text>
          </Grid>
          <Grid item xs={3}>
            <Text fontSize={10} variant='subtitle2' fontWeight={theme.typography.fontWeightBold}>${totalAmount}</Text>
          </Grid>
        </Grid>
      </InvoiceNotItemsRoot>
    )
  }

  const renderRows = (): JSX.Element | object => {
    if (widgetPayload?.formValues == null ||
      widgetPayload?.formValues?.rowItems == null ||
      !isArray(widgetPayload?.formValues?.rowItems) ||
      widgetPayload?.formValues?.rowItems.length === 0) {
      return <></>
    }
    return widgetPayload?.formValues.rowItems.map(renderRow)
  }

  return (
    <>
      <Image
        src='/images/search-light.svg'
        alt='invoice email logo'
        width={200}
        height={50}
      />
      <Root sx={{ marginTop: theme.spacing(-2), width: '100%', zIndex: 1, backgroundColor: '#F7F9FB' }}>
        <CardContent>
          <Text variant='body2' sx={{ marginTop: theme.spacing(2), fontWeight: '700', textAlign: 'center', fontSize: 12 }}>{widgetPayload?.formValues?.businessName}</Text>
          <PreviewText variant='caption' sx={{ display: 'block', textAlign: 'center' }}>Thanks for your Interest in our Services</PreviewText>
          <Text variant='subtitle3' sx={{ marginTop: theme.spacing(1), display: 'block', textAlign: 'center', fontSize: 15 }}>${totalAmount}</Text>
          <PreviewText variant='caption' sx={{ marginTop: theme.spacing(1), display: 'block', textAlign: 'center' }}>Due on {format(addDays(new Date(), 1), 'MMMM dd, yyyy')}</PreviewText>
          <Grid
            container
            spacing={0}
            direction='row'
            alignItems='center'
            justifyContent='center'
            sx={{ marginTop: theme.spacing(1) }}
          >
            <Button sx={{ width: '100%', marginBottom: theme.spacing(3) }} variant='contained' disableElevation>Pay Invoice</Button>
          </Grid>
          <Box sx={{ padding: theme.spacing(1.5), backgroundColor: '#fff', borderRadius: theme.spacing(0.5), border: ' 1px solid #EDF1F6' }}>
            <Box sx={{ textAlign: 'left' }}>
              <Text variant='subtitle2' fontSize={12} fontWeight={theme.typography.fontWeightBold}>{widgetPayload?.formValues?.businessName}</Text>
              <Grid container>
                <Grid item xs={8}><PreviewText variant='subtitle2'>{format(addDays(new Date(), 1), 'MMMM dd, yyyy')}</PreviewText></Grid>
              </Grid>
            </Box>
            <Box sx={{ paddingY: theme.spacing(2) }}>
              <Text sx={{ paddingBottom: theme.spacing(1), fontSize: 12 }} variant='subtitle2' fontWeight={theme.typography.fontWeightBold}>Bill To</Text>
              <Grid container sx={{ marginTop: theme.spacing(-1) }}>
                <Grid item xs={8}><PreviewText variant='subtitle2'>{widgetPayload?.formValues?.contact}</PreviewText></Grid>
              </Grid>
              <Grid container>
                <Grid item xs={8}><PreviewText variant='subtitle2'>{widgetPayload?.formValues?.email}</PreviewText></Grid>
              </Grid>
              <Grid container>
                <Grid item xs={8}><PreviewText variant='subtitle2'>{widgetPayload?.formValues?.phone}</PreviewText></Grid>
              </Grid>
            </Box>
            <Divider />
            {renderRows()}
            {renderSubTotalRow()}
            {renderTotalRow()}
          </Box>
        </CardContent>
      </Root>
    </>
  )
}

const Root = styled(Card)(({ theme }) => ({
  [`& > .${cardContentClasses.root}`]: {
    padding: theme.spacing(1),
    overflowY: 'auto'
  }
}))
const PreviewText: React.ElementType = styled(Text)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: theme.spacing(3.15),
  color: theme.palette.text.secondary,
  fontSize: 10
}))

const InvoiceItemsRoot: React.ElementType = styled(Box)(({ theme }) => ({
  '& .MuiTypography-root': {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
  },
  '.MuiGrid-root': {
    marginLeft: 0
  }
}))

const InvoiceNotItemsRoot: React.ElementType = styled(Box)(({ theme }) => ({
  '& .MuiTypography-root': {
    marginTop: theme.spacing(1.3),
    marginBottom: theme.spacing(1.3)
  }
}))
interface InvoiceRowItemFormValues {
  item?: string
  quantity?: number | null
  rate?: number | null
}

interface InvoiceEmailConfirmationProps {
  widgetData: IHeroInvoiceWidget
  widgetPayload: IInvoiceWidgetPayload
}

export default InvoicesEmailConfirmation
