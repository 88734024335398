import {
  HeroEmailSignupHandler,
  IHeroBookingSignUpPayload,
  IHeroPaymentSignUpPayload,
  IHeroPlacementSignUpPayload
} from '../types'
import { Feature } from '../constants'

export const useSignUpPayloadFormatter = (): UseSignUpPayloadFormatter => {
  const getSignUpPayload = (widgetType: Feature): HeroEmailSignupHandler => {
    const noop: HeroEmailSignupHandler = () => null

    const submitEmailHandler: { [key in Feature]: HeroEmailSignupHandler } = {
      booking: bookingHeroEmailSignUpHandler,
      'contact-hub': contactHubHeroEmailSignupHandler,
      'email-campaign': emailCamaignHeroEmailSignupHandler,
      invoice: invoiceHeroEmailSignupHandler,
      messenger: messengerHeroEmailSignupHandler,
      payment: paymentHeroEmailSignUpHandler,
      review: reviewHeroEmailSignUpHandler,
      sites: sitesHeroEmailSignUpHandler,
      engagement: engagementHeroEmailSignupHandler,
      placement: placementHeroEmailSignUpHandler
    }

    return submitEmailHandler[widgetType] ?? noop
  }

  return {
    getSignUpPayload
  }
}

export const bookingHeroEmailSignUpHandler = ({ email, phoneNumber, widgetPayload, widgetData }): IHeroBookingSignUpPayload => {
  const payload: IHeroBookingSignUpPayload = {
    ...widgetPayload
  }

  if (phoneNumber != null) {
    payload.phoneNumber = phoneNumber
  }

  if (email != null) {
    payload.email = email
  }

  return payload
}

export const paymentHeroEmailSignUpHandler = ({ email, phoneNumber, widgetPayload, widgetData }): IHeroPaymentSignUpPayload => {
  const payload: IHeroPaymentSignUpPayload = {
    ...widgetPayload
  }

  if (phoneNumber != null) {
    payload.phoneNumber = phoneNumber
  }

  if (email != null) {
    payload.email = email
  }

  return payload
}

export const placementHeroEmailSignUpHandler = ({ email, phoneNumber, widgetPayload, widgetData }): IHeroPlacementSignUpPayload => {
  const payload: IHeroPlacementSignUpPayload = {
    ...widgetPayload.placementResult
  }

  if (phoneNumber != null) {
    payload.phoneNumber = phoneNumber
  }

  if (email != null) {
    payload.email = email
  }

  return payload
}

export const reviewHeroEmailSignUpHandler = ({ email, phoneNumber, widgetPayload, widgetData }): IHeroBookingSignUpPayload => {
  const payload: IHeroBookingSignUpPayload = {
    ...widgetPayload
  }

  if (phoneNumber != null) {
    payload.phoneNumber = phoneNumber
  }

  if (email != null) {
    payload.email = email
  }

  return payload
}

export const contactHubHeroEmailSignupHandler = ({ email, phoneNumber, widgetPayload, widgetData }): IHeroBookingSignUpPayload => {
  const payload: IHeroBookingSignUpPayload = {
    ...widgetPayload
  }

  if (phoneNumber != null) {
    payload.phoneNumber = phoneNumber
  }

  if (email != null) {
    payload.email = email
  }

  return payload
}

export const emailCamaignHeroEmailSignupHandler = ({ email, phoneNumber, widgetPayload, widgetData }): IHeroBookingSignUpPayload => {
  const payload: IHeroBookingSignUpPayload = {
    ...widgetPayload
  }

  if (phoneNumber != null) {
    payload.phoneNumber = phoneNumber
  }

  if (email != null) {
    payload.email = email
  }

  return payload
}

export const messengerHeroEmailSignupHandler = ({ email, phoneNumber, widgetPayload, widgetData }): IHeroBookingSignUpPayload => {
  const payload: IHeroBookingSignUpPayload = {
    ...widgetPayload
  }

  if (phoneNumber != null) {
    payload.phoneNumber = phoneNumber
  }

  if (email != null) {
    payload.email = email
  }

  return payload
}

export const invoiceHeroEmailSignupHandler = ({ email, phoneNumber, widgetPayload, widgetData }): IHeroBookingSignUpPayload => {
  const payload: IHeroBookingSignUpPayload = {
    ...widgetPayload
  }

  if (phoneNumber != null) {
    payload.phoneNumber = phoneNumber
  }

  if (email != null) {
    payload.email = email
  }

  return payload
}

export const engagementHeroEmailSignupHandler = ({ email, phoneNumber, widgetPayload, widgetData }): IHeroBookingSignUpPayload => {
  const payload: IHeroBookingSignUpPayload = {
    ...widgetPayload
  }

  if (phoneNumber != null) {
    payload.phoneNumber = phoneNumber
  }

  if (email != null) {
    payload.email = email
  }

  return payload
}

export const sitesHeroEmailSignUpHandler = ({ email, phoneNumber, widgetPayload, widgetData }): IHeroBookingSignUpPayload => {
  const payload: IHeroBookingSignUpPayload = {
    ...widgetPayload
  }

  if (phoneNumber != null) {
    payload.phoneNumber = phoneNumber
  }

  if (email != null) {
    payload.email = email
  }

  return payload
}

export interface UseSignUpPayloadFormatter {
  getSignUpPayload: (widgetType: Feature) => HeroEmailSignupHandler
}
