import React, { useEffect, useReducer, useRef } from 'react'
import router from 'next/router'
import { Base64 } from 'js-base64'
import { useLocalstorageState } from '@gositeinc/ui'

import {
  HeroWidgetInteractionResultPayload,
  IEmailConfirmation,
  IHero,
  IHeroFeatureContent,
  IHeroBodyAdditionalText,
  IHeroContentPart,
  IHeroContent,
  IHeroCTA,
  IHeroSignUp,
  IHeroTestimonial,
  IHeroWidget,
  IHeroMain,
  IHeroBusinessSearchResult,
  IHeroSignUpPayload,
  IBackgroundMedia,
  HeroType,
  SxProps
} from '../types'
import { useSmsPayloadFormatter } from './useSmsPayloadFormatter'
import { useSignUpPayloadFormatter } from './useSignUpPayloadFormatter'
import { useAnalytics } from '../providers'
import { sendSMS } from './api'
import * as Constants from '../constants'
import { useHubSpotForms } from '../utils'

const initialState: HeroState = {
  isWidgetVisible: true,
  isCallToActionVisible: false,
  isWidgetInteractionResultVisible: false,
  isSignUpFormVisible: false,
  isBusinessSearchResultVisible: false,
  widgetPayload: {
    id: 0,
    __component: '',
    metaTitle: '',
    businessIcon: {
      id: 0,
      __component: '',
      metaTitle: '',
      url: '',
      alternativeText: '',
      width: 0,
      height: 0,
      ext: ''
    },
    businessIconColor: '',
    businessLocation: '',
    businessName: '',
    maximumMonths: 0,
    serviceDescription: '',
    serviceDurationInMinutes: 0,
    serviceHourlyPricing: '',
    serviceName: '',
    smsMessage: '',
    bookingDateTime: new Date(),
    reviewStars: 4,
    amount: 0,
    formValues: {},
    businessHours: [],
    emailContent: '',
    emailTitle: '',
    receiverName: '',
    senderAvatarColor: '',
    senderName: '',
    selectedService: {
      id: 0,
      __component: '',
      metaTitle: '',
      name: '',
      image: {
        id: 0,
        __component: '',
        metaTitle: '',
        url: '',
        alternativeText: '',
        width: 0,
        height: 0,
        ext: ''
      },
      price: 0
    },
    senderAvatarIcon: {
      id: 0,
      __component: '',
      metaTitle: '',
      url: '',
      alternativeText: '',
      width: 0,
      height: 0,
      ext: ''
    }
  },
  phoneNumber: null,
  email: ''
}

export const useHero = ({ data }: UseHeroProps): UseHero | null => {
  const { analytics } = useAnalytics()
  const { getSmsPayload } = useSmsPayloadFormatter()
  const { getSignUpPayload } = useSignUpPayloadFormatter()
  const heroSignUpForm = useRef<HTMLFormElement | null>(null)
  const { saveHeroSignUpForm, saveHeroCTAForm } = useHubSpotForms()
  const localStorageMostRecentEmail = useLocalstorageState<string>('most_recent_email', '')
  const [state, dispatch] = useReducer(reducer, initialState)
  const getContentPartData = (contentPartId: Constants.HeroContentPart): IHeroContentPart | undefined => {
    if (data === undefined) return undefined
    if (data.hero === undefined) return undefined
    return data?.hero?.contentParts?.find(contentPart => contentPart.__component.includes(contentPartId))
  }

  const widgetData = getContentPartData(Constants.HeroContentPart.Widget) as IHeroWidget
  const widgetType = widgetData?.__component.split('elements.')[1].split('-widget')[0] as Constants.Feature
  const contentData = getContentPartData(Constants.HeroContentPart.Content) as IHeroContent
  const featureContentData = getContentPartData(Constants.HeroContentPart.FeatureContent) as IHeroFeatureContent
  const signUpFormData = getContentPartData(Constants.HeroContentPart.SignUpForm) as IHeroSignUp
  const heroCTAData = getContentPartData(Constants.HeroContentPart.CTA) as IHeroCTA
  const emailConfirmationData = getContentPartData(Constants.HeroContentPart.Email) as IEmailConfirmation
  const testimonialData = getContentPartData(Constants.HeroContentPart.Testimonial) as IHeroTestimonial
  const bodyAdditionalData = getContentPartData(Constants.HeroContentPart.AdditionalText) as IHeroBodyAdditionalText
  const mainData = getContentPartData(Constants.HeroContentPart.Main) as IHeroMain
  const businessSearchResultData = getContentPartData(Constants.HeroContentPart.BusinessSearchResult) as IHeroBusinessSearchResult
  const backgroundMediaData = getContentPartData(Constants.HeroContentPart.BackgroundMedia) as IBackgroundMedia

  const onSubmitWidget = (payload: HeroWidgetInteractionResultPayload): void => {
    if (widgetType === Constants.Feature.Sites) {
      dispatch({ type: 'setIsSignUpFormVisible', payload: true })
    } else {
      dispatch({ type: 'setWidgetPayload', payload: { ...payload, ...emailConfirmationData, ...widgetData } })
    }

    setTimeout(() => {
      const heroNode = document.getElementById('hero')
      if (heroNode != null) {
        heroNode.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }
    }, 50)
  }

  const onSubmitCta = (phoneNumber: number): void => {
    dispatch({ type: 'setIsWidgetInteractionResultVisible', payload: true })
    dispatch({ type: 'setPhoneNumber', payload: phoneNumber })

    const message = getSmsPayload(widgetType)({
      featureContentData,
      heroCTAData,
      widgetPayload: state.widgetPayload
    })
    analytics?.track(
      'mkt_submit_phone_number',
      {
        sectionData: data,
        phone: phoneNumber.toString(),
        sectionTitle: contentData?.title
      }
    )
    sendSMS({
      contact_phone: phoneNumber != null ? phoneNumber.toString() : '',
      message
    }).catch(() => console.error('A problem occured while sending an SMS to phone number ', phoneNumber))

    saveHeroCTAForm({ phone: phoneNumber.toString() })
      .catch(() => { })
  }

  const signupPageRoute = (signupPayload?: IHeroSignUpPayload | null): void => {
    let _redirectUrl = 'https://cloud.gosite.com/signup'

    _redirectUrl = `${_redirectUrl}?lead_source=${window.location.href}`
    if (signupPayload != null) {
      if (signupPayload.email != null) {
        _redirectUrl = `${_redirectUrl}&lead_email=${signupPayload.email}`
      }
      _redirectUrl = `${_redirectUrl}&info=${Base64.encode(JSON.stringify({ ...signupPayload }))}`
    }

    router.push(_redirectUrl).catch(() => { })
  }

  const onSubmitEmail = (email: string): void => {
    analytics?.track('mkt_submit_email', {
      sectionData: data,
      sectionTitle: contentData?.title,
      userEmail: email
    })
    saveHeroSignUpForm({ email })
      .then((response: { ok: boolean }) => {
        if (response?.ok) {
          localStorageMostRecentEmail[1](email)
        }
      })
      .finally(() => {
        const signupPayload = getSignUpPayload(widgetType)({
          email: email ?? '',
          phoneNumber: state?.phoneNumber,
          widgetData,
          widgetPayload: state.widgetPayload
        })

        signupPageRoute(signupPayload)
      })
  }

  const onBlurEmail = (): void => {
    analytics?.track('mkt_enter_email', {
      sectionData: data,
      sectionTitle: contentData?.title
    })
  }

  const onClickHeroBodyCtaButton = (): void => {
    analytics?.track('mkt_click_biz_exp', {
      sectionData: data,
      actionTitle: contentData?.ctaText,
      sectionTitle: contentData?.title
    })
    dispatch({ type: 'setIsSignUpFormVisible', payload: true })
  }

  useEffect(() => {
    if (state.isSignUpFormVisible) {
      heroSignUpForm.current = document.querySelector('#heroSignUpForm')
    }
  }, [state.isSignUpFormVisible])

  return {
    state,
    dispatch,
    parentData: data,
    heroType: data.hero.heroType,
    styles: data?.hero?.styles,
    contentData,
    backgroundMediaData,
    widgetData,
    widgetType,
    featureContentData,
    mainData,
    businessSearchResultData,
    signUpFormData,
    heroCTAData,
    emailConfirmationData,
    testimonialData,
    bodyAdditionalData,
    onSubmitWidget,
    onSubmitCta,
    onSubmitEmail,
    onBlurEmail,
    onClickHeroBodyCtaButton,
    signupPageRoute
  }
}

const reducer = (state: HeroState, action: ReducerAction): HeroState => {
  switch (action.type) {
    case 'setIsWidgetVisible':
      return {
        ...state,
        isWidgetVisible: action.payload,
        isWidgetInteractionResultVisible: false
      }
    case 'setIsWidgetInteractionResultVisible':
      return {
        ...state,
        isWidgetVisible: false,
        isCallToActionVisible: false,
        isWidgetInteractionResultVisible: action.payload
      }
    case 'setIsSignUpFormVisible':
      return {
        ...state,
        isWidgetVisible: false,
        isCallToActionVisible: false,
        isWidgetInteractionResultVisible: false,
        isSignUpFormVisible: action.payload
      }
    case 'setWidgetPayload':
      return {
        ...state,
        widgetPayload: action.payload,
        isCallToActionVisible: true
      }
    case 'setPhoneNumber':
      return {
        ...state,
        phoneNumber: action.payload
      }
    case 'setEmail':
      return {
        ...state,
        email: action.payload
      }
    case 'setIsBusinessSearchResultVisible':
      return {
        ...state,
        isBusinessSearchResultVisible: true
      }
    default:
      throw new Error()
  }
}

type ReducerAction =
  | { type: 'setIsWidgetVisible', payload: boolean }
  | { type: 'setIsWidgetInteractionResultVisible', payload: boolean }
  | { type: 'setIsSignUpFormVisible', payload: boolean }
  | { type: 'setWidgetPayload', payload: HeroWidgetInteractionResultPayload }
  | { type: 'setPhoneNumber', payload: number }
  | { type: 'setEmail', payload: string }
  | { type: 'setIsBusinessSearchResultVisible', payload: boolean }

export interface HeroState {
  isWidgetVisible: boolean
  isCallToActionVisible: boolean
  isWidgetInteractionResultVisible: boolean
  isSignUpFormVisible: boolean
  widgetPayload: HeroWidgetInteractionResultPayload
  phoneNumber: number | null
  email: string
  isBusinessSearchResultVisible: boolean
}

export interface UseHeroProps {
  data: IHero
}

export interface UseHero {
  state: HeroState
  dispatch: React.Dispatch<ReducerAction>
  parentData: IHero | null
  heroType: HeroType | null
  styles: SxProps
  widgetData: IHeroWidget | null
  widgetType: Constants.Feature
  contentData: IHeroContent | IHeroFeatureContent
  backgroundMediaData: IBackgroundMedia | null
  featureContentData: IHeroFeatureContent | null
  mainData: IHeroMain | null
  businessSearchResultData: IHeroBusinessSearchResult | null
  signUpFormData: IHeroSignUp | null
  heroCTAData: IHeroCTA | null
  emailConfirmationData: IEmailConfirmation | null
  testimonialData: IHeroTestimonial | null
  bodyAdditionalData: IHeroBodyAdditionalText | null
  onSubmitWidget: (payload: HeroWidgetInteractionResultPayload) => void
  onSubmitCta: (phoneNumber: number) => void
  onSubmitEmail: (email: string) => void
  onBlurEmail: () => void
  onClickHeroBodyCtaButton: () => void
  signupPageRoute: (signupPayload?: IHeroSignUpPayload | null) => void
}
