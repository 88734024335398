import React from 'react'
import {
  Box,
  Media,
  SxProps,
  styled,
  useMediaQuery,
  useTheme
} from '@gositeinc/ui'

import {
  IMedia,
  IHeroMediaWidget
} from '../types'
import { heroClasses } from '../classes'

export const HeroMediaWidget = ({ data }: HeroMediaWidgetProps): JSX.Element => {
  const theme = useTheme()
  const isBiggerScreen = useMediaQuery(theme.breakpoints.up('md'))

  const {
    foregroundMedia,
    smallScreenForegroundMedia
  } = data

  const renderMediaList = (mediaList: Array<{
    id: number
    styles: SxProps
    media: IMedia
  }>): JSX.Element => {
    if (mediaList?.length > 0) {
      return (
        <>
          {mediaList?.map((item) => (
            <Box sx={item.styles} key={item.id} className={heroClasses.foregroundMedia}>
              <Media media={item.media} />
            </Box>
          ))}
        </>
      )
    }
    return <></>
  }

  if (isBiggerScreen) {
    return renderMediaList(foregroundMedia)
  }

  return (
    <Root className='GoSiteHero-media'>
      {renderMediaList(smallScreenForegroundMedia)}
    </Root>
  )
}

const Root = styled(Box)(({ theme }) => ({
}))

export interface HeroMediaWidgetProps {
  data: IHeroMediaWidget
}
