import React from 'react'
import { useRouter } from 'next/router'
import { StickyContainer, Sticky } from 'react-sticky'
import capitalize from 'lodash/capitalize'
import {
  AudioTestimonialGroup,
  AudioTestimonialProps,
  FormSection,
  FormSectionProps,
  FeatureColumnsGroup1,
  FeatureColumnsGroup1Props,
  FeatureOverview,
  FeatureOverviewProps,
  ArticleBody,
  ArticleBodyProps,
  ArticleColumnsGroup,
  ArticleColumnsGroupProps,
  MediaCarousel,
  MediaCarouselProps
} from '@gositeinc/ui'

import Hero from './hero'
import HeroMain from './hero-main'
import { FeatureRow, FeatureRowProps } from './feature-row'
import Testimonial, { TestimonialProps } from './testimonial'
import Testimonial2, { Testimonial2Props } from './testimonial2'
import { Testimonial3, Testimonial3Props } from './testimonial3'
import TestimonialColumnsGroup, { TestimonialColumnsGroupProps } from './testimonial-columns-group'
import DownloadMobileApp from './download-mobile-app'
import ProductCta from './product-cta'
import { TabViewSection, TabViewSectionProps } from './tab-view-section'
import { ReviewsGroup } from './reviews-group'
import { TestimonialTabsGroup, TestimonialTabsGroupProps } from './testimonial-tabs-group'
import {
  HeroProps,
  ISection,
  HeroMainProps,
  DownloadMobileAppProps,
  ProductCtaProps
} from '../types'
import * as Constants from '../constants'
import { AccordianGroup } from './accordian-group'
import { Pricing, PricingProps } from './pricing'
import { ANALYTICS_CONSTANTS } from 'src/analytics-constants'

// Display a section individually
const PageSection = ({ data }: SectionProps): JSX.Element => {
  if (data.__component === null || data.__component === '') return <></>
  // Prepare the component
  const SectionComponent: React.ElementType = sectionComponents[data.__component]
  // Display the section
  return <SectionComponent data={data} analytics={ANALYTICS_CONSTANTS[data.__component]} />
}

// Display the list of sections
const Sections = ({ sections }: SectionsProps): JSX.Element => {
  // const [analyticsProperties, setAnalyticsProperties] = useState({})
  // const [isSeeMore, setIsSeeMore] = useState(false)
  const router = useRouter()

  const renderSection = (section: ISection): JSX.Element => {
    const _sectionId = getSectionId(section)

    return (
      <section
        id={_sectionId}
        key={_sectionId}
      >
        <StickyContainer>
          {router.asPath.includes('kitchen') && (
            <Sticky>
              {({ style }) => (
                <div style={style}>
                  <div style={Constants.STICKY_TAG_CONTENT_STYLES}>
                    <div style={{ fontSize: 16, fontWeight: 700 }}>
                      {capitalize(section.__component.split('.')[1].split('-').join(' '))}
                    </div>
                    <div style={{ fontSize: 12 }}>{section.metaTitle}</div>
                  </div>
                </div>
              )}
            </Sticky>
          )}
          <PageSection
            data={section}
            key={`${section.__component}${section.id}`}
          />
        </StickyContainer>
      </section>
    )
  }

  return (
    <>
      {/* Show the actual sections */}
      {sections?.map(renderSection)}
    </>
  )
}

export const getSectionId = (section: ISection): string => `${section.__component}-${section.id}`

export const heroSectionComponents: {
  [key: string]:
  | ((props: HeroProps) => JSX.Element)
  | ((props: HeroMainProps) => JSX.Element)
} = {
  'sections.hero': Hero,
  'sections.hero-main': HeroMain,
  'sections.hero-booking': Hero,
  'sections.hero-messenger': Hero,
  'sections.hero-review': Hero,
  'sections.hero-invoice': Hero
}

// Map Strapi sections to section components
export const sectionComponents: {
  [key: string]:
  | ((props: AudioTestimonialProps) => JSX.Element)
  | ((props: HeroProps) => JSX.Element)
  | ((props: FeatureColumnsGroup1Props) => JSX.Element)
  | ((props: FeatureOverviewProps) => JSX.Element)
  | ((props: FeatureRowProps) => JSX.Element)
  | ((props: FormSectionProps) => JSX.Element)
  | ((props: TestimonialProps) => JSX.Element)
  | ((props: Testimonial2Props) => JSX.Element)
  | ((props: Testimonial3Props) => JSX.Element)
  | ((props: TestimonialColumnsGroupProps) => JSX.Element)
  | ((props: DownloadMobileAppProps) => JSX.Element)
  | ((props: ProductCtaProps) => JSX.Element)
  | ((props: TabViewSectionProps) => JSX.Element)
  | ((props: TestimonialTabsGroupProps) => JSX.Element)
  | ((props: PricingProps) => JSX.Element)
  | ((props: ArticleBodyProps) => JSX.Element)
  | ((props: ArticleColumnsGroupProps) => JSX.Element)
  | ((props: MediaCarouselProps) => JSX.Element)
} = {
  ...heroSectionComponents,
  'sections.audio-testimonial-group': AudioTestimonialGroup,
  'sections.feature-columns-group': FeatureColumnsGroup1,
  'sections.feature-overview1': FeatureOverview,
  'sections.feature-overview2': FeatureOverview,
  'sections.feature-row1': FeatureRow,
  'sections.feature-row2': FeatureRow,
  'sections.form-section': FormSection,
  'sections.pricing': Pricing,
  'sections.testimonial': Testimonial,
  'sections.testimonial-2': Testimonial2,
  'sections.testimonial-3': Testimonial3,
  'sections.testimonial-columns-group': TestimonialColumnsGroup,
  'sections.download-mobile-app': DownloadMobileApp,
  'sections.product-cta': ProductCta,
  'sections.tab-view-section': TabViewSection,
  'sections.reviews-group': ReviewsGroup,
  'sections.testimonial-tabs-group': TestimonialTabsGroup,
  'sections.accordian-group': AccordianGroup,
  'sections.article': ArticleBody,
  'sections.article-columns-group': ArticleColumnsGroup,
  'sections.media-carousel': MediaCarousel
}

interface SectionProps {
  data: ISection
}

interface SectionsProps {
  sections: ISection[]
}

export default Sections
