import React from 'react'
import { Avatar, Box, Button, Grid, Text, useTheme, styled, Media } from '@gositeinc/ui'

import EmailConfirmation from './email-confirmation'
import { IHeroPaymentWidget, IPaymentWidgetPayload } from 'src/types'

const PaymentConfirmationWidget = ({ widgetData, widgetPayload }: PaymentConfirmationWidgetProps): JSX.Element => {
  const theme = useTheme()
  const {
    confirmationIconBackgroundColor,
    confirmationNameColor,
    daysUntilDue,
    confirmButtonText,
    confirmDueOnPrefixText
  } = widgetData
  const {
    senderAvatarColor,
    senderAvatarIcon,
    senderName,
    receiverName,
    emailTitle,
    amount,
    selectedService
  } = widgetPayload

  const serviceAmount = amount ?? selectedService?.price ?? 0

  const _senderAvatarColor = (senderAvatarColor === undefined || senderAvatarColor === null || senderAvatarColor === '')
    ? theme.palette.primary.main
    : senderAvatarColor

  const dueDate = new Date()
  dueDate.setDate(dueDate.getDate() + daysUntilDue)
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ]
  const dateString = `${monthNames[dueDate.getMonth()]} ${dueDate.getDate()}, ${dueDate.getFullYear()}`

  return (
    <EmailConfirmation
      senderAvatarColor={_senderAvatarColor}
      senderAvatarIcon={senderAvatarIcon}
      senderName={senderName}
      receiverName={receiverName}
      title={emailTitle}
      body={
        <EmailBodyContent>
          <Grid container>
            <Grid item container xs={12} justifyContent='center' alignItems='center' spacing={1} flexWrap='nowrap'>
              <Grid item>
                <Avatar sx={{ backgroundColor: confirmationIconBackgroundColor, width: 24, height: 24 }}>
                  <Media width={12} height={12} media={senderAvatarIcon} />
                </Avatar>
              </Grid>
              <Grid item>
                <Text variant='caption' sx={{ color: confirmationNameColor, fontWeight: 500, fontSize: 10 }}>{senderName}</Text>
              </Grid>
            </Grid>

            <Grid item container xs={12} justifyContent='center' sx={{ marginTop: 3 }}>
              <Text variant='h5'>${serviceAmount.toFixed(2)}</Text>
            </Grid>

            <Grid item container xs={12} justifyContent='center' sx={{ marginTop: 1, color: '#8290A1' }}>
              <Text variant='caption'>{confirmDueOnPrefixText}{' '}{dateString}</Text>
            </Grid>

            <Grid item container xs={12} justifyContent='center' sx={{ marginTop: 2 }}>
              <Button variant='contained' sx={{ fontSize: 10 }}>{confirmButtonText}</Button>
            </Grid>
          </Grid>
        </EmailBodyContent>
      }
    />
  )
}

export const EmailBodyContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1)
}))

interface PaymentConfirmationWidgetProps {
  widgetData: IHeroPaymentWidget
  widgetPayload: IPaymentWidgetPayload
}

export default PaymentConfirmationWidget
