import React from 'react'
import {
  Button,
  Box,
  BoxProps,
  Container,
  Grid,
  Link,
  Rating,
  Text,
  useTheme,
  styled,
  Media
} from '@gositeinc/ui'

import { IButtonLink, ITestimonialTabItem } from '../types'
import { testimonialTabItemClasses } from '../classes'

export const TestimonialTabItem = ({
  data,
  HeaderComponent,
  BgImageComponent,
  ...boxProps
}: TestimonialTabItemProps): JSX.Element => {
  const theme = useTheme()

  const {
    companyLogo,
    media,
    buttonLink,
    content: {
      stars,
      quote,
      author,
      company
    },
    styles
  } = data

  const renderButton = (link: IButtonLink): JSX.Element => {
    return (
      <Link
        link={link}
      >
        <Button
          variant='text'
          color='primary'
          size='small'
          sx={{
            textTransform: 'none',
            fontWeight: theme.typography.fontWeightRegular,
            justifyContent: 'center',
            alignItems: 'center',
            textDecoration: 'underline',
            [theme.breakpoints.up('md')]: {
              paddingLeft: 0
            }
          }}
        >
          {link?.text}
        </Button>

      </Link>
    )
  }
  return (
    <Root
      sx={styles}
      {...boxProps}
    >
      <Container maxWidth='md'>
        {HeaderComponent}
        <Grid container alignItems='center' mt={0} rowSpacing={10}>
          <Grid
            item
            md={6}
            className={testimonialTabItemClasses.content}
          >
            <Box className={testimonialTabItemClasses.media}>
              <Media
                className={testimonialTabItemClasses.companyLogo}
                media={companyLogo}
                rootStyle={{
                  justifyContent: 'initial'
                }}
                mediaStyle={{
                  width: 'auto'
                }}
              />
            </Box>
            <Box mt={2}>
              <Rating
                readOnly
                value={stars}
                size='medium'
                sx={{ color: theme.palette.primary.main }}
              />
            </Box>
            <Text
              rich
              className={testimonialTabItemClasses.quote}
              my={4}
              textAlign='left'
            >
              {quote}
            </Text>
            {buttonLink !== undefined && renderButton(buttonLink)}
            <Text
              rich
              className={testimonialTabItemClasses.author}
            >
              {author}
            </Text>
            <Text
              rich
              className={testimonialTabItemClasses.company}
              color='text.secondary'
            >
              {company}
            </Text>
          </Grid>
          <Grid item xs={12} md={6} display='flex' justifyContent='center'>
            <Box className={testimonialTabItemClasses.media}>
              <Media media={media} objectFit='contain' />
            </Box>
          </Grid>
        </Grid>
      </Container>
      {BgImageComponent}
    </Root>
  )
}

const Root = styled(Box)(({ theme }) => ({
  [`.${testimonialTabItemClasses.quote} p`]: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.6
  },
  [`.${testimonialTabItemClasses.quote} h2`]: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.6
  },
  [`.${testimonialTabItemClasses.author} p`]: {
    fontWeight: theme.typography.fontWeightRegular
  },
  [`.${testimonialTabItemClasses.company} p`]: {
    ...theme.typography.body2,
    fontWeight: theme.typography.fontWeightMedium
  },
  position: 'relative',
  paddingTop: theme.spacing(10),
  paddingBottom: 0,
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    textAlign: 'left'
  }
}))

export type TestimonialTabItemProps = {
  data: ITestimonialTabItem
  HeaderComponent?: JSX.Element
  BgImageComponent?: JSX.Element
} & BoxProps
