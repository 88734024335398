import { projectName } from './constants'

interface IComponentClassNamesObject {
  [name: string]: string
}

export const getComponentClassNamesObject = (componentName: string, ...args: string[]): IComponentClassNamesObject => {
  let classNameObject = {}
  args.forEach(ar => {
    classNameObject = {
      ...classNameObject,
      [ar]: `${projectName}${componentName}-${ar}`
    }
  })
  return classNameObject
}

export const heroMainClasses = getComponentClassNamesObject('HeroMain', 'title', 'description', 'foregroundMedia')

export const heroBusinessClasses = getComponentClassNamesObject('HeroBusinessSearchResult', 'title', 'description', 'notes')

export const headerClasses = getComponentClassNamesObject('Header', 'root', 'logo', 'navbarLinks', 'buttonLinks', 'buttonLink')

export const heroClasses = getComponentClassNamesObject('Hero', 'title', 'description', 'foregroundMedia', 'mediaWidget')

export const featureColumnClasses = getComponentClassNamesObject('FeatureColumn', 'root', 'title', 'description')

export const featureRowBodyClasses = getComponentClassNamesObject('FeatureRowBody', 'root', 'title', 'description', 'media', 'content', 'valuePropositionTitle', 'valuePropositionDescription')

export const productCTAClasses = getComponentClassNamesObject('ProductCTA', 'root', 'title', 'subtitle', 'description', 'media', 'content')

export const testimonial3Classes = getComponentClassNamesObject('Testimonial3', 'root', 'topBannerText')

export const testimonialTabItemClasses = getComponentClassNamesObject('TestimonialTabItem', 'root', 'content', 'media', 'quote', 'company', 'companyLogo', 'author')

export const downloadMobileAppClasses = getComponentClassNamesObject('DownloadMobileApp', 'root', 'agreementText', 'media', 'title')

export const featureOverviewClasses = getComponentClassNamesObject('FeatureOverview', 'root', 'title', 'description')

export const testimonialColumnsGroupClasses = getComponentClassNamesObject('TestimonialColumnsGroup', 'root', 'title', 'description', 'quote', 'company', 'companyLogo', 'author')

export const testimonial2Classes = getComponentClassNamesObject('Testimonial2', 'root', 'content', 'quote', 'company', 'author')

export const heroCTAClasses = getComponentClassNamesObject('HeroCTA', 'root', 'title')

export const engagementEmailBodyClasses = getComponentClassNamesObject('EngagementEmailBody', 'root', 'title', 'description', 'ctaText', 'businessName')

export const messengerEmailConfirmationClasses = getComponentClassNamesObject('MessengerEmailConfirmation', 'root', 'emailContent')

export const reviewEmailConfirmationClasses = getComponentClassNamesObject('ReviewEmailConfirmation', 'root', 'emailContent')

export const tabPanelItemClasses = getComponentClassNamesObject('TabPanelItem', 'root', 'title', 'content', 'dotIndicators')

export const tabPanelViewClasses = getComponentClassNamesObject('TabPanelView', 'root', 'title', 'header')

export const articleClasses = getComponentClassNamesObject('Article', 'root', 'title', 'description', 'content')

export const testimonialTabsGroup = getComponentClassNamesObject('TestimonialTabsGroup', 'root', 'title', 'description', 'content')

export const pricingClasses = getComponentClassNamesObject('PricingClasses', 'root', 'title', 'description', 'content')

export const blogNavBarClasses = getComponentClassNamesObject('BlogNavBar', 'root', 'progressBar', 'socialShare', 'newsLetter')

export const subpageClasses = getComponentClassNamesObject('Subpage', 'root', 'tabRoot', 'tabContent')
