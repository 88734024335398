import React from 'react'
import router from 'next/router'
import { motion } from 'framer-motion'
import {
  Box,
  BoxProps,
  Button,
  colors,
  containerClasses,
  Divider,
  Grid,
  IconButton,
  inputClasses,
  Media,
  Link,
  Stack,
  Text,
  TextField,
  typographyClasses,
  styled,
  useTheme,
  useMediaQuery,
  useButton
} from '@gositeinc/ui'

import { env } from '../utils'
import {
  HeroFormWidget
} from './hero-form-widget'
import {
  IHeroContent,
  IHeroFormWidget,
  IMedia,
  IImageLink
} from '../types'
import * as Assets from '../assets'
import { heroClasses } from '../classes'
import { ANALYTICS_CONSTANTS } from 'src/analytics-constants'

const transition = { duration: 1, ease: 'easeInOut', delay: 4 }

export const HeroContent = ({ data, analytics, ...boxProps }: HeroContentProps): JSX.Element => {
  const emulateOldSiteUI = env.emulateOldSiteUI
  const theme = useTheme()
  const { getVariant } = useButton()
  const isBiggerScreen = useMediaQuery(theme.breakpoints.up('md'))
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const isLargerThanSmallScreen = useMediaQuery(theme.breakpoints.up('sm'))

  const {
    badges,
    buttonLinks,
    ctaText,
    ctaType,
    ctaUrl,
    description,
    form,
    labelIcon,
    labelText,
    storeLinks,
    styles,
    title
  } = data

  const getTextTitleAttributes = (): object => {
    if (emulateOldSiteUI) {
      return {
        fontSize: { md: theme.spacing(6), xs: theme.spacing(4.5) },
        lineHeight: theme.spacing(6.5)
      }
    }

    return {
      fontSize: { md: theme.spacing(7.50), xs: theme.spacing(4.5) },
      lineHeight: { md: theme.spacing(8), xs: theme.spacing(5.4) }
    }
  }

  const getTextDescriptionAttributes = (): object => {
    if (emulateOldSiteUI) {
      return {
        fontWeight: theme.typography.fontWeightMedium,
        lineHeight: '140%',
        mt: 3
      }
    }

    return {
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: theme.spacing(3.5),
      py: 1.25
    }
  }

  const getHeroTheme = (): string => {
    switch (data?.theme) {
      case 'inverted':
        return theme.palette.common.white
      default:
        return theme.palette.text.primary
    }
  }

  // const trackAction = (): void => {
  //   analytics?.track('mkt_start_24d_free_trial', {
  //     event: 'Start free trial'
  //   })
  // }

  const handleCtaLinkClick = (): void => {
    // eslint-disable-next-line
    router.push(`${ctaUrl}${window.location.search !== '' ? window.location.search : ''}`).catch(() => { })
  }

  const renderDivider = (index: number): JSX.Element => {
    if (isBiggerScreen && index + 1 < badges.length) {
      return (
        <Divider
          orientation='vertical'
          sx={{
            borderColor: theme.palette.text.secondary,
            height: theme.spacing(4),
            marginX: theme.spacing(3)
          }}
        />
      )
    }

    return <></>
  }

  const renderBadges = (): JSX.Element => {
    if (badges?.length === 0) {
      return <></>
    }

    return (
      <Grid
        container
        alignItems={isBiggerScreen ? 'center' : 'flex-start'}
        flexDirection={isBiggerScreen ? 'row' : 'column'}
        rowSpacing={isBiggerScreen ? 0 : 2}
        pb={4.5}
      >
        {badges?.length > 0 && badges?.map((link: IMedia, index: number) => (
          <React.Fragment key={index}>
            <Grid item>
              <Media key={index} media={link} />
            </Grid>
            {renderDivider(index)}
          </React.Fragment>
        ))}
      </Grid>
    )
  }

  const renderLinks = (): JSX.Element => {
    return (
      <Grid
        className='GositeHeroContent-buttonLinks'
        container
        alignItems='center'
        spacing={4}
      >
        <Grid item xs={isLargerThanSmallScreen ? undefined : 12}>
          <Grid container spacing={2} alignItems='center'>
            {buttonLinks?.length > 0 && buttonLinks?.map((button, index) => (
              <Grid item key={button.id} xs={isLargerThanSmallScreen ? undefined : 12}>
                <Link
                  link={button}
                  data-analytics={analytics?.button}
                  data-analytics-event={button.text}
                >
                  <Button
                    variant={getVariant(button.variant)}
                    fullWidth={!isBiggerScreen}
                    size='large'
                    sx={{ justifyContent: 'space-between' }}
                    endIcon={(
                      <Assets.RightArrow
                        pathColor={
                          getVariant(button.variant) === 'contained'
                            ? theme.palette.common.white
                            : theme.palette.primary.main
                        }
                      />
                    )}
                  >
                    {button.text}
                  </Button>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2} alignItems='center'>
            {storeLinks?.length > 0 && storeLinks?.map((link: IImageLink) => (
              <Grid item display='flex' alignItems='center' key={link.id}>
                <Link
                  link={link}
                  data-analytics={link?.url?.includes('apple') ? analytics?.storeLinkApple : analytics?.storeLinkGoogle}
                  data-analytics-section-title={title}
                  data-analytics-action-title={link?.url?.includes('apple') ? 'apple' : 'google'}
                >
                  <Media media={link.media} />
                </Link>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const renderButton = (): JSX.Element => {
    return (
      <IconButton
        id='GoSiteHeroContent-ctaButton'
        sx={{
          background: colors.grey[100],
          height: 40,
          width: 40,
          ml: isSmallerScreen ? 1.5 : 5
        }}
      >
        <Assets.RightArrow pathColor={theme.palette.primary.main} />
      </IconButton>
    )
  }

  const renderCta = (): JSX.Element => {
    if (ctaType === 'link') {
      return (
        <CtaLink onClick={handleCtaLinkClick}>
          <Stack direction='row' columnGap={3} alignItems='center'>
            <Text
              variant='h5'
              fontWeight={theme.typography.fontWeightSemiBold}
              py={1.25}
            >
              {ctaText}
            </Text>
            {renderButton()}
          </Stack>
          {renderSwoosh()}
        </CtaLink>
      )
    } else if (ctaType === 'input') {
      return (
        <>
          <Stack direction='row' alignItems='center' mt={isLargerThanSmallScreen ? 5 : 0}>
            <Text
              id='GoSiteHeroContent-ctaText' /** Added a new ctaText id for text animation before textfield */
              sx={{
                color: '#fff',
                fontSize: '24px',
                fontWeight: theme.typography.fontWeightBold,
                width: 'auto'
              }}
            >
              {ctaText}
            </Text>
            <TextField
              autoFocus
              id='GoSiteHeroContent-ctaInput'
              variant='standard'
              placeholder={ctaText}
              InputProps={{
                disableUnderline: true
              }}
              sx={{
                [`& .${inputClasses.root}`]: {
                  color: getHeroTheme(),
                  fontSize: '24px',
                  fontWeight: theme.typography.fontWeightBold,
                  width: 'auto'
                }
              }}
            />
            {renderButton()}
          </Stack>
          {renderSwoosh()}
        </>
      )
    }

    return <></>
  }

  const renderForm = (): JSX.Element => {
    if (form == null) {
      return <></>
    }

    return (
      <HeroFormWidget
        data={{ form } as unknown as IHeroFormWidget}
        contentData={data}
      />
    )
  }

  const renderSwoosh = (): JSX.Element => {
    if (ctaText != null && ctaText !== '') {
      return (
        <motion.svg
          id='ctaSwooshImage'
          xmlns='http://www.w3.org/2000/svg'
          width='298'
          height='16'
          fill='none'
          viewBox='0 0 298 16'
        >
          <motion.path
            stroke='#0357FF'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='3'
            d='M2 14.4067C46.4444 7.36612 175.333 -3.45931 326 6.29752'
            initial={{ pathLength: 0, display: 'none' }}
            animate={{ pathLength: 1, display: 'block' }}
            transition={transition}
          />
        </motion.svg>
      )
    }
    return <></>
  }

  return (
    <Root
      {...boxProps}
      sx={styles}
      className='GoSiteHero-content'
    >
      <Label mb={2}>
        {labelIcon != null && (
          <Box width={22} pt={0.625}>
            <Media media={labelIcon} />
          </Box>
        )}
        {labelText !== '' && (
          <Text
            variant='h6'
            fontSize={theme.typography.heading.fontSize}
            color='primary'
            sx={{ textTransform: 'uppercase' }}
          >
            {labelText}
          </Text>
        )}
      </Label>
      {renderBadges()}
      <Text
        className={heroClasses.title}
        rich
        gutterBottom
        color={getHeroTheme()}
        {...getTextTitleAttributes()}
      >
        {title}
      </Text>
      <Text
        className={heroClasses.description}
        rich
        variant='inherit'
        color={getHeroTheme()}
        {...getTextDescriptionAttributes()}
      >
        {description}
      </Text>
      {renderCta()}
      {renderLinks()}
      {renderForm()}
    </Root>
  )
}

const Root = styled(Box)(({ theme }) => ({
  position: 'relative',
  minHeight: 720,
  padding: env.emulateOldSiteUI ? theme.spacing(8, 0) : theme.spacing(12, 0),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(20)
  },
  [`& > .${String(containerClasses.root)}`]: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: env.emulateOldSiteUI ? theme.spacing(6) : 0,
      paddingRight: env.emulateOldSiteUI ? theme.spacing(6) : 0
    }
  }
}))

const CtaLink = styled(Link)(({ theme }) => ({
  display: 'inline-block',
  marginTop: theme.spacing(5),
  [`& .${String(typographyClasses.root)}`]: {
    color: theme.palette.text.primary
  }
}))

const Label = styled(Box)(({ theme }) => ({
  alignItems: 'start',
  display: 'flex',
  gap: theme.spacing(1.25),
  justifyContent: 'start'
}))

export type HeroContentProps = {
  data: IHeroContent
  analytics: typeof ANALYTICS_CONSTANTS['elements.hero-content']
} & BoxProps
