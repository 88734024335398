import { Box, Text, styled } from '@gositeinc/ui'

import { engagementEmailBodyClasses } from '../classes'

export const EngagementEmailBody = ({ title, description, linkText, titleTextVariant, descriptionTextVariant }: EngagementEmailBodyProps): JSX.Element => {
  return (
    <Root textAlign='center'>
      <Text
        rich
        className={engagementEmailBodyClasses.title}
        mt={2}
        gutterBottom
      >
        {title}
      </Text>
      <Text
        rich
        className={engagementEmailBodyClasses.description}
        gutterBottom
      >
        {description}
      </Text>
      <Text
        rich
        className={engagementEmailBodyClasses.ctaText}
        sx={{ textDecoration: 'underline' }}
        gutterBottom
      >
        {linkText}
      </Text>
    </Root>
  )
}

const Root = styled(Box)(({ theme }) => ({
  [`.${engagementEmailBodyClasses.title} p`]: {
    ...theme.typography.caption,
    fontWeight: theme.typography.fontWeightBold
  },
  [`.${engagementEmailBodyClasses.title} h2`]: {
    ...theme.typography.caption,
    fontWeight: theme.typography.fontWeightBold
  },
  [`.${engagementEmailBodyClasses.description} p`]: {
    ...theme.typography.caption3,
    fontWeight: theme.typography.fontWeightMedium
  },
  [`.${engagementEmailBodyClasses.ctaText} p`]: {
    ...theme.typography.subtitle2,
    fontWeight: theme.typography.fontWeightBold
  }
}))

export interface EngagementEmailBodyProps {
  title: string
  description: string
  linkText: string
  descriptionTextVariant: string
  titleTextVariant: string
}
