import React, { useState } from 'react'
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Media,
  Phone,
  Rating,
  styled,
  Text,
  Theme,
  useTheme
} from '@gositeinc/ui'

import { HeroWidgetModifierStyle } from './hero-widget'
import { IHeroReviewWidget } from '../types'

const ReviewWidget = ({ data, onDone }: ReviewWidgetProps): JSX.Element => {
  const { title, subtitle, defaultStars, minStars, media } = data
  // const readOnlyStars = minStars - 1
  const selectableStars = 5
  const theme = useTheme()
  const [stars, setStars] = useState(Math.max(defaultStars, minStars))

  return (
    <Root id='Root'>

      <Card sx={{ width: `calc(300px - ${theme.spacing(8)})`, zIndex: 1 }}>
        <ReviewPhone elevation={0} />
        <CardContent>
          <Grid
            container
            spacing={0}
            direction='column'
            alignItems='center'
            justifyContent='center'
          >
            <Avatar alt='avatar' sx={{ width: 60, height: 60 }}>
              <Media
                media={media}
              />
            </Avatar>
          </Grid>
          <Text variant='body2' sx={{ marginTop: theme.spacing(2), fontWeight: '700', textAlign: 'center' }}>{title}</Text>
          <Text variant='caption' sx={{ marginTop: theme.spacing(1), display: 'block', textAlign: 'center' }}>{subtitle}</Text>
          <Grid
            container
            spacing={0}
            direction='row'
            alignItems='center'
            justifyContent='center'
            sx={{ marginTop: theme.spacing(1) }}
          >
            <Rating max={minStars} value={minStars} readOnly />
            <Rating
              max={selectableStars}
              value={stars}
              onChange={(event, newValue) => {
                newValue = newValue ?? stars
                setStars(newValue)
                onDone({ reviewStars: newValue + minStars })
              }}
            />
          </Grid>
        </CardContent>
      </Card>

    </Root>
  )
}

const Root: React.ElementType = styled('div')(({ theme }: { theme: Theme }) => ({
  position: 'relative',
  maxWidth: 326,
  [theme.breakpoints.up('sm')]: {
    maxWidth: 560
  },
  '&': {
    background: '#F7F9FB'
  },
  maxHeight: 330,
  padding: theme.spacing(4),
  paddingTop: theme.spacing(8),
  marginTop: theme.spacing(10),
  [theme.breakpoints.down('md')]: {
    marginTop: 0
  }
}))

const ReviewPhone: React.ElementType = styled(Phone)(({ theme }: { theme: Theme }) => ({
  width: 300,
  height: 621,
  color: '#787a7b',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1),
  borderRadius: 35,
  border: '15px solid #f0f4f6',
  boxShadow: 'initial',
  position: 'absolute',
  top: -10,
  left: 0,
  background: 'transparent'
}))

interface ReviewWidgetProps {
  data: IHeroReviewWidget
  onDone: (payload: object) => void
}

export const reviewHeroWidgetModifierStyle: HeroWidgetModifierStyle = {
  showFade: false,
  heroWidgetGridPropsCreator: (theme: Theme) => ({
    md: 6,
    lg: 6,
    sx: {
      [theme.breakpoints.down('md')]: {
        paddingRight: 0
      }
    }
  }),
  heroBodyGridPropsCreator: (theme: Theme) => ({
    md: 6,
    lg: 6,
    sx: {
      [theme.breakpoints.down('md')]: {
        paddingLeft: 0
      }
    }
  })
}

export default ReviewWidget
