import React, { useCallback, useEffect, useState } from 'react'
import {
  Avatar,
  Box,
  Card,
  Chip,
  Divider,
  Image,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Media,
  styled,
  TextField,
  Theme,
  useTheme
} from '@gositeinc/ui'
import { VscCircleFilled } from 'react-icons/vsc'
import { FiPhone } from 'react-icons/fi'

import MessengerWidgetBody, { IChatItem } from './messenger-widget-body'
import { HeroWidgetModifierStyle } from './hero-widget'
import { IHeroWidget, IHeroMessengerWidget } from '../types'
import { useAnalytics } from '../providers'

const MessengerWidget = ({ data, onDone }: MessengerWidgetProps): JSX.Element => {
  const { analytics } = useAnalytics()
  const theme = useTheme()

  const [chatItems, setChatItems] = useState<IChatItem[]>([])
  const [currentOutgoingChat, setCurrentOutgoingChat] = useState<IChatItem | null>()
  const [lastOutgoingMessage, setLastOutgoingMessage] = useState<string>('')

  useEffect(() => {
    if (data.messengerItems.length > 0) {
      const messengerItemsArray = [...data.messengerItems]

      const _currentOutgoingChat: IChatItem | undefined = [...messengerItemsArray]
        .reverse()
        .find(item => item.type === 'outgoing')

      setLastOutgoingMessage(_currentOutgoingChat?.message ?? '')

      const _lastOutgoingChatIndex = data
        .messengerItems
        .findIndex(item => item.id === _currentOutgoingChat?.id ?? '')

      const _chatItems: IChatItem[] = [...messengerItemsArray]
        .slice(0, _lastOutgoingChatIndex)
        .map(item => ({ ...item, isLoading: false }))

      setChatItems(_chatItems)
      setCurrentOutgoingChat(_currentOutgoingChat)
    }
  }, [currentOutgoingChat])

  const onSend = useCallback(() => {
    analytics?.track('mkt_send_demo_message', { sectionData: data })
    setLastOutgoingMessage('')
    const _currentOutgoingChat: any = currentOutgoingChat

    setChatItems(currentChatItems => [
      ...currentChatItems, _currentOutgoingChat
    ])

    const incomingChatIndex = data.messengerItems.findIndex(item => item.id === _currentOutgoingChat.id) + 1
    const incomingChat: IChatItem = data.messengerItems[incomingChatIndex]

    setTimeout(() => {
      setChatItems(currentChatItems => currentChatItems.map(
        item => item.id === _currentOutgoingChat.id
          ? ({ ...item, isLoading: false })
          : item
      ))
      setChatItems(currentChatItems => [
        ...currentChatItems,
        {
          ...incomingChat,
          isLoading: false
        }
      ])
    }, 3000)

    setTimeout(() => {
      setChatItems(currentChatItems => currentChatItems.map(
        item => item.id === _currentOutgoingChat.id
          ? ({ ...item, isLoading: true })
          : item
      ))
    }, 1000)

    setTimeout(() => {
      onDone(data)
    }, 6000)
  }, [chatItems])

  return (
    <>
      <Root elevation={10}>
        <ChatHeader dense>
          <ListItem
            secondaryAction={
              <IconBox>
                <FiPhone
                  color='#A5E342'
                  size={20}
                />
              </IconBox>
            }
            sx={{ padding: theme.spacing(2) }}
          >
            <ListItemAvatar>
              <BusinessIconAvatar>
                <Media
                  media={data.senderAvatar}
                  height={40}
                  width={40}
                />
              </BusinessIconAvatar>
              <VscCircleFilled
                size={20}
                fill='#54AB31'
                style={{
                  position: 'absolute',
                  top: 40,
                  left: 40
                }}
              />
            </ListItemAvatar>
            <ListItemText
              primary={data.senderName}
              secondary={`from ${data.description}`}
            />
          </ListItem>
        </ChatHeader>
        <Divider variant='middle' sx={{ marginX: 0 }} />
        <ChatBody>
          <MessengerWidgetBody data={chatItems} />
        </ChatBody>
        <Divider variant='middle' sx={{ marginX: 0 }} />
        <ChatFooter>
          <SendMessageTextField
            variant='standard'
            disabled
            multiline
            fullWidth
            placeholder='Your Message'
            value={lastOutgoingMessage}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Chip
                    label='SEND'
                    disabled={lastOutgoingMessage === ''}
                    onClick={onSend}
                    sx={{
                      fontWeight: theme.typography.fontWeightBold,
                      backgroundColor: '#C0F06B',
                      width: 71
                    }}
                  />
                </InputAdornment>)
            }}
            id='disabled-text'
          />
        </ChatFooter>
      </Root>
      <MessengerChatButtonContainer>
        <Image src='/images/chat-button.svg' alt='bubble' width='55' height='55' />
      </MessengerChatButtonContainer>
    </>
  )
}

const Root: React.ElementType = styled(Card)(({ theme }) => ({
  borderRadius: 20,
  height: 376,
  display: 'flex',
  flexDirection: 'column'
}))

const MessengerChatButtonContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(3, 0, 3, 'auto'),
  width: '43px',
  height: '43px',
  borderRadius: '50%',
  boxShadow: '0px 10px 14px 1px rgb(0 0 0 / 14%)'

}))

const SendMessageTextField: React.ElementType = styled(TextField)(({ theme }) => ({
  '.MuiInput-root:before': {
    border: 'none',
    borderBottomStyle: 'none !important'
  }
}))

const BusinessIconAvatar: React.ElementType = styled(Avatar)(
  ({ theme, color }: { theme: Theme, color: string }) => ({
    '& svg': {
      fill: color,
      height: 20,
      position: 'relative',
      top: theme.spacing(0.25)
    }
  }))

const IconBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#F7FDE8',
  borderRadius: '50%',
  padding: theme.spacing(1.3, 1.3),
  display: 'flex',
  alignItems: 'center',
  margin: theme.spacing(2)
}))

const ChatHeader = styled(List)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    maxWidth: theme.spacing(40)
  },
  width: theme.spacing(55),
  height: theme.spacing(9),
  padding: theme.spacing(0, 0),
  '& .MuiListItem-root': {
    padding: theme.spacing(0, 0)
  },
  '& .MuiListItemAvatar-root': {
    minWidth: 32
  },
  '& .MuiAvatar-root': {
    width: 40,
    height: 40,
    margin: theme.spacing(2)
  },
  '& .MuiListItemText-root': {
    marginLeft: theme.spacing(2)
  },
  '& .MuiListItemText-primary': {
    fontSize: theme.typography.h4,
    fontWeight: theme.typography.fontWeightSemiBold
  },
  '& .MuiListItemText-secondary': {
    fontSize: theme.typography.caption2
  },
  '& .MuiListItemSecondaryAction-root': {
    right: 0
  },
  '& .MuiListItemSecondaryAction-root .MuiTypography-root': {
    fontSize: theme.typography.pxToRem(7)
  }
}))

export const ChatBody = styled(Box)(({ theme }) => ({
  overflowY: 'scroll',
  display: 'flex',
  flexDirection: 'column-reverse',
  flexGrow: 1
}))

export const ChatFooter = styled(Box)(({ theme }) => ({
  '& textarea:disabled': {
    WebkitTextFillColor: 'black'
  },
  '& .MuiInput-root': {
    padding: theme.spacing(1.8, 1.8)
  }
}))

interface MessengerWidgetProps {
  data: IHeroMessengerWidget
  onDone: (payload: IHeroWidget) => void
}

export const messengerWidgetModifierStyle: HeroWidgetModifierStyle = {
  showFade: true,
  heroWidgetGridPropsCreator: (theme: Theme) => ({
    sx: {
      [theme.breakpoints.down('md')]: {
        paddingRight: 0
      }
    }
  }),
  heroBodyGridPropsCreator: (theme: Theme) => ({
    sx: {
      [theme.breakpoints.down('md')]: {
        paddingLeft: 0
      }
    }
  })
}

export default MessengerWidget
