import { env } from './env'

export const getStrapiMedia = (url: string | undefined): string | null => {
  if (url == null) {
    return null
  }

  // Return the full URL if the media is hosted on an external provider
  if (url.startsWith('http') || url.startsWith('//')) {
    return url
  }

  // Otherwise prepend the URL path with the Strapi URL
  return `${env.strapiDomain}${url}`
}
