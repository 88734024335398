import {
  INavbarLink,
  INavbarDropDown,
  INavbarChildItem
} from '@gositeinc/ui'
import { getLinkWithSearchParams } from './getLinkWithSearchParams'

export const getNavbarLinksInfusedWithSearchParams = (navbarLinks: INavbarLink[]): INavbarLink[] => {
  const _navbarLinks = [...navbarLinks]
  _navbarLinks.forEach((navbarLink: INavbarLink) => {
    if (navbarLink?.columns?.length > 0) {
      navbarLink.columns?.forEach(
        (column: INavbarDropDown) => {
          if (column?.links != null && column?.links?.length > 0) {
            column.links?.forEach((link: INavbarChildItem): void => {
              link.url = getLinkWithSearchParams(link.url)
            })
          }
        }
      )
    }
  })
  return _navbarLinks
}
