import React from 'react'
import { Box, Text, styled, Paper, Rating } from '@gositeinc/ui'

import EmailConfirmation from './email-confirmation'
import { IHeroReviewWidget, IReviewWidgetPayload } from 'src/types'
import { reviewEmailConfirmationClasses } from '../classes'

const ReviewConfirmationWidget = ({ widgetData, widgetPayload }: ReviewConfirmationWidgetProps): JSX.Element => {
  const { reviewTexts } = widgetData
  const {
    senderAvatarColor,
    senderAvatarIcon,
    senderName,
    receiverName,
    emailTitle,
    emailContent,
    reviewStars
  } = widgetPayload
  const { reviewText } = reviewTexts[reviewStars - 1]

  const now = new Date()
  const dateString = `${now.getMonth()}/${now.getDate()}/${now.getFullYear()}`

  return (
    <EmailConfirmation
      senderAvatarColor={senderAvatarColor}
      senderAvatarIcon={senderAvatarIcon}
      senderName={senderName}
      receiverName={receiverName}
      title={emailTitle}
      body={
        <EmailBodyContent>
          <Paper variant='outlined'>
            <Text
              variant='caption2'
              color='textSecondary'
              component='div'
            >
              {dateString}
            </Text>
            <Rating
              readOnly
              name='simple-controlled'
              value={reviewStars}
              size='large'
            />
            <Text
              variant='caption2'
              color='textSecondary'
              component='div'
            >
              {reviewText}
            </Text>
          </Paper>
          <Paper
            elevation={0}
            sx={{ background: '#EDF1F6', marginTop: 1 }}
          >
            <Text
              rich
              className={reviewEmailConfirmationClasses.emailContent}
            >
              {emailContent}
            </Text>
          </Paper>
        </EmailBodyContent>
      }
    />
  )
}

export const EmailBodyContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  '& .MuiPaper-root': {
    padding: theme.spacing(1)
  },
  '& .MuiRating-root': {
    width: '100%',
    justifyContent: 'space-between'
  },
  '& .MuiTypography-root': {
    color: 'black'
  },
  [`.${reviewEmailConfirmationClasses.emailContent} p`]: {
    ...theme.typography.caption2,
    color: theme.palette.text.secondary
  }
}))

interface ReviewConfirmationWidgetProps {
  widgetData: IHeroReviewWidget
  widgetPayload: IReviewWidgetPayload
}

export default ReviewConfirmationWidget
