import React from 'react'
import { Avatar, Box, Divider, List, ListItem, ListItemAvatar, ListItemText, Text, Media, styled, useTheme } from '@gositeinc/ui'
import { format as dateFormat } from 'date-fns'

import { IMedia } from '../types'

const EmailConfirmation = ({
  senderAvatarColor,
  senderAvatarIcon,
  senderName,
  receiverName,
  title,
  body,
  actionNames
}: EmailConfirmationProps): JSX.Element => {
  const theme = useTheme()

  const _senderAvatarColor = (senderAvatarColor !== undefined &&
    senderAvatarColor !== null &&
    typeof senderAvatarColor === 'string' &&
    senderAvatarColor !== '')
    ? senderAvatarColor
    : theme.palette.primary.main

  return (
    <>
      <EmailHeader dense>
        <ListItem
          secondaryAction={
            <Text
              component='span'
            >
              {dateFormat(new Date(), 'h:mma')}
            </Text>
          }
          sx={{ paddingX: 0 }}
        >
          <ListItemAvatar>
            <Avatar sx={{ backgroundColor: _senderAvatarColor, width: 24, height: 24 }}>
              <Media height={12} width={12} media={senderAvatarIcon} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={senderName}
            secondary={`To: ${receiverName}`}
          />
        </ListItem>
      </EmailHeader>
      <Divider variant='middle' sx={{ marginX: theme.spacing(1) }} />
      <EmailBody>
        <EmailBodyContent>
          <Text
            variant='subtitle3'
          >
            {title}
          </Text>
        </EmailBodyContent>
        {body}
      </EmailBody>
    </>
  )
}

const EmailHeader = styled(List)(({ theme }) => ({
  '& .MuiListItem-root': {
    padding: theme.spacing(0, 1)
  },
  '& .MuiListItemAvatar-root': {
    minWidth: 32
  },
  '& .MuiAvatar-root': {
    width: 24,
    height: 24
  },
  '& .MuiListItemText-root': {
    margin: 0
  },
  '& .MuiListItemText-primary': {
    fontSize: theme.typography.caption3.fontSize
  },
  '& .MuiListItemText-secondary': {
    fontSize: theme.typography.caption3.fontSize
  },
  '& .MuiListItemSecondaryAction-root': {
    right: theme.spacing(1),
    top: '15%'
  },
  '& .MuiListItemSecondaryAction-root .MuiTypography-root': {
    fontSize: theme.typography.pxToRem(7)
  }
}))

export const EmailBody = styled(Box)(({ theme }) => ({

}))

export const EmailBodyContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1)
}))

// interface IconStyleProps {
//   iconColor: string
// }

interface EmailConfirmationProps {
  senderName: string
  senderAvatarColor?: string
  senderAvatarIcon: IMedia
  receiverName: string
  title: string
  body: React.ReactNode
  actionNames?: string[]
}

export default EmailConfirmation
