import React, { useEffect, useRef } from 'react'
import { animated, useTransition } from '@react-spring/web'
import {
  BackgroundMedia,
  Box,
  Button,
  Container,
  Grid,
  RichText,
  Stack,
  Media,
  styled,
  Text,
  useMediaQuery,
  useTheme
} from '@gositeinc/ui'

import HeroMain from './hero-main'
import HeroBody from './hero-body'
import { HeroContent } from './hero-content'
import HeroWidget, { heroWidgetModifierStyles } from './hero-widget'
import HeroCta from './hero-cta'
import HeroWidgetInteractionResult from './hero-widget-interaction-result'
import HeroSignUpForm from './hero-sign-up-form'
import HeroTestimonial from './hero-testimonial'
import HeroArrows, { HeroArrowHeadAnchor, HeroArrowTailAnchor } from './hero-arrows'
import { IHero, IHeroContent } from '../types'
import {
  useHero
} from '../utils'
import { heroClasses } from '../classes'
import * as Constants from '../constants'
import * as Assets from '../assets'
import { ANALYTICS_CONSTANTS } from 'src/analytics-constants'

const Hero = ({ data }: HeroProps): JSX.Element => {
  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))
  const hero = useHero({ data })
  const widgetInteractionResultRef = useRef<HTMLDivElement>(null)

  if (hero == null) {
    return <></>
  }

  const {
    state,
    dispatch,
    parentData,
    heroType,
    mainData,
    contentData,
    backgroundMediaData,
    widgetData,
    widgetType,
    featureContentData,
    heroCTAData,
    signUpFormData,
    testimonialData,
    bodyAdditionalData,
    // businessSearchResultData,
    onSubmitWidget,
    onSubmitCta,
    onSubmitEmail,
    onBlurEmail,
    onClickHeroBodyCtaButton,
    styles
    // signupPageRoute
  } = hero

  // useHeroAnimation({
  //   isHeroMain: mainData != null && !(state.isBusinessSearchResultVisible), /** Animation will be applied for Hero Main */
  //   isBusinessSearchResult: state.isBusinessSearchResultVisible, /** Animation will be applied for HeroBusinessSearchResult */
  //   mainData: mainData != null
  //     ? { ctaType: mainData?.ctaType, foregroundMedia: mainData?.foregroundMedia, smallScreenForegroundMedia: mainData?.smallScreenForegroundMedia }
  //     : undefined,
  //   businessSearchResultData: businessSearchResultData != null
  //     ? { notes: businessSearchResultData?.notes }
  //     : undefined
  // })

  const {
    showFade = false,
    fadeStyleCreator = () => { },
    bodyStyleCreator = () => { },
    heroBodyGridPropsCreator = () => { },
    heroWidgetGridPropsCreator = () => { },
    heroCtaStyleCreator = () => { }
  } = heroWidgetModifierStyles[widgetType] ?? {}

  const ctaTransitions = useTransition(state.isCallToActionVisible, {
    ...Constants.HERO_CTA_CONFIG,
    reverse: !state.isCallToActionVisible
    // onRest: () => {},
  })

  const widgetInteractionResultTransitions = useTransition(state.isWidgetInteractionResultVisible, {
    ...Constants.HERO_WIDGET_INTERACTION_RESULT_ANIMATION_CONFIG,
    reverse: !state.isWidgetInteractionResultVisible
    // onRest: () => {},
  })

  const signUpFormTransitions = useTransition(state.isSignUpFormVisible, {
    ...Constants.HERO_SIGN_UP_FORM_ANIMATION_CONFIG,
    reverse: !state.isSignUpFormVisible
    // onRest: () => {},
  })

  const ctaButtonTransitions = useTransition(state.isWidgetInteractionResultVisible, {
    ...Constants.HERO_CTA_BUTTON_ANIMATION_CONFIG,
    reverse: !state.isWidgetInteractionResultVisible
    // onRest: () => {},
  })

  const continueToSignupForm = (): void => {
    dispatch({ type: 'setIsSignUpFormVisible', payload: true })
  }

  const renderHeroBodyCtaContent = (): React.ReactNode => {
    return (
      <HeroBodyCta id='heroCtaContent'>
        <Text
          variant='subtitle1'
          align='center'
          position='relative'
          sx={{ maxWidth: { xs: '75%', md: '100%' } }}
        >
          {!state.isWidgetInteractionResultVisible && !state.isSignUpFormVisible && !state.isCallToActionVisible && (
            <RichText>{featureContentData?.widgetTryOutText}</RichText>
          )}
          {state.isWidgetInteractionResultVisible && (
            <RichText>{featureContentData?.widgetInteractionResultText}</RichText>
          )}
          {state.isSignUpFormVisible && (
            <RichText>{featureContentData?.signUpText}</RichText>
          )}
          {state.isCallToActionVisible && (
            <RichText>{featureContentData?.featureShortDesc !== null && featureContentData?.featureShortDesc !== '' ? featureContentData?.featureShortDesc : featureContentData?.widgetTryOutText}</RichText>
          )}
          {isLargeScreen && (
            <HeroArrowTailAnchor
              id='heroArrowTailAnchor'
              component='span'
              heroState={state}
              widgetType={widgetType}
            />
          )}
          {!isLargeScreen && (state.isWidgetInteractionResultVisible || state.isSignUpFormVisible) && (
            <HeroArrowTailAnchor
              id='heroArrowTailAnchor'
              component='span'
              heroState={state}
              widgetType={widgetType}
            />
          )}
        </Text>
        {state.isCallToActionVisible && isLargeScreen && widgetType !== 'invoice' && (
          renderHeroBodyAdditionalContent()
        )}
        {ctaButtonTransitions(
          (styles, item) => item && (
            <animated.div style={{ ...styles }}>
              {state.isWidgetInteractionResultVisible && (
                <Button
                  variant='outlined'
                  sx={{ mt: 2 }}
                  onClick={onClickHeroBodyCtaButton}
                >
                  {featureContentData?.ctaButtonText}
                </Button>
              )}
            </animated.div>
          )
        )}
        {state.isSignUpFormVisible && testimonialData != null && (
          <HeroTestimonial
            data={testimonialData}
          />
        )}
      </HeroBodyCta>
    )
  }

  const renderHeroBodyAdditionalContent = (): React.ReactNode => {
    if (bodyAdditionalData != null) {
      return (
        <HeroBodyAdditionalTextRoot>
          {bodyAdditionalData?.icon !== null && bodyAdditionalData?.icon !== undefined && (
            <Box width={22}>
              <Media media={bodyAdditionalData?.icon} />
            </Box>
          )}
          {bodyAdditionalData?.primaryText !== null && bodyAdditionalData?.primaryText !== '' && (
            <Box pl={1}>
              <Text
                variant='h4'
                color={theme.palette.common.black}
                fontSize='1.25rem'
              >
                {bodyAdditionalData?.primaryText}
              </Text>
            </Box>
          )}
          <Box width='255px' display='flex' pl={1}>
            <Text
              variant='caption2'
              color={theme.palette.common.black}
              align='left'
              flex={1}
            >
              {bodyAdditionalData?.description}
            </Text>
          </Box>
        </HeroBodyAdditionalTextRoot>
      )
    }

    return <></>
  }

  useEffect(() => {
    dispatch({ type: 'setIsWidgetVisible', payload: true })
  }, [])

  if (mainData != null) {
    return (
      <HeroMain
        data={mainData}
        analytics={ANALYTICS_CONSTANTS[mainData.__component]}
      />
    )
  }

  if (heroType === 'feature') {
    return (
      <Root id='hero' sx={styles}>
        <HeroArrows heroState={state} widgetType={widgetType} />
        <Container sx={{ paddingX: 0 }}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={4}
              lg={5}
              {...(heroBodyGridPropsCreator(theme))}
            >
              {featureContentData != null && (
                <HeroBody
                  bodyStyle={bodyStyleCreator(theme) ?? {}}
                  data={featureContentData}
                  renderHeroCtaContent={
                    isLargeScreen
                      ? renderHeroBodyCtaContent()
                      : (
                        <div id='heroCtaContent'>
                          {!state.isWidgetInteractionResultVisible && !state.isSignUpFormVisible && (
                            <Text
                              variant='body2'
                              align='center'
                              mt={5}
                              position='relative'
                              className={heroClasses.description}
                            >
                              <RichText removeLineBreaksInSmallerScreen={false}>{featureContentData?.widgetTryOutText}</RichText>
                              <HeroArrowTailAnchor
                                id='heroArrowTailAnchor'
                                component='span'
                                heroState={state}
                                widgetType={widgetType}
                              />
                            </Text>
                          )}
                        </div>)
                  }
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              lg={7}
              display='flex'
              flexDirection='column'
              {...(heroWidgetGridPropsCreator(theme))}
            >
              <WidgetContainer
                sx={{
                  height: widgetInteractionResultRef != null
                    ? widgetInteractionResultRef.current?.clientHeight
                    : 'auto'
                }}
              >
                {showFade && state.isCallToActionVisible && isLargeScreen && <Fade sx={fadeStyleCreator(theme) ?? {}} />}

                {state.isWidgetVisible && Object.values(Constants.Feature).includes(widgetType) && widgetData != null && (
                  <>
                    <HeroWidget
                      contentData={featureContentData}
                      widgetData={widgetData}
                      widgetType={widgetType}
                      onSubmit={onSubmitWidget}
                    />
                    {
                      widgetType === 'invoice' &&
                      !state.isCallToActionVisible &&
                      !state.isWidgetInteractionResultVisible &&
                      !state.isSignUpFormVisible &&
                      (
                        <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: theme.spacing(6) }}>
                          <Grid item display='flex'>
                            <Box mt={0.3}>
                              <Assets.LinktextIcon
                                pathColor={theme.palette.primary.main}
                                sx={{ fontSize: 18 }}
                              />
                            </Box>
                            <Text
                              variant='subtitle1'
                              color='primary'
                              fontWeight={theme.typography.fontWeightSemiBold}
                              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                              onClick={continueToSignupForm}
                              pl={1}
                            >
                              {heroCTAData?.continueLinkText}
                            </Text>
                          </Grid>
                        </Box>
                      )
                    }
                  </>
                )}

                {ctaTransitions(
                  (styles, item) => item && (
                    <animated.div style={{ ...styles, position: 'relative', zIndex: 1 }}>
                      {parentData != null && heroCTAData != null && featureContentData != null && (
                        <HeroCta
                          parentData={parentData}
                          contentData={featureContentData}
                          data={heroCTAData}
                          widgetType={widgetType}
                          renderAdditionalContent={renderHeroBodyAdditionalContent}
                          onSubmit={onSubmitCta}
                          isMobileView={!isLargeScreen}
                          onClickContinue={continueToSignupForm}
                          ctaStyle={heroCtaStyleCreator(theme) ?? {}}
                        />
                      )}
                    </animated.div>
                  )
                )}
                {widgetInteractionResultTransitions(
                  (styles, item) => item && (
                    <animated.div
                      style={{
                        ...styles,
                        position: 'absolute'
                      }}
                    >
                      {widgetData != null && (
                        <HeroWidgetInteractionResult
                          widgetData={widgetData}
                          widgetPayload={state.widgetPayload}
                          widgetType={widgetType}
                          ref={widgetInteractionResultRef}
                        />
                      )}
                    </animated.div>
                  )
                )}
                {signUpFormTransitions(
                  (styles, item) => item && (
                    <animated.div style={{ ...styles, width: '100%' }}>
                      {/* <Link href={navigateToCloud()}> */}
                      {signUpFormData != null && (
                        <HeroSignUpForm
                          data={signUpFormData}
                          onSubmit={onSubmitEmail}
                          onBlur={onBlurEmail}
                        />
                      )}
                      {/* </Link> */}
                    </animated.div>
                  )
                )}
                <HeroArrowHeadAnchor
                  id='heroArrowHeadAnchor'
                  component='span'
                  heroState={state}
                  widgetType={widgetType}
                />
              </WidgetContainer>
              {!isLargeScreen &&
                (state.isWidgetInteractionResultVisible || state.isSignUpFormVisible) &&
                renderHeroBodyCtaContent()}
            </Grid>
          </Grid>
        </Container>
        {backgroundMediaData != null && (
          <BackgroundMedia
            media={backgroundMediaData?.backgroundMedia}
            objectFit='cover'
          />)}
      </Root>
    )
  }

  return (
    <Root id='hero' sx={styles}>
      <Container sx={{ paddingX: 0, position: 'relative', zIndex: 1 }}>
        <Stack
          direction={{ xs: 'column', lg: 'row' }}
        >
          {contentData != null && (
            <HeroContent
              data={contentData as IHeroContent}
              analytics={ANALYTICS_CONSTANTS[contentData.__component]}
            />
          )}
          {featureContentData != null && (
            <HeroBody
              bodyStyle={bodyStyleCreator(theme) ?? {}}
              data={featureContentData}
              renderHeroCtaContent={
                isLargeScreen
                  ? renderHeroBodyCtaContent()
                  : (
                    <div id='heroCtaContent'>
                      {!state.isWidgetInteractionResultVisible && !state.isSignUpFormVisible && (
                        <Text
                          variant='body2'
                          align='center'
                          mt={5}
                          position='relative'
                          className={heroClasses.description}
                        >
                          <RichText removeLineBreaksInSmallerScreen={false}>{featureContentData?.widgetTryOutText}</RichText>
                          <HeroArrowTailAnchor
                            id='heroArrowTailAnchor'
                            component='span'
                            heroState={state}
                            widgetType={widgetType}
                          />
                        </Text>
                      )}
                    </div>)
              }
            />
          )}
          {state.isWidgetVisible && widgetData != null && (
            <Box>
              <WidgetContainer>
                {showFade && state.isCallToActionVisible && isLargeScreen && <Fade sx={fadeStyleCreator(theme) ?? {}} />}

                {state.isWidgetVisible && widgetData != null && (
                  <>
                    <HeroWidget
                      contentData={contentData}
                      widgetData={widgetData}
                      widgetType={widgetType}
                      onSubmit={onSubmitWidget}
                    />
                    {
                      widgetType === 'invoice' &&
                      !state.isCallToActionVisible &&
                      !state.isWidgetInteractionResultVisible &&
                      !state.isSignUpFormVisible &&
                      (
                        <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: theme.spacing(6) }}>
                          <Grid item display='flex'>
                            <Box mt={0.3}>
                              <Assets.LinktextIcon
                                pathColor={theme.palette.primary.main}
                                sx={{ fontSize: 18 }}
                              />
                            </Box>
                            <Text
                              variant='subtitle1'
                              color='primary'
                              fontWeight={theme.typography.fontWeightSemiBold}
                              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                              onClick={continueToSignupForm}
                              pl={1}
                            >
                              {heroCTAData?.continueLinkText}
                            </Text>
                          </Grid>
                        </Box>
                      )
                    }
                  </>
                )}

                {ctaTransitions(
                  (styles, item) => item && (
                    <animated.div style={{ ...styles, position: 'relative', zIndex: 1 }}>
                      {heroCTAData != null && (
                        <HeroCta
                          data={heroCTAData}
                          parentData={parentData}
                          contentData={contentData}
                          widgetType={widgetType}
                          renderAdditionalContent={renderHeroBodyAdditionalContent}
                          onSubmit={onSubmitCta}
                          isMobileView={!isLargeScreen}
                          onClickContinue={continueToSignupForm}
                          ctaStyle={heroCtaStyleCreator(theme) ?? {}}
                        />
                      )}
                    </animated.div>
                  )
                )}
                {widgetInteractionResultTransitions(
                  (styles, item) => item && (
                    <animated.div style={{ ...styles, position: 'absolute' }}>
                      {widgetData != null && (
                        <HeroWidgetInteractionResult
                          widgetData={widgetData}
                          widgetPayload={state.widgetPayload}
                          widgetType={widgetType}
                        />
                      )}
                    </animated.div>
                  )
                )}
                {signUpFormTransitions(
                  (styles, item) => item && (
                    <animated.div style={{ ...styles, width: '100%' }}>
                      {/* <Link href={navigateToCloud()}> */}
                      {signUpFormData != null && (
                        <HeroSignUpForm
                          data={signUpFormData}
                          onSubmit={onSubmitEmail}
                          onBlur={onBlurEmail}
                        />
                      )}
                      {/* </Link> */}
                    </animated.div>
                  )
                )}
                <HeroArrowHeadAnchor
                  id='heroArrowHeadAnchor'
                  component='span'
                  heroState={state}
                  widgetType={widgetType}
                />
              </WidgetContainer>
              {!isLargeScreen &&
                (state.isWidgetInteractionResultVisible || state.isSignUpFormVisible) &&
                renderHeroBodyCtaContent()}
            </Box>)}
        </Stack>
      </Container>
      {backgroundMediaData != null && (
        <BackgroundMedia
          media={backgroundMediaData?.backgroundMedia}
          objectFit='cover'
        />)}
    </Root>
  )
}

const Root = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundColor: '#F7F9FB',
  alignItems: 'center',
  display: 'flex',
  overflow: 'hidden',
  paddingTop: 50,
  paddingBottom: 60,
  [`.${heroClasses.title} h1`]: {
    ...theme.typography.h4,
    [theme.breakpoints.up('md')]: {
      ...theme.typography.h3
    }
  },
  [theme.breakpoints.up('md')]: {
    paddingTop: 100
  },
  [theme.breakpoints.up('lg')]: {
    minHeight: 720
  }
}))

const HeroBodyCta = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: theme.spacing(5),
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(16)
  }
}))

const WidgetContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  minHeight: 330,
  minWidth: 200,
  position: 'relative',
  transition: 'min-height 1s ease-out',
  [theme.breakpoints.up('md')]: {
    minHeight: 600
  }
}))

const Fade = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(180deg, rgba(247,249,251,1) 0%, rgba(247,249,251,1) 75%, rgba(247,249,251,0) 100%)',
  height: 600,
  width: '100%',
  zIndex: 9,
  position: 'absolute',
  top: -300,
  [theme.breakpoints.down('md')]: {
    height: 200,
    top: -20,
    zIndex: 9
  }
}))

const HeroBodyAdditionalTextRoot = styled(Box)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(5)
  }
}))
interface HeroProps {
  data: IHero
}

export type { HeroProps }
export default Hero
