/* eslint @typescript-eslint/explicit-function-return-type: 0 */
/* eslint @typescript-eslint/restrict-template-expressions: 0 */

import fetch from 'cross-fetch'

import { env } from './env'
import { II18nLocaleObject, IPage } from 'src/types'

const siteName = 'growth.gosite.com'

export function getStrapiURL (path) {
  return `${env.strapiDomain}${path}`
}

export function getHubspotURL (path) {
  return `${env.hubspotDomain}${path}`
}

export function getGositeDashboardURL (path) {
  return `${env.v1GositeDashboard}${path}`
}

// Helper to make GET requests to Strapi
export async function fetchAPI (path, options = {}) {
  const defaultOptions = {
    headers: {
      'Content-Type': 'application/json'
    }
  }
  const mergedOptions = {
    ...defaultOptions,
    ...options
  }
  const requestUrl = getStrapiURL(path)
  const response = await fetch(requestUrl, mergedOptions)
  // if (!response.ok) {
  //   console.error(response)
  //   console.error(response.statusText)
  //   throw new Error('An error occured please try again')
  // }
  const data = await response.json()
  return data
}

export const getAllLocales = async (): Promise<II18nLocaleObject[]> => {
  const locales: II18nLocaleObject[] = await fetchAPI('/i18n/locales')
  return locales
}

export const getAllPages = async (i18nLocaleObject: II18nLocaleObject): Promise<IPage[]> => {
  return await fetchAPI(`/pages?_locale=${i18nLocaleObject.code}&status=published&site.site=${siteName}&_limit=-1`) as unknown as IPage[]
}

/**
 *
 * @param {object} params The router params object with slug: { slug: [<slug>] }
 * @param {string} locale The current locale specified in router.locale
 * @param {boolean} preview router isPreview value
 */
export async function getPageData (params, locale = 'en', preview = false) {
  const slug = params.slug.join('/')
  // Find the pages that match this slug
  const pagesData = await fetchAPI(`/pages?slug=${slug}&_locale=${locale}&status=published${preview ? '&status=draft' : ''}&site.site=${siteName}`)

  // Make sure we found something, otherwise return null
  if (pagesData == null || pagesData.length === 0) {
    return null
  }

  // Return the first item since there should only be one result per slug
  return pagesData[0]
}

// Get site data from Strapi (metadata, navbar, footer...)
export async function getGlobalData (locale) {
  const global = await fetchAPI(`/global?_locale=${locale ?? 'en'}`)
  return global
}

export async function getErrorPageData (locale) {
  const data = await fetchAPI(`/marketing-site-error?_locale=${locale ?? 'en'}`)
  return data
}

export async function postAPI (path, payload = {}) {
  const requestPayload = {
    method: 'POST',
    body: JSON.stringify({ ...payload }),
    headers: {
      'Content-Type': 'application/json'
    }
  }
  const requestUrl = path
  const response = await fetch(requestUrl, requestPayload)
  if (!response.ok) {
    console.error(response.statusText)
  }
  return response
}

export async function sendSMS (payload) {
  const createSmsURL = getGositeDashboardURL('/api/users/send-sms')
  const smsResponse = postAPI(createSmsURL, payload)
  return await smsResponse
}

export async function createContactInHubspot (phoneNumber) {
  const createContactURL = getHubspotURL(`/v2/hubspot/createContact/phone/${phoneNumber}`)
  const hubspotResponse = postAPI(createContactURL)
  const hubspotId = (await hubspotResponse).text()
  return await hubspotId
}

export async function createContactWithEmailInHubspot (email) {
  const createContactURL = getHubspotURL(`/v2/hubspot/createContact/email/${email}`)
  const hubspotResponse = postAPI(createContactURL)
  const hubspotId = (await hubspotResponse).text()
  return await hubspotId
}

export async function updateContactInHubspot (hubId, email) {
  const updateContactURL = getHubspotURL(`/v2/hubspot/updateContact/${hubId}/email/${email}`)
  const hubspotResponse = postAPI(updateContactURL)
  const hubspotId = (await hubspotResponse).text()
  return await hubspotId
}

export async function submitHubDetails (payload) {
  const response = await postAPI(getHubspotURL('/v2/hubspot/forms/'), payload)
  return response
}

export async function getAppLinkNotificationBanner (locale = 'en') {
  const global = await fetchAPI(`/app-link-notification-banner?_locale=${locale}`)
  return global
}
