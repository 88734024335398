import React from 'react'

import { Phone } from 'src/components'
import BookingEmailConfirmation from './booking-email-confirmation'
import MessengerEmailConfirmation from './messenger-email-confirmation'
import { IHeroWidget, HeroWidgetInteractionResultPayload } from '../types'
import { Feature } from '../constants'
import ReviewConfirmationWidget from './review-confirmation'
import ContactCardWidget from './contact-card-widget'
import PaymentConfirmationWidget from './payment-confirmation'
import InvoicesEmailConfirmation from './invoices-email-confirmation'
import EngagementEmailConfirmation from './engagement-email-confirmation'
import PlacementConfirmationWidget from './placement-confirmation'

const _HeroWidgetInteractionResult = ({
  widgetType,
  widgetData,
  widgetPayload
}: HeroWidgetInteractionResultProps, ref: React.ForwardedRef<HTMLDivElement>): JSX.Element => {
  const isTopBar: boolean = (widgetType !== Feature.Invoice)

  const renderContent = (): React.ReactNode => {
    const ContentComponent: React.ElementType = featureDemoComponents[widgetType]

    return <ContentComponent widgetPayload={widgetPayload} widgetData={widgetData} />
  }

  if (widgetType === Feature.ContactHub) {
    return <div ref={ref}>{renderContent()}</div>
  }

  return (
    <Phone ref={ref} showPhoneEmailShell={isTopBar}>
      {renderContent()}
    </Phone>
  )
}

const HeroWidgetInteractionResult = React.forwardRef(_HeroWidgetInteractionResult)

const featureDemoComponents = {
  booking: BookingEmailConfirmation,
  'contact-hub': ContactCardWidget,
  'email-campaign': BookingEmailConfirmation,
  invoice: InvoicesEmailConfirmation,
  messenger: MessengerEmailConfirmation,
  payment: PaymentConfirmationWidget,
  placement: PlacementConfirmationWidget,
  review: ReviewConfirmationWidget,
  sites: BookingEmailConfirmation,
  engagement: EngagementEmailConfirmation
}

interface HeroWidgetInteractionResultProps {
  widgetType: Feature
  widgetPayload: HeroWidgetInteractionResultPayload
  widgetData: IHeroWidget
  onProceed?: () => void
}

export default HeroWidgetInteractionResult
