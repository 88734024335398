import {
  FormFieldValues,
  IBlogArticle,
  IBlogAuthor,
  IBlogCategory,
  IFooter,
  IPricingParam,
  INavbar,
  INotificationBanner,
  INavbarLink,
  IMetadata,
  SxProps
} from '@gositeinc/ui'
import PropTypes from 'prop-types'
declare global {
  interface Window {
    analytics: any
    cookieStore: any
    dataLayer: any
  }
}
// https://next.material-ui.com/customization/theming/#custom-variables
// declare module '@material-ui/core/styles' {
//   interface Theme {
//     status: {
//       danger: string;
//     };
//   }
//   // allow configuration using `createTheme`
//   interface ThemeOptions {
//     status?: {
//       danger?: string;
//     };
//   }
// }

// https://next.material-ui.com/customization/typography/#adding-amp-disabling-variants
// https://github.com/mui-org/material-ui/issues/22257#issuecomment-748048683
declare module '@mui/material' {
  interface TypographyVariants {
    fontWeightSemiBold: number
    heading: React.CSSProperties
    subtitle3: React.CSSProperties
    caption3: React.CSSProperties
    caption2: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    fontWeightSemiBold: number
    heading: React.CSSProperties
    subtitle3: React.CSSProperties
    caption3: React.CSSProperties
    caption2: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material' {
  interface TypographyPropsVariantOverrides {
    heading: true
    subtitle3: true
    caption3: true
    caption2: true
  }
}

export interface IComponentBase {
  __component: string
  id: number
  metaTitle?: string
}

export interface IBackgroundMedia extends IComponentBase {
  backgroundMedia: IMedia
  styles: SxProps
}

export interface IHeroSignUpPayload {
  phoneNumber?: number | null
  email: string
  widgetData: IComponentBase
  widgetPayload?: HeroWidgetInteractionResultPayload
}

export interface IHeroBookingSignUpPayload extends IHeroSignUpPayload {
  bookingDateTime?: Date | null
}

export interface IHeroPaymentSignUpPayload extends IHeroSignUpPayload, IPaymentWidgetPayload { }

export interface IHeroPlacementSignUpPayload extends IHeroSignUpPayload, IPlacementWidgetPayload { }

export interface IHubSpotForm extends IComponentBase {
  script: string
}

export interface IHeroMediaWidget extends IComponentBase {
  name: string
  foregroundMedia: Array<{
    id: number
    styles: SxProps
    media: IMedia
  }>
  smallScreenForegroundMedia: Array<{
    id: number
    styles: SxProps
    media: IMedia
  }>
  styles: SxProps
}

export interface IHeroFormWidget extends IComponentBase {
  form: IForm
  title: string
}

export interface IBookingWidgetPayload extends IEmailConfirmation, IHeroBookingWidget {
  bookingDateTime: Date
}

export interface IReviewWidgetPayload extends IEmailConfirmation {
  reviewStars: number
}

export interface IContactHubWidgetPayload {
  formValues: FormFieldValues
}

export interface IPaymentWidgetPayload extends IEmailConfirmation {
  amount?: number
  selectedService?: IHeroPaymentService
}
export interface InvoiceRowItemFormValues {
  item?: string
  quantity?: number | null
  rate?: number | null
}

export interface InvoiceWidgetPreviewPayload {
  formValues: InvoicePayloadItems
}
export interface IInvoiceWidgetPayload extends InvoiceWidgetPreviewPayload, IEmailConfirmation {

}

export interface InvoicePayloadItems {
  [key: string]: string | InvoiceRowItemFormValues[]
}
export interface IEngagementWidgetPayload extends IEmailConfirmation {
  emailSubject: string
}
export interface IPlacementWidgetPayload extends IEmailConfirmation {
  businessHours: IHeroPlacementBusinessHours[]
}

export interface IEmailConfirmation extends IComponentBase {
  senderName: string
  senderAvatarIcon: IMedia
  senderAvatarColor: string
  receiverName: string
  emailTitle: string
  emailContent: string
}

export interface IHeroCTA extends IComponentBase {
  title: string
  textFieldPlaceholder: string
  continueLinkText: string
  smsContent: string
  styles: SxProps
}

export interface IFeatureColumn extends IComponentBase {
  icon: IMedia
  title: string
  description: string
  contentAlignment: string
}

export interface ITestimonialBase extends IComponentBase {
  stars: number
  quote: string
  author: string
  company: string
}

export interface IFeatureColumnsGroup extends IComponentBase {
  title: string
  description: string
  features: IFeatureColumn[]
}

export interface ITestimonialColumnsGroup extends IComponentBase {
  title: string
  description: string
  items: ITestimonialBase[]
  styles: SxProps
}

export interface IHeroContent extends IComponentBase {
  badges: IMedia[]
  buttonLinks: IButtonLink[]
  ctaText?: string
  ctaType: string
  ctaUrl: string
  description: string
  form: IForm
  labelIcon: IMedia
  labelText: string
  phoneNumberPlaceholderText?: string
  storeLinks: IImageLink[]
  styles: SxProps
  theme: null | 'normal' | 'inverted'
  title: string
}
export interface IHeroFeatureContent extends IComponentBase {
  title: string
  label: string
  widgetTryOutText: string
  featureShortDesc: string
  widgetInteractionResultText: string
  signUpText: string
  ctaButtonText: string
  labelIcon?: IMedia
  hubspotId: string
  styles: SxProps
}

export interface IHeroSignUp extends IComponentBase {
  icon: IMedia
  title: string
  backgroundMedia: IMedia
}

export interface IHeroTestimonial extends IComponentBase {
  videoIcon: IMedia
  title: string
  subtitle: string
  videoUrl: string
  videoLength: string
  videoFile: IMedia
}

export interface IHeroBookingWidget extends IComponentBase {
  businessIcon: IMedia
  businessIconColor: string
  businessLocation: string
  businessName: string
  maximumMonths: number
  serviceName: string
  serviceDescription: string
  serviceDurationInMinutes: number
  serviceHourlyPricing: string
  smsMessage: string
}

export interface IHeroMessengerWidget extends IComponentBase {
  senderName: string
  description: string
  senderAvatar: IMedia
  messengerItems: IHeroMessengerItem[]
}

export interface IHeroReviewTextItem {
  id: number
  reviewText: string
}
export interface IHeroReviewWidget extends IComponentBase {
  title: string
  subtitle: string
  defaultStars: number
  minStars: number
  media: IMedia
  reviewTexts: IHeroReviewTextItem[]
}

export interface IHeroPaymentService extends IComponentBase {
  name: string
  image: IMedia
  price: number
}

export interface IHeroPaymentOption extends IComponentBase {
  headerText: string
  paymentLinkText: string
  invoiceText: string
  invoiceIcon: IMedia
  paymentLinkIcon: IMedia
}

export interface IHeroPaymentPaymentLink extends IComponentBase {
  headerText: string
  amountLabelText: string
  contactLabelText: string
  amountInputPlaceholderText: string
  amount: number
  contactName: string
  contactEmail: string
  contactImage: IMedia
}

export interface IHeroPaymentInvoice extends IComponentBase {
  headerText: string
  invoiceItemsLabelText: string
  invoiceItemsPlaceholderText: string
  services: IHeroPaymentService[]
  billToLabelText: string

  contactName: string
  contactEmail: string
  contactImage: IMedia

  invoiceDetailLabelText: string
  sendOnText: string
  dueText: string

  totalText: string
  selectServiceAboveText: string
}
export interface IHeroPaymentWidget extends IComponentBase {
  daysUntilDue: number
  confirmationIconBackgroundColor: string
  confirmationNameColor: string
  confirmButtonText: string
  confirmDueOnPrefixText: string
  paymentOption: IHeroPaymentOption
  invoice: IHeroPaymentInvoice
  paymentLink: IHeroPaymentPaymentLink
}

export interface IHeroContactHubWidget extends IComponentBase {
  buttons: IButton[]
  form: IForm
  formFieldAttributes: Array<{ name: string, label: string, icon: IMedia, value: string | number }>
}

export interface IHeroEngagementWidget extends IComponentBase {
  title: string
  recieverName: string
  senderName: string
  emailSubjectPlaceholderText: string
  businessAvatar: IMedia
  businessName: string
  engagementMedia: IMedia
  engagementTitle: string
  engagementDescription: string
  engagementCtaText: string
  engagementSubmitText: string
  businessIconBackgroundColor: string
  subject: string
}
export interface IHeroPlacementTabService {
  id: number
  name: string
  icon: IMedia
  active: boolean
}

export interface IHeroPlacementThirdPartyService {
  name: string
  icon: IMedia
  successIcon: IMedia
  status: string
}

export interface IHeroPlacementBusinessHours extends IComponentBase {
  checked: boolean
  dayOfWeek: number
  fullDay: boolean
  openHour?: string
  closeHour?: string
}

export interface IHeroPlacementResult extends IComponentBase {
  thirdPartyServices: IHeroPlacementThirdPartyService[]
  resultIcon: IMedia
  manageListingText: string
}
export interface IHeroPlacementWidget extends IComponentBase {
  businessIcon: IMedia
  businessIconColor: string
  businessLocation: string
  businessName: string
  manageBusinessText: string
  businessHoursText: string
  services: IHeroPlacementTabService[]
  defaultHours?: IHeroPlacementBusinessHours[]
  placementResult: IHeroPlacementResult
}

export interface IHeroSitesWidget extends IComponentBase {
  styles: SxProps
}

export type HeroType = 'normal' | 'feature'

export interface IHero extends IComponentBase {
  hero: {
    id: number
    name: string
    heroType: HeroType | null
    styles: SxProps
    // heroCTA: IHeroCTA
    // email: IEmailConfirmation
    // body: IHeroFeatureContent
    // signupForm: IHeroSignUp
    // testimonial: IHeroTestimonial
    contentParts: IHeroContentPart[]
  }
}

export interface IHeroAnimiation {
  isHeroMain: boolean
  isBusinessSearchResult: boolean
  mainData?: {
    ctaType: string
    foregroundMedia: Array<{
      id: number
      styles: {
        animation?: {
          from: gsap.TweenVars
          to: gsap.TweenVars
          position?: gsap.Position
        }
      }
      media: IMedia
      smallScreenMedia: IMedia
    }>
    smallScreenForegroundMedia: Array<{
      id: number
      styles: {
        animation?: {
          from: gsap.TweenVars
          to: gsap.TweenVars
          position?: gsap.Position
        }
      }
      media: IMedia
    }>
  }
  businessSearchResultData?: {
    notes: INote[] | undefined
  }
}

export interface IHeroBodyAdditionalText extends IComponentBase {
  icon: IMedia
  description: string
  primaryText: string
}

export type HeroCTAMessageFormatter = ({
  featureContentData: IHeroFeatureContent,
  heroCTAData: IHeroCTA,
  widgetPayload: HeroWidgetInteractionResultPayload
}) => string

export type HeroEmailSignupHandler = ({
  phoneNumber,
  email,
  widgetPayload,
  widgetData
}: {
  phoneNumber: number | null
  email: string
  widgetPayload: HeroWidgetInteractionResultPayload
  widgetData: IComponentBase
}) => null | IHeroBookingSignUpPayload | IHeroPaymentSignUpPayload

export interface ILink extends IComponentBase {
  newTab?: boolean
  text?: string
  url: string
}

export type ButtonVariant = 'outlined' | 'solid' | 'link'

export interface IButtonLink extends ILink {
  variant?: ButtonVariant
  icon?: IMedia
}

export interface IButton extends IComponentBase {
  text?: string
  type?: 'primary' | 'secondary'
  variant?: ButtonVariant
  icon?: IMedia
}

export interface IFeatureRow extends IComponentBase {
  description: string
  label: string
  buttonLink: IButtonLink
  content?: string
  iconList: IMedia[]
  mediaOrder: 'left' | 'center' | 'right' | null
  mediaHorizontalAlignment: 'left' | 'center' | 'right' | null
  contentHorizontalAlignment: 'left' | 'center' | 'right' | null
  mediaColumnWidth: 'medium' | 'large' | 'largest' | null
  title: string
  media: IMedia
  backgroundMedia?: IMedia | null
  valuePropositionColumnLayout: string
  valuePropositions?: IValueProposition[]
  smallScreenMedia: IMedia
  styles: SxProps
}

export interface ITabView extends IComponentBase {
  labelIcon: IMedia
  labelText: String
  title: string
  tabMenuItems: ITabMenuItems[]
  tabPanels: ITabPanel[]
  orientation: 'vertical' | 'horizontal'
  mediaOrder: 'left' | 'right'
  styles: SxProps
}

export interface IForegroundMedia extends IComponentBase {
  id: number
  styles: SxProps
  media: IMedia
  name: string
}

export interface ITabPanel extends IComponentBase {
  title: string
  content: string
  backgroundMedia: IMedia
  primaryButton: IButtonLink
  secondaryButton: IButtonLink
  foregroundMedia: IForegroundMedia[]
  smallScreenForegroundMedia: IForegroundMedia[]
  mediaHorizontalAlignment: 'left' | 'right'
  tabMenuHorizontalAlignment: 'left' | 'right'
  tabMenuOrientation: 'horizontal' | 'vertical'
  testimonial: IHeroTestimonial
}
export interface ITabMenuItems extends IComponentBase {
  labelIcon: IMedia
  labelText: string
  content: string
  title: string
}

export interface IValueProposition extends IComponentBase {
  icon?: IMedia
  title: string
  description: string
}

export interface IFeatureOverview extends IComponentBase {
  title: string
  description?: string
  primaryMedia: IMedia
  mediaList: IMedia[]
  primaryMediaVerticalAlignment: string
  showDivider: boolean
  mediaListVerticalAlignment: string
  styles: SxProps
}
export interface INavigationItemModel {
  parent: number
  menuAttached: Boolean
  title: string
  id: number
  items: INavigationChildItemModel[]
}

export interface INavigationChildItemModel {
  id: number
  path: string
  title: string
  related: INavbarFeatureDropdownItem
}

export interface INavbarFeatureDropdownItem {
  subtitle: string
  featureId: string
  addIcon: string
}

export interface IHeaderButton {
  id: number
  url: string
  type: string
  text: string
  newTab: Boolean
}

export interface ILogo {
  id: number
  url: string
}

export interface IProductCta extends IComponentBase {
  title: string
  subtitle: string
  label: string
  button: IButtonLink
  backgroundImage: IMedia
  isBounded: boolean
  styles: SxProps
}

export interface IMedia extends IComponentBase {
  url: string
  alternativeText: string
  width: number
  height: number
  ext: string
  formats?: Array<{
    url: string
    height: number
    width: number
  }>
}

export interface IGlobal {
  metadata: IMetadata
  metaTitleSuffix: string
  favicon: IMedia
  footer: IFooter
  navbar: INavbar
  navbarLinks: INavbarLink[]
  marketingPromotion: INotificationBanner
  cookieConsent: ICookieConsent
}

export interface ICookieConsent {
  id: number
  title: string
  acceptButton: IButton
}
export interface IErrorPage {
  title: string
  description: string
  redirectButton: IButtonLink
}

export interface FooterColumn {
  id: number
  title: string
  links: ILink[]
}

export interface IImageLink extends ILink {
  media: IMedia
  title: string
}

export interface INavbarDropDown {
  id: string
  title: string
  nestedColumnLayout: string
  links: INavbarChildItem[]
}

export interface IFeatureCta extends IComponentBase {
  buttonText: string
  title: string
  subtitle: string
  media: IMedia
  buttonUrl?: IButtonUrl
  url: string
}

export interface ITestimonial extends IComponentBase {
  label: string
  quote: string
  ctaButton: ITestimonialButton[]
  figure: ICaptionImage
  backgroundMedia: IMedia
}

export interface ITestimonial2 extends IComponentBase {
  content: ITestimonialBase
  backgroundMedia: IMedia
  styles: SxProps
}

export interface ITestimonial3 extends ITestimonialTabItem, Omit<ITestimonialTabItem, 'companyLogo' | 'media'> {
  topReviewImage?: IMedia
  topBannerText: string
  businessImage: IMedia
  businessReviewImage: IMedia
  backgroundImage: IMedia
  topReviewImageShown: boolean
  topBannerTextShown: boolean
}

export interface ITestimonialTabsGroup extends IComponentBase {
  backgroundImage: IMedia
  labelIcon: IMedia
  labelText: string
  title: string
  description: IMedia
  tabItems: Array<{ labelText: string } & IComponentBase>
  tabPanels: ITestimonialTabItem[]
  styles: SxProps
}

export interface ITestimonialTabItem extends IComponentBase {
  companyLogo: IMedia
  content: ITestimonialBase
  buttonLink: IButtonLink
  media: IMedia
  styles: SxProps
}

export interface ICaptionImage {
  caption: string
  captionImage: IMedia
}
export interface ITestimonialButton extends ILink {
  variant?: ButtonVariant
  config?: SxProps
}
export interface IButtonUrl {
  slug: string
}

export interface INavbarChildItem extends IComponentBase {
  title: string
  subtitle: string
  externalUrl: string
  external: boolean
  opensNewTab: boolean
  icon: IMedia
  url: string
}

export interface ISite {
  id: number
  site: string
}

export interface II18nLocaleObject {
  id: number
  name: string
  code: string
  isDefault: boolean
}

export interface IPageContext {
  defaultLocale?: string | null
  locale?: string
  locales?: string[] | null
  slug: string
  primaryColor?: string
  navbarTheme?: 'normal' | 'inverted'
  displayNavbarLinks?: boolean
  isPagePasswordProtected?: boolean
  localizations: PageContextLocalization[]
  localizedPaths: PageContextLocalizedPath[]
  metadata: IMetadata
  metaTitleSuffix: string
  styles: SxProps
  scripts?: IPageScript[]
  pageLayout?: 'normal' | 'article' | null
}

export interface IPage extends IArticlePage {
  id: number
  shortName: string
  status: 'published' | 'draft'
  slug: string
  site: ISite
  locale: string
  locales?: string[]
  defaultLocale?: string
  contentSections: ISection[]
  localizations: PageContextLocalization[]
  metadata: IMetadata
  metaTitleSuffix: string
  displayPageNavbarLinks: boolean
  pagePrimaryColor: string
  pageNavbarTheme: 'normal' | 'inverted'
  pagePasswordProtected: boolean
  styles: SxProps
  scripts: IPageScript[]
  pageLayout: 'normal' | 'article' | null
  created_at: string
}
// Test code to demo devops

export interface IArticlePage {
  readingTimeInMinutes?: number
  image?: IMedia
  categories: IBlogCategory[]
  articleBodyIntro?: string
  createdAt: string
  title: string
  seoFriendlyTitle: string
  subtitle?: string
  author: IBlogAuthor
  styles: SxProps
}

export interface ISubpages extends IComponentBase {
  subpages: ISubpage[]
}

export interface ISubpage {
  id: number
  name: string
  title: string
  styles: SxProps
  contentSections: ISection[]
}

export interface IBlogPage {
  id: number
  title: string
  pageSize: number
  styles: SxProps
  locale?: string
  localizations: PageContextLocalization[]
  publishedAt?: string
  createdAt?: string
  updatedAt?: string
  mainZone: any
  sidebarZone: any
  metadata: IMetadata
  metaTitleSuffix: string
  noResultText: string
  searchText: string
  relatedArticlesTitle: string
  viewAllArticles: IButtonLink
}

export interface IBlogArticlePage extends IBlogArticle {

}

export interface IPageScript extends IComponentBase {
  script: string
  scriptLocation: 'headPrepend' | 'headAppend' | 'bodyPrepend' | 'bodyAppend' | null
}
export interface PageContextLocalization {
  id: number
  locale: string
}

export interface PageContextLocalizedPath {
  href: string
  locale: string
}
export interface IFooterColumn {
  id: number
  title: string
  links: ILink[]
}
export interface IHeroMain extends IComponentBase {
  labelText: string
  labelIcon: IMedia
  description: string
  title: string
  badges: IMedia[]
  storeLinks: IImageLink[]
  backgroundImage: IMedia
  foregroundImage: IMedia
  buttonLinks: IButtonLink[]
  foregroundMedia: Array<{
    id: number
    styles: SxProps
    media: IMedia
  }>
  smallScreenForegroundMedia: Array<{
    id: number
    styles: SxProps
    media: IMedia
  }>
  ctaType: string
  ctaUrl: string
  theme: null | 'normal' | 'inverted'
  styles: SxProps
  ctaText?: string
  phoneNumberPlaceholderText?: string
}

export interface IHeroBusinessSearchResult extends IComponentBase {
  description: string
  notes: INote[]
  phoneNumberPlaceholderText: string
  title: string
  styles: SxProps
  theme: null | 'normal' | 'inverted'
  businessSearchResult: IBusinessSearchResult
}

export interface IBusinessSearchResult {
  type: string
  icon: string
  typeColor: string
  name: string
  formatted_address: string
  rating: string
  reviews: Array<{
    author_name: string
    author_url: string
    language: string
    profile_photo_url: string
    rating: 5
    relative_time_description: string
    text: string
  }>
  opening_hours: IBusinessOpeningHours
}

export interface IBusinessOpeningHours {
  weekday_text: string[]
}

export interface INote {
  backgroundColor: string
  content: string
  id: number
  image: IMedia
  styles: SxProps
  textColor: string
}
export interface IHeroMessengerItem {
  id: number
  message: string
  type: string
}

export interface IDownloadApp extends IComponentBase {
  label: string
  title: string
  description: string
  media: IMedia
  agreementText: string
  inputPlaceholderText: string
  submitButtonText: string
  appDownloadLinks: IAppDownloadLinks[]
  inputIcon: IMedia
  successMessage: string
  failureMessage: string
  smsContent: string
  downloadAppTheme: string
  showDivider: boolean
  styles: SxProps
}
export interface IAppDownloadLinks extends IComponentBase {
  media: IMedia
  url: string
  title: string
}
export interface IHeroInvoiceWidget extends IComponentBase {
  title: string
  IHeroInvoiceWidget: number
  invoiceRowItemFields: IHeroInvoiceRow[]
  form: IHeroInvoiceForm
}

export interface IHeroInvoiceRow {
  description: string
  helperText: string
  isMultiLine: boolean
  isRequired: boolean
  label: string
  name: string
  placeholder: string
  type: string
  width: string
  defaultValue: string
}

export interface IHeroInvoiceForm {
  name: string
  sections: IHeroInvoiceSection[]
}
export interface IHeroInvoiceSection extends IComponentBase {
  description: string
  showDivider: boolean
  title: string
  defaultValue?: string | null | undefined | unknown
}

export interface IFormSection extends IComponentBase {
  title: string
  description: string
  media: IMedia
  form: IForm
}

export type IFormFieldWidth = 'full' | 'oneThird' | 'oneFourth' | 'twoThird' | 'half' | 'threeFourth'

export interface IFormField extends IComponentBase {
  name: string
  label: string
  items: IFormOptionItem[]
  type: string
  defaultValue?: string | number
  isRequired: boolean
  description?: string
  helperText?: string
  placeholder?: string
  width?: IFormFieldWidth
}

export interface IForm extends IComponentBase {
  name: string
  sections: IFormControl[]
}

export interface IFormHeading extends IComponentBase {
  description: string
  title: string
}

export interface IFormSeparator extends IComponentBase {
  description: string
  title: string
  showDivider: boolean
}
export interface IFormInput extends Omit<IFormField, 'items'> {
  isMultiline: boolean
}

export interface IFormDropdown extends Omit<IFormField, 'type'> {

}

export interface IFormCheckboxGroup extends Omit<IFormField, 'type'> {

}

export interface IFormRadioGroup extends Omit<IFormField, 'type'> {

}

export interface IFormOptionItem {
  id: number
  label: string
}

export interface IFormButtonGroup extends IComponentBase {
  buttons: IButton[]
}

export interface GeolocationPositionProps {
  coords?: {
    latitude?: number | null
    longitude?: number | null
  }
}

export interface IArticle extends IComponentBase {
  title: string
  description?: string
  columns: ArticleColumn[]
  styles: SxProps
}

export interface IFaq extends IComponentBase {
  title: string
  item: FaqRow[]
  styles: SxProps
}

export interface FaqRow {
  heading: string
  description: string
}

export interface IPricingSubscription {
  id: number
  name: string
}

export interface IPricing extends IComponentBase {
  title: string
  defaultSubscription: string
  sliders: IPricingParam[]
  styles: SxProps
  subscriptions: IPricingSubscription[]
}

export interface ArticleColumn extends IComponentBase {
  content: string
}

export type IFormControl = IFormHeading | IFormSeparator | IFormInput | IFormDropdown | IFormCheckboxGroup | IFormRadioGroup

export type ISection = IHero | IHeroMain | IFeatureColumnsGroup | IFeatureRow | IProductCta

export interface IBlogSection {
  id: number
  component: string
}

export type { HeroProps } from './domain/hero'

export type { FeatureRowProps } from './domain/feature-row'

export type { FeatureColumnsGroupProps } from './domain/feature-columns-group'

export type { ProductCtaProps } from './domain/product-cta'

export type { TestimonialProps } from './domain/testimonial'

export type { HeroMainProps } from './domain/hero-main'

export type { DownloadMobileAppProps } from './domain/download-mobile-app'

export type HeroWidgetInteractionResultPayload =
  | IBookingWidgetPayload
  | IReviewWidgetPayload
  | IPaymentWidgetPayload
  | IContactHubWidgetPayload
  | IInvoiceWidgetPayload
  | IPlacementWidgetPayload

export type IHeroWidget =
  | IHeroMediaWidget
  | IHeroFormWidget
  | IHeroBookingWidget
  | IHeroMessengerWidget
  | IHeroReviewWidget
  | IHeroPaymentWidget
  | IHeroContactHubWidget
  | IHeroInvoiceWidget
  | IHeroEngagementWidget
  | IHeroPlacementWidget
  | IHeroSitesWidget

export type IHeroContentPart =
  | IHeroContent
  | IBackgroundMedia
  | IHeroFeatureContent
  | IHeroCTA
  | IHeroSignUp
  | IEmailConfirmation
  | IHeroTestimonial
  | IHeroWidget
  | IHeroBodyAdditionalText
  | IHeroMain
  | IHeroBusinessSearchResult

export type { SxProps }

export const linkPropTypes = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  newTab: PropTypes.bool
})

export const mediaPropTypes = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  alternativeText: PropTypes.string,
  mime: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
})

export const buttonLinkPropTypes = PropTypes.shape({
  theme: PropTypes.string,
  text: PropTypes.string.isRequired,
  newTab: PropTypes.bool
})
