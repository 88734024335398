import { Media } from '@gositeinc/ui'

import EmailConfirmation, { EmailBodyContent } from './email-confirmation'
import { EngagementEmailBody } from './engagement-email-body'
import { IEngagementWidgetPayload, IHeroEngagementWidget } from '../types'

const EngagementEmailConfirmation = ({ widgetData, widgetPayload }: EngagementEmailConfirmationProps): JSX.Element => {
  const {
    engagementTitle,
    engagementDescription,
    engagementCtaText,
    engagementMedia
  } = widgetData
  const {
    senderAvatarColor,
    senderAvatarIcon,
    senderName,
    receiverName,
    emailTitle,
    emailSubject
  } = widgetPayload

  return (
    <EmailConfirmation
      senderAvatarColor={senderAvatarColor}
      senderAvatarIcon={senderAvatarIcon}
      senderName={senderName}
      receiverName={receiverName}
      title={emailSubject === '' ? emailTitle : emailSubject}
      body={
        <EmailBodyContent>
          <Media media={engagementMedia} height={99} width={160} />
          <EngagementEmailBody
            title={engagementTitle}
            description={engagementDescription}
            linkText={engagementCtaText}
            titleTextVariant='caption'
            descriptionTextVariant='caption3'
          />
        </EmailBodyContent>
      }
    />
  )
}

interface EngagementEmailConfirmationProps {
  widgetData: IHeroEngagementWidget
  widgetPayload: IEngagementWidgetPayload
}

export default EngagementEmailConfirmation
