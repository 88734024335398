import { jsPDF as JsPdf } from 'jspdf'
import html2canvas from 'html2canvas'

export const usePdf = (): UsePdf => {
  const downloadPdf = (ref: HTMLInputElement | HTMLElement | null, filename: string): void => {
    if (ref == null) {
      console.warn('Could not find reference to html for rendering pdf')
      return
    }
    html2canvas(ref, {
      allowTaint: true,
      useCORS: true
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg')
      const htmlHeight = Number(canvas.height)
      const htmlWidth = Number(canvas.width)
      const topLeftMargin = 15
      const pdfWidth = htmlWidth + topLeftMargin * 2
      const pdfHeight = htmlHeight * 1.5 + topLeftMargin * 2
      const canvasImageWidth = htmlWidth
      const canvasImageHeight = htmlHeight
      const totalPDFPages = Math.ceil(htmlHeight / pdfHeight) - 1
      const pdf = new JsPdf('p', 'pt', [pdfWidth, pdfHeight])
      pdf.addImage(
        imgData,
        'JPG',
        topLeftMargin,
        topLeftMargin,
        canvasImageWidth,
        canvasImageHeight
      )

      for (let i = 1; i <= totalPDFPages; i++) {
        pdf.addPage()
        pdf.addImage(
          imgData,
          'JPG',
          topLeftMargin,
          -(pdfHeight * i) + topLeftMargin * 4,
          canvasImageWidth,
          canvasImageHeight
        )
      }
      pdf.save(`${filename}.pdf`)
    }).catch(() => { })
  }

  return {
    downloadPdf
  }
}

interface UsePdf {
  downloadPdf: (ref: HTMLInputElement | HTMLElement | null, filename: string) => void
}
