import React, { useState } from 'react'
import { animated, config, useTransition } from '@react-spring/web'
import {
  Box,
  Grid,
  Text,
  IconButton,
  ListItem,
  OutlinedInput,
  Select,
  Avatar,
  ListItemText,
  ListItemAvatar,
  MenuItem,
  Card,
  Media,
  styled,
  useMediaQuery,
  useTheme,
  SxProps,
  Theme
} from '@gositeinc/ui'
import { IoArrowBackSharp } from 'react-icons/io5'

import { IHeroPaymentWidget, IMedia } from '../types'
import { HeroWidgetModifierStyle } from './hero-widget'
import { ChevronDown } from '../assets'

const PaymentWidget = ({ data, onDone }: PaymentWidgetProps): JSX.Element => {
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'))

  const [state, setState] = useState<State>(State.Init)

  const [done, setDone] = useState<boolean>(false)

  const _onDone = (payload: object): void => {
    onDone(payload)
    setDone(true)
  }

  const transitions = useTransition(state, {
    keys: state,
    from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
    config: config.stiff
  })

  return (
    <Root id='Root'>
      <PaymentContainer elevation={24} sx={{ paddingX: theme.spacing(5) }}>
        {transitions((style, currentState) => {
          switch (currentState) {
            case State.Init:
              return (
                <animated.div style={{ ...style }}>
                  <PaymentOptions
                    sx={{ position: state !== State.Init ? 'absolute' : 'relative' }}
                    data={data}
                    onInvoiceClick={() => {
                      setState(State.Invoice)
                    }}
                    onPaymentLinkClick={() => {
                      setState(State.Payment)
                    }}
                  />
                </animated.div>
              )
            case State.Payment:
              return (
                <animated.div style={{ ...style }}>
                  <PaymentLink
                    sx={{ position: state !== State.Payment ? 'absolute' : 'relative' }}
                    data={data}
                    onBackClick={() => {
                      setState(State.Init)
                    }}
                    onDone={_onDone}
                  />
                </animated.div>
              )
            case State.Invoice:
              return (
                <animated.div style={{ ...style }}>
                  <Invoice
                    sx={{ position: state !== State.Invoice ? 'absolute' : 'relative' }}
                    data={data}
                    onBackClick={() => {
                      setState(State.Init)
                    }}
                    onDone={_onDone}
                  />
                </animated.div>
              )
            default:
              return <></>
          }
        })}

        {done && isMd && state === State.Invoice && <Fade />}
      </PaymentContainer>

    </Root>
  )
}

const Contact = ({ name, image, email }: ContactProps): JSX.Element => (
  <Box sx={{ borderRadius: '8px', background: '#F7F9FB', marginTop: 0 }}>
    <ListItem sx={{ marginTop: 0 }}>
      <ListItemAvatar>
        <Avatar>
          <Media
            media={image}
          />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={name}
        secondary={email} sx={{
          '.MuiTypography-body1': {
            fontWeight: 'bold'
          }
        }}
      />
    </ListItem>
  </Box>
)

const Invoice = ({ data, onBackClick, onDone, sx }: InvoiceProps): JSX.Element => {
  const { invoice, daysUntilDue } = data
  const {
    headerText,
    contactEmail,
    contactImage,
    contactName,
    invoiceDetailLabelText,
    invoiceItemsLabelText,
    invoiceItemsPlaceholderText,
    billToLabelText,
    sendOnText,
    dueText,
    totalText,
    selectServiceAboveText,
    services
  } = invoice
  const [selectedServiceId, setSelectedServiceId] = useState<number>(-1)
  const [selectOpen, setSelectOpen] = useState(false)
  const theme = useTheme()

  const date2String = (date: Date): string => {
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const monthNames = ['Jan', 'Feby', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    return `${dayNames[date.getDay()]}, ${monthNames[date.getMonth()]} ${date.getDate()}`
  }

  const time = new Date()
  const nowString = date2String(time)
  time.setDate(time.getDate() + daysUntilDue)
  const dueString = date2String(time)

  return (
    <Box sx={sx}>
      <IconButton
        sx={{ margin: 0, marginLeft: -1 }}
        onClick={onBackClick}
      >
        <IoArrowBackSharp size={16} color='black' />
      </IconButton>
      <Text variant='h5' sx={{ marginTop: 1 }}>
        {headerText}
      </Text>
      <Grid container flexDirection='column' spacing={1} sx={{ marginTop: 1 }}>
        <Grid item xs={12}>
          <SectionTitle>{invoiceItemsLabelText}</SectionTitle>
        </Grid>
        <Grid item xs={12}>
          <Select
            IconComponent={ChevronDown}
            value={selectedServiceId}
            displayEmpty
            sx={{
              width: '100%',
              '& > .MuiOutlinedInput-notchedOutline': {
                borderColor: '#EDF1F6 !important'
              },
              '& > .MuiOutlinedInput-input': {
                paddingRight: '56px !important'
              },
              '& > .MuiSelect-icon': {
                right: '26px !important'
              },
              color: '#8290A1',
              [theme.breakpoints.up('md')]: {
                color: selectOpen ? theme.palette.primary.main : '#8290A1'
              }
            }}
            onOpen={() => {
              setSelectOpen(true)
            }}
            onClose={() => {
              setSelectOpen(false)
            }}
            onChange={(e) => {
              const id = e.target.value as number
              setSelectedServiceId(id)
              const selectedService = services.find(s => s.id === id)

              onDone({
                selectedService
              })
            }}
            renderValue={
              selectedServiceId !== -1 ? undefined : () => <PlaceHolder variant='h5'>{invoiceItemsPlaceholderText}</PlaceHolder>
            }
          >
            <MenuItem key={-1} value={-1} sx={{ display: 'none' }} />
            {services.map(service => (
              <ServiceMenuItem
                key={service.id}
                value={service.id}
              >
                <Grid container alignItems='center' justifyContent='space-between'>
                  <Grid item container spacing={2} xs='auto' alignItems='center'>
                    <Grid item>
                      <Avatar sx={{ borderRadius: '8px' }}>
                        <Media width={40} height={40} media={service.image} />
                      </Avatar>
                    </Grid>
                    <Grid item>
                      <Text variant='body1' sx={{ fontWeight: 'bold' }}>{service.name}</Text>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Text variant='body1'>${service.price}</Text>
                  </Grid>
                </Grid>
              </ServiceMenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} mt={2}>
          <SectionTitle>{billToLabelText}</SectionTitle>
        </Grid>
        <Grid item xs={12}>
          <Contact name={contactName} email={contactEmail} image={contactImage} />
        </Grid>
        <Grid item xs={12} mt={2}>
          <SectionTitle>{invoiceDetailLabelText}</SectionTitle>
        </Grid>
        <Grid item container xs={12} justifyContent='space-between'>
          <Text variant='body2' fontWeight={500} color='#8290A1'>{sendOnText}</Text>
          <Text variant='body2' color='#576777'>{nowString}</Text>
        </Grid>
        <Grid item container xs={12} justifyContent='space-between'>
          <Text variant='body2' fontWeight={500} color='#8290A1'>{dueText}</Text>
          <Text variant='body2' color='#576777'>{dueString}</Text>
        </Grid>
        <Grid item container xs={12} gap={2}>
          <Text variant='h6'>{totalText}</Text>
          <Text variant='h6' fontWeight={500} color='#B6C0CC'>{services.find(s => s.id === selectedServiceId)?.price ?? selectServiceAboveText}</Text>
        </Grid>
      </Grid>
    </Box>
  )
}

const PaymentOptions = ({ onInvoiceClick, onPaymentLinkClick, data, sx }: PaymentOptionsProps): JSX.Element => {
  const { paymentOption } = data
  const { headerText, invoiceIcon, paymentLinkIcon, invoiceText, paymentLinkText } = paymentOption
  return (
    <PaymentOptionsRoot sx={sx}>
      <Text variant='h5'>
        {headerText}
      </Text>
      <Grid container justifyContent='center' alignItems='center' spacing={1} sx={{ marginTop: 2 }}>
        <Grid item xs={12} md={6}>
          <Option onClick={onPaymentLinkClick}>
            <Media
              media={paymentLinkIcon}
            />
            <OptionText rich variant='body1'>{paymentLinkText}</OptionText>
          </Option>
        </Grid>
        <Grid item xs={12} md={6}>
          <Option onClick={onInvoiceClick}>
            <Media
              media={invoiceIcon}
            />
            <OptionText rich variant='body1'>{invoiceText}</OptionText>
          </Option>
        </Grid>
      </Grid>
    </PaymentOptionsRoot>
  )
}

const PaymentLink = ({ data, onBackClick, onDone, sx }: PaymentLinkProps): JSX.Element => {
  const { paymentLink } = data
  const [amount, setAmount] = useState('')
  const [disableInput, setDisableInput] = useState(false)
  const theme = useTheme()

  const { headerText, contactLabelText, amountLabelText, contactEmail, contactImage, contactName, amount: invoiceAmount } = paymentLink
  return (
    <Box sx={sx}>
      <IconButton
        sx={{ margin: 0, marginLeft: -1 }}
        onClick={onBackClick}
      >
        <IoArrowBackSharp size={16} color='black' />
      </IconButton>
      <Text variant='h5' sx={{ marginTop: 1 }}>
        {headerText}
      </Text>
      <Grid container flexDirection='column' columnSpacing={2} sx={{ marginTop: 1 }}>
        <Grid item xs={12}>
          <SectionTitle>{amountLabelText}</SectionTitle>
        </Grid>
        <Grid item xs={12}>
          <OutlinedInput
            type='text'
            fullWidth
            disabled={disableInput}
            value={amount}
            onClick={() => {
              setAmount(`$${invoiceAmount}.00`)
              setDisableInput(true)
              setTimeout(() => {
                onDone({
                  amount: invoiceAmount
                })
              }, 1500)
            }}
            sx={{
              '& > .MuiOutlinedInput-notchedOutline': {
                borderColor: '#EDF1F6 !important'
              },
              '.MuiOutlinedInput-input': {
                borderColor: '#EDF1F6 !important',
                color: amount === '' ? '#8290A1' : theme.palette.primary.main,
                fontWeight: 'bold'
              },
              '& .Mui-disabled': {
                WebkitTextFillColor: amount === '' ? '#8290A1' : theme.palette.primary.main
              }
            }}
            placeholder='Enter an amount'
          />
        </Grid>
        <Grid item xs={12} mt={2}>
          <SectionTitle>{contactLabelText}</SectionTitle>
        </Grid>
        <Grid item xs={12}>
          <Contact name={contactName} email={contactEmail} image={contactImage} />
        </Grid>
      </Grid>
    </Box>
  )
}

const SectionTitle: React.FC = ({ children }) => (<Text variant='body1' sx={{ fontWeight: 600 }}>{children}</Text>)

const PaymentContainer: React.ElementType = styled(Card)(({ theme }: { theme: Theme }) => ({
  borderRadius: 8,
  paddingBottom: theme.spacing(5),
  paddingTop: theme.spacing(1),
  width: 312,
  [theme.breakpoints.up('sm')]: {
    width: 400
  },
  [theme.breakpoints.up('md')]: {
    width: 496
  },
  overflow: 'visible',
  transition: 'height 1s ease-out'
}))

const PlaceHolder: React.ElementType = styled(Text)(({ theme }: { theme: Theme }) => ({
  fontWeight: 'bold',
  [theme.breakpoints.down('md')]: {
    fontSize: 20
  }
}))

const ServiceMenuItem: React.ElementType = styled(MenuItem)(({ theme }: { theme: Theme }) => ({
  '&:hover': {
    background: '#F7F9FB',
    color: theme.palette.primary.main,
    borderRadius: theme.spacing(2)
  },
  padding: '13px 24px'
}))

const OptionText: React.ElementType = styled(Text)(({ theme }: { theme: Theme }) => ({
  fontWeight: 'bold',
  textAlign: 'center',
  marginLeft: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    marginLeft: 0
  }
}))

const Option: React.ElementType = styled(Box)(({ theme }: { theme: Theme }) => ({
  boxSizing: 'border-box',
  border: '1px solid #DBE3EB',
  borderRadius: 8,
  padding: 30,
  width: 200,
  height: 200,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: 'initial',
    height: 'initial',
    paddingLeft: 18,
    paddingRight: 18
  },
  '&:hover': {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    backgroundColor: `${theme.palette.primary.main}14`
  }
}))

const Root: React.ElementType = styled('div')(({ theme }: { theme: Theme }) => ({
  position: 'relative',
  maxWidth: 326,
  [theme.breakpoints.up('sm')]: {
    maxWidth: 560
  },
  [theme.breakpoints.up('md')]: {
    maxHeight: 400
  }
}))

const Fade = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(180deg, rgba(248, 249, 251, 0) 0%, #F7F9FB 35.91%)',
  height: '40%',
  width: '120%',
  position: 'absolute',
  top: '89%',
  left: '-10%'
}))

const PaymentOptionsRoot = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4)
}))

enum State {
  Init,
  Payment,
  Invoice
}

interface PaymentOptionsProps {
  sx: SxProps
  data: IHeroPaymentWidget
  onInvoiceClick: () => any
  onPaymentLinkClick: () => any
}

interface PaymentLinkProps {
  sx: SxProps
  data: IHeroPaymentWidget
  onDone: (payload: object) => void
  onBackClick: () => void
}

interface InvoiceProps {
  sx: SxProps
  data: IHeroPaymentWidget
  onDone: (payload: object) => void
  onBackClick: () => void
}

interface ContactProps {
  name: string
  email: string
  image: IMedia
}

interface PaymentWidgetProps {
  data: IHeroPaymentWidget
  onDone: (payload: object) => void
}

export const paymentWidgetModifierStyle: HeroWidgetModifierStyle = {
  showFade: false
}

export default PaymentWidget
