import React, { useState } from 'react'
import { BiCheckCircle, BiDollarCircle, BiTime } from 'react-icons/bi'
import { Box, Divider, List, ListItem, ListItemIcon, ListItemText, Text, styled, useTheme } from '@gositeinc/ui'
import { format as dateFormat } from 'date-fns'

import EmailConfirmation, { EmailBodyContent } from './email-confirmation'
import { IBookingWidgetPayload, IHeroBookingWidget } from '../types'

const BookingEmailConfirmation = ({ widgetData, widgetPayload }: BookingEmailConfirmationProps): JSX.Element => {
  const {
    serviceName,
    serviceDurationInMinutes,
    serviceHourlyPricing,
    serviceDescription
  } = widgetData
  const {
    senderAvatarColor,
    senderAvatarIcon,
    senderName,
    receiverName,
    emailTitle,
    bookingDateTime
  } = widgetPayload
  const [isSeeMore, setIsSeeMore] = useState(false)
  const theme = useTheme()

  const renderBody = (): JSX.Element => {
    return (
      <EmailBody>
        <EmailBodyContent>
          <Box>
            <BiCheckCircle
              color={theme.palette.primary.main}
              size={22}
            />
          </Box>
          <Text
            variant='caption'
            color='primary'
            sx={{ whiteSpace: 'nowrap' }}
          >
            {dateFormat(bookingDateTime, 'EEE, d LLLL h:mma')}
          </Text>
        </EmailBodyContent>
        <Divider variant='middle' sx={{ marginX: theme.spacing(1) }} />
        <EmailBodyContent>
          <Text
            variant='caption'
            fontWeight={theme.typography.fontWeightSemiBold}
          >
            {serviceName}
          </Text>
          <List dense>
            <ListItem>
              <ListItemIcon>
                <BiTime size={11} />
              </ListItemIcon>
              <ListItemText
                primary={`${serviceDurationInMinutes} min`}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <BiDollarCircle size={11} />
              </ListItemIcon>
              <ListItemText
                primary={`$${serviceHourlyPricing}/hr`}
              />
            </ListItem>
          </List>
          <Text
            variant='caption2'
            color='textSecondary'
            component='div'
          >
            {serviceDescription.length > 30
              ? (
                <>
                  {isSeeMore ? serviceDescription : `${serviceDescription.slice(0, 35)}...`}
                  {!isSeeMore && (
                    <SeeMore onClick={() => setIsSeeMore(true)}>See More</SeeMore>
                  )}
                </>)
              : serviceDescription}
          </Text>
        </EmailBodyContent>
      </EmailBody>
    )
  }

  return (
    <EmailConfirmation
      senderAvatarColor={senderAvatarColor}
      senderAvatarIcon={senderAvatarIcon}
      senderName={senderName}
      receiverName={receiverName}
      title={emailTitle}
      body={renderBody()}
    />
  )
}

const EmailBody = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  '& .MuiList-root': {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(0, -1)
  },
  '& .MuiListItem-root': {
    margin: theme.spacing(0, 1),
    padding: 0,
    width: 'auto',
    whiteSpace: 'pre'
  },
  '& .MuiListItemIcon-root': {
    color: theme.palette.primary.main,
    minWidth: 15.5
  },
  '& .MuiListItemText-primary': {
    fontSize: theme.typography.caption2.fontSize
  }
}))

const SeeMore = styled('span')(() => ({
  textDecoration: 'underline',
  cursor: 'pointer'
}))

interface BookingEmailConfirmationProps {
  widgetData: IHeroBookingWidget
  widgetPayload: IBookingWidgetPayload
}

export default BookingEmailConfirmation
