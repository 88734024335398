import Cookies from 'js-cookie'

import { submitHubDetails } from './api'
import { env } from '../utils'
import { getUtmParams } from './getUtmParams'

export const useHubSpotForms = (): UseHubSpotForms => {
  const saveHeroSignUpForm = async ({ email }: { email: string }): Promise<any> => {
    const hubspotutkId = Cookies.get('hubspotutk')
    const utmParams = getUtmParams()
    const _utmParams = utmParams !== '' ? utmParams : ''

    const payload = {
      hubId: env.hubspotAccId,
      formId: env.hubspotHeroSignUpFormId,
      fields: [
        {
          name: 'email',
          value: email
        },
        {
          name: 'utmParams',
          value: _utmParams
        }
      ],
      context: {
        hutk: hubspotutkId,
        pageUri: window.location.href,
        pageName: document.title
      }
    }

    return await submitHubDetails(payload)
  }

  const saveHeroCTAForm = async ({ phone }: { phone: string }): Promise<any> => {
    const hubspotutkId = Cookies.get('hubspotutk')
    const _mostRecentEmail = JSON.parse(window.localStorage.getItem('most_recent_email') ?? '')
    const utmParams = getUtmParams()
    const _utmParams = utmParams !== '' ? utmParams : ''

    const payload = {
      hubId: env.hubspotAccId,
      formId: env.hubspotHeroCtaFormId,
      fields: [
        {
          name: 'email',
          value: _mostRecentEmail
        },
        {
          name: 'phone',
          value: phone
        },
        {
          name: 'utmParams',
          value: _utmParams
        }
      ],
      context: {
        hutk: hubspotutkId,
        pageUri: window.location.href,
        pageName: document.title
      }
    }

    return await submitHubDetails(payload)
  }

  return {
    saveHeroCTAForm,
    saveHeroSignUpForm
  }
}

export interface UseHubSpotForms {
  saveHeroSignUpForm: ({ email }: { email: string }) => Promise<any>
  saveHeroCTAForm: ({ phone }: { phone: string }) => Promise<any>
}

export interface GetHubspotCookie {
  hubspotutk: string
}
